import { styled } from '@mui/material'

export const StyledScroll = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'width' && prop !== 'scrollX' && prop !== 'scrollY'
})<{ height?: string; width?: string; scrollX?: boolean; scrollY?: boolean }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  overflow-y: ${({ scrollY }) => scrollY && 'scroll'};
  overflow-x: ${({ scrollX }) => scrollX && 'scroll'};
  &::-webkit-scrollbar {
    display: none;
  }
`
