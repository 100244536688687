import { Stack } from '@mui/material'
import { ReactNode } from 'react'
import { StyledLeftComponent, StyledRightComponent } from './styled'

function PageContent({ leftCmp, rightCmp }: { leftCmp: ReactNode; rightCmp: ReactNode }) {
  return (
    <Stack flexDirection="row" gap={3.5} justifyContent="space-between" width={'100%'}>
      <StyledLeftComponent>{leftCmp}</StyledLeftComponent>
      <StyledRightComponent alignItems={'center'}>{rightCmp}</StyledRightComponent>
    </Stack>
  )
}

export default PageContent
