import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { FORMATDATE } from '../orders/reducer'
import { DeliverySlice } from './model'

const initialState: DeliverySlice = {
  filters: {
    status: undefined,
    deliveryDate: dayjs().format(FORMATDATE)
  }
}

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setSelectedOrderDeliveryId(state, { payload }: PayloadAction<string>) {
      state.selectId = state.selectId === payload ? undefined : payload
    },
    setFilter(state, { payload }: PayloadAction<string | undefined>) {
      state.filters.status = state.filters.status === payload ? undefined : payload
    },
    setfiltersDate(state, { payload }: PayloadAction<string | undefined>) {
      state.filters.deliveryDate =
        state.filters.deliveryDate === payload ? undefined : payload
    },
    setSearchFilter(state, { payload }: PayloadAction<string>) {
      state.search = payload
    }
  }
})

export const deliveryActions = {
  ...deliverySlice.actions
}
export const deliveryReducer = deliverySlice.reducer
