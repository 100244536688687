import { MouseEventHandler } from 'react'
import { memo } from 'react'
import { Text } from '../Text'
import { StyledFilterButton } from './styled'

type Props = {
  text: string
  isSelected?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
  bgColorCode: any
}

function FilterButtonCmp({ text, isSelected = false, onClick, bgColorCode }: Props) {
  return (
    <StyledFilterButton bgColor={bgColorCode} isSelected={isSelected} onClick={onClick}>
      <Text
        bold
        text={text.toUpperCase()}
        textColor={isSelected ? '#FEFEFE' : bgColorCode}
      />
    </StyledFilterButton>
  )
}

export const FilterButton = memo(FilterButtonCmp)
