import { Stack } from '@mui/material'
import { OrderStatus, Status } from '../../features/orders/model'
import { BadgeText } from '../BadgeText'
import { Text } from '../Text'

type Props = {
  status?: Status | OrderStatus
  text?: string
  uppercase?: boolean
}

export const StatusBadge = ({ status, uppercase, text = '' }: Props) => {
  return (
    <Stack overflow={'visible'} minWidth={100}>
      <Text text={text} textColor={status} fontSize={14} />
      {status && <BadgeText status={status} color={status} uppercase={uppercase} />}
    </Stack>
  )
}
