import { createSelector } from '@reduxjs/toolkit'
import { get, keyBy } from 'lodash'
import { Product } from '../products/model'
import { selectProductsIds } from '../products/selectors'
import type { RootState } from '../store'
import { OrderProductCartExtended } from './model'

const MIN = 9
const MAX = 20
const interval = [':00', ':15', ':30', ':45']

export const selectCart = (state: RootState) => state.cart

export const selectCartProducts = createSelector(selectCart, (cart) => cart.items)

export const selectCartExtended = createSelector(
  selectCartProducts,
  selectProductsIds,
  (items, productsByIds) => {
    return items.map((o) => {
      const prod: Product = get(productsByIds, o.productId.toString())
      return {
        ...o,
        category: prod?.category || undefined,
        title: prod?.title.toLowerCase(),
        sku: prod?.sku,
        img: prod?.img,
        conversionValue: prod?.conversionValue,
        weight: prod?.weight,
        conversionUnit: prod?.conversionUnit,
        price: prod?.price
      } as OrderProductCartExtended
    })
  }
)

export const selectCartProductsIds = createSelector(selectCartProducts, (products) =>
  keyBy(products, '_id')
)

export const makeSelectCartProductById = (id: string) =>
  createSelector(selectCartProductsIds, (products) => products[id])

export const selectMaxProcessingTime = createSelector(
  selectCartProducts,
  (cartProducts) => {
    const processingTime = cartProducts.map((p) => p.processingTime || 0)
    return Math.max(...processingTime, 24)
  }
)

export const selectDeliveryDate = createSelector(
  selectCart,
  (cart) => cart?.details?.deliveryDate as string
)

export const selectMinDeliveryDate = createSelector(
  selectMaxProcessingTime,
  (processingTime) => {
    const today = new Date()
    return today.setHours(today.getHours() + processingTime)
  }
)

export const selectDeliveryOpts = createSelector(
  selectMinDeliveryDate,
  (minDeliveryDate) => {
    const deliveryDate = new Date(minDeliveryDate)
    if (deliveryDate.getHours() < MIN) {
      deliveryDate.setHours(MIN)
    }
    if (deliveryDate.getHours() > MAX) {
      deliveryDate.setDate(deliveryDate.getDate() + 1)
      deliveryDate.setHours(MIN)
    }
    return deliveryDate
  }
)

export const selectDeliveryHoursOpts = createSelector(
  selectDeliveryOpts,
  selectDeliveryDate,
  (date, selectedDay) => {
    const min =
      selectedDay && new Date(Date.parse(selectedDay)) > date ? MIN : date.getHours()
    const opts = Array.from({ length: MAX - min + 1 }, (_, i) => min + i)
    return opts
      .map((o, index) =>
        index === opts.length - 1
          ? { title: `${o}:00`, id: `${o}:00` }
          : interval.map((i) => ({ title: `${o}${i}`, id: `${o}${i}` }))
      )
      .flat()
  }
)

export const selectCartNotes = createSelector(selectCart, (cart) => cart.details)

export const selectShowDetails = createSelector(selectCart, (cart) => cart.showDetails)

export const selectDeliveryMode = createSelector(selectCart, (cart) => cart.deliveryMode)

export const selectTotal = createSelector(selectCartExtended, (products) => {
  return products.reduce((acc, inc) => acc + inc.quantity * inc.price, 0.0).toFixed(2)
})
