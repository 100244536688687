import { styled } from '@mui/material'

type Props = {
  imgWidth?: number
  imgHeight?: number
}

export const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'imgWidth' && prop !== 'imgHeight'
})<Props>`
  width: ${({ imgWidth }) => (imgWidth ? `${imgWidth}` : 100)}px;
  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight}` : 150)}px;
  object-fit: cover;
  border-radius: 15px;
`
