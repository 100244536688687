import { ListItemText } from '@mui/material'
import { ReactNode } from 'react'
import { OrderStatus, Status } from '../../features/orders/model'
import { theme } from '../../style/theme'
import { StyledListItem, StyledCell, StyledListItemIcon } from './styled'

export type Props = {
  statusBadge?: ReactNode
  createdAt?: any
  deliveryDate?: any
  detail?: ReactNode
  rightComponent?: ReactNode
  onClick?: () => void
  status?: Status | OrderStatus
  isSelected: Boolean
  orderData: any
  deliveryTime?: string
}

export const ListItem = ({
  statusBadge,
  deliveryTime,
  detail,
  rightComponent,
  isSelected,
  onClick,
  status = 'new'
}: Props) => {
  return (
    <StyledListItem
      onClick={onClick}
      borderColor={theme.status[status]}
      backgroundColor={theme.background.lightGrey}
      isSelected={isSelected}
    >
      {statusBadge}
      <StyledCell>
        {deliveryTime && <ListItemText>{deliveryTime}</ListItemText>}
      </StyledCell>
      <StyledCell>{detail}</StyledCell>
      <StyledListItemIcon>{rightComponent}</StyledListItemIcon>
    </StyledListItem>
  )
}
