import {
  AccountCircle,
  Ballot,
  Cake,
  Home,
  LocalShippingRounded,
  QrCodeScannerOutlined,
  Upload
} from '@mui/icons-material'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory'
import { BottomNavigationAction } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomModal from '../../../components/CustomModal'
import { Logo } from '../../../components/Logo'
import Stack from '../../../components/Stack'
import { Text } from '../../../components/Text'
import { selectUserName } from '../../../features/app/selectors'
import { theme } from '../../../style/theme'
import { FlowerScanQrOrder } from '../../ScanQrOrder'
import { StyledNavigation, StyledNavigationLogo } from './styled'

const iconsMap = {
  '/login': <Home />,
  '/shop': <StoreMallDirectoryIcon />,
  '/orders': <Ballot />,
  '/detail': <InsertChartIcon />,
  '/production': <Cake />,
  '/delivery': <LocalShippingRounded />,
  '/import': <Upload />
}

function Sidebar({ routes }: { routes: { path: string; label: string }[] }) {
  const [openScan, setOpenScan] = useState(false)
  const [openLogout, setOpenLogout] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const currentUserName = useSelector(selectUserName)

  const manageOrders =
    routes.filter((e) => e.path === '/orders' || e.path === '/delivery').length > 0

  const onLogout = useCallback(() => {
    dispatch({ type: 'LOGOUT' })
  }, [dispatch])

  const onClickNav = useCallback(
    (_: React.SyntheticEvent<Element, Event>, path: string) => {
      if (path === '/logout') {
        setOpenLogout(true)
        return
      }
      if (path === '/qrscanner') {
        setOpenScan(true)
        return
      }
      navigate(path)
    },
    [navigate]
  )

  return (
    <StyledNavigation showLabels value={location.pathname} onChange={onClickNav}>
      <StyledNavigationLogo value="/" icon={<Logo />} />
      {routes.map((e: { path: string; label: string }, index) => (
        <BottomNavigationAction
          key={e.label}
          label={e.label}
          value={index === 0 ? '/' : e.path}
          // @ts-ignore
          icon={iconsMap[e.path] || <Home />}
        />
      ))}
      {manageOrders && (
        <BottomNavigationAction
          label="Scan QR"
          value="/qrscanner"
          icon={<QrCodeScannerOutlined />}
        />
      )}

      <BottomNavigationAction
        value="/logout"
        icon={<AccountCircle />}
        label={
          <>
            <Text
              text={currentUserName}
              wordBreak="break-word"
              textColor={`${theme.palette.black}`}
              fontSize={12}
            />
            <Text text={'Esci'} textColor="red" fontSize={12} />
          </>
        }
      />
      <CustomModal
        show={openScan}
        largeContainer
        onClose={() => setOpenScan(false)}
        content={<FlowerScanQrOrder />}
      />
      <CustomModal
        show={openLogout}
        onClick={onLogout}
        onClose={() => setOpenLogout(false)}
        hasAction
        content={
          <Stack width="250px">
            <Text
              text="Sei sicuro di voler effettura il logout?"
              bold
              textAligned="center"
            />
          </Stack>
        }
      />
    </StyledNavigation>
  )
}

export default Sidebar
