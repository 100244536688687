function ErrorIcon() {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 674 674"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M196.668 673.407L0.430664 477.17V196.831L196.668 0.59375H477.007L673.244 196.831V477.17L477.007 673.407H196.668ZM228.44 598.65H445.235L598.487 445.398V228.603L445.235 75.3508H228.44L75.1877 228.603V445.398L228.44 598.65ZM230.308 495.859L336.837 389.33L443.366 495.859L495.696 443.529L389.167 337L495.696 230.472L443.366 178.142L336.837 284.67L230.308 178.142L177.979 230.472L284.507 337L177.979 443.529L230.308 495.859Z"
        fill="#EE5959"
      />
    </svg>
  )
}

export default ErrorIcon
