import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

type Props = {
  flowName: string
}

export const Reset = React.memo(({ flowName }: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'flower/reset', payload: { flowName } })
  }, [dispatch, flowName])

  return null
})
