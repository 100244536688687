import { TypographyProps } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { memo, useEffect, useState } from 'react'
import { ThemeColor, theme } from '../../style/theme'
import { StyledText } from './styled'

type Props = {
  className?: string
  text: string | undefined | number
  textColor?: string
  themeColor?: ThemeColor
  variant?: Variant
  bold?: boolean
  fontSize?: number
  value?: string
  textAligned?: 'right' | 'left' | 'center' | 'inherit'
  wordwrap?: string
  textOverflow?: string
  whitespace?: string
  width?: string
  overflowwrap?: string
  uppercase?: boolean
  wordBreak?: string
  sx?: TypographyProps['sx']
}

const TextCmp = ({
  value,
  text,
  textColor,
  themeColor,
  bold,
  fontSize,
  textAligned,
  className,
  wordwrap,
  textOverflow,
  whitespace,
  width,
  overflowwrap,
  uppercase,
  variant = 'body1',
  wordBreak,
  sx
}: Props) => {
  const color = themeColor ? `${theme.palette[themeColor]}` : textColor

  return (
    <StyledText
      className={className}
      textColor={color}
      variant={variant}
      bold={bold}
      fontSize={fontSize}
      textAligned={textAligned}
      wordwrap={wordwrap}
      textOverflow={textOverflow}
      whitespace={whitespace}
      width={width}
      overflowwrap={overflowwrap}
      uppercase={uppercase}
      wordBreak={wordBreak}
      sx={sx}
    >
      {value || text}
    </StyledText>
  )
}

export const Text = memo(TextCmp)
