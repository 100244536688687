import { Store } from '../stores/model'

export type Details = {
  phone?: string
  email?: string
  payment: boolean
  deliveryAt?: string
  address?: string
  notes?: string
  deliveryStoreId?: string
  deliveryDate: string
  originalDate?: string
  deliveryTime: string
}

export type Orders = Order[]

export type Order = {
  _id?: string
  orderNumber?: string
  status: OrderStatus
  storeId: string
  createdAt: string
  createdBy: string
  items: Items[]
  details?: Details
  store?: Store
  id?: string
}

export type Items = {
  status?: Status
  productId?: string
  quantity?: number
}

export type OrderStatus =
  | 'new'
  | 'pending'
  | 'ready'
  | 'delivered'
  | 'toDeliver'
  | 'completed'
  | 'deleted'

export type Status =
  | 'new'
  | 'pending'
  | 'ready'
  | 'processing'
  | 'toDeliver'
  | 'delivered'
  | 'completed'

export enum StatusCode {
  new = 'new',
  pending = 'pending',
  processing = 'processing',
  ready = 'ready',
  toDeliver = 'toDeliver',
  delivered = 'delivered',
  completed = 'completed',
  deleted = 'deleted'
}

export type OrdersSlice = {
  filters: {
    status: string | undefined
  }
  tempDeliveryDate?: string | undefined
  selectId?: string | undefined | selectedOrder
  selectStatus?: string | undefined
  items: Orders
  search?: string
}

export type selectedOrder = {
  id: string | undefined
  toggle?: boolean
  status?: string | undefined
}
