import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import { keyframes, Stack, styled } from '@mui/material'
import { Status } from '../../features/products/model'
import { theme } from '../../style/theme'

type Props = {
  borderColor?: Omit<Status, 'completed'>
  bgColor?: Omit<Status, 'completed'>
}

const cardAnim = keyframes`
0% { opacity: 0}
100% { opacity: 1 }
`
export const StyledInfoIcon = styled(InfoIcon)`
  font-size: 0.8em;
`

export const StyledAlertQuantity = styled(ErrorOutlineIcon)`
  width: 14px;
  padding-left: 2px;
`

export const StyledRow = styled(Stack, {
  shouldForwardProp: (props) => props !== 'borderColor' && props !== 'sideBorder'
})(({ borderColor }: Props) => ({
  // @ts-ignore
  borderLeft: borderColor ? `6px solid ${theme.status[borderColor]}` : 'none',
  backgroundColor: '#FEFEFE',
  padding: '10px 0 10px 10px',
  borderRadius: '15px',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  minHeight: '90px',
  position: 'relative',
  marginBottom: '20px',
  boxShadow: ' 0px 1px 25px rgba(0, 0, 0, 0.06)',
  animationName: `${cardAnim}`,
  animationDuration: '0.3s'
}))

export const StyledBadge = styled('div', {
  shouldForwardProp: (props) => props !== 'bgColor'
})(({ bgColor }: Props) => ({
  position: 'absolute',
  left: '55px',
  top: '10px',
  width: '30px',
  height: '30px',
  // @ts-ignore
  backgroundColor: bgColor ? `${theme.status[bgColor]}` : 'red',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  borderRadius: '15px'
}))
