import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrderProductCart } from '../../../features/cart/model'
import { cartActions } from '../../../features/cart/reducer'
import {
  OrderProduct,
  OrderProductExtendend
} from '../../../features/ordersProducts/model'
import { theme } from '../../../style/theme'
import CustomModal from '../../CustomModal'
import { Text } from '../../Text'

type Props = {
  productNote: string | undefined
  handleClick: () => {
    payload: OrderProductCart
    type: 'cart/editNote'
  }
  value: OrderProduct
  setProductNote: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const ModalAction = ({
  productNote,
  handleClick,
  value,
  setProductNote
}: Props) => {
  return (
    <CustomModal
      width={'30%'}
      subtitle={'Visualizza o modifica note:'}
      hasInputField
      hasAction
      placeholder={productNote}
      initialValue={value.note}
      onClick={handleClick}
      onChange={setProductNote}
      openModal={
        <Text
          text={productNote || 'Nessuna nota'}
          textColor={value?.note ? theme.palette.danger : theme.palette.lightGrey}
          fontSize={14}
          wordwrap="anywhere"
          textOverflow={'ellipsis'}
          width={'90px'}
          whitespace={'nowrap'}
        />
      }
    />
  )
}

export const ModalView = ({ productNote, handleClick, value }: Props) => {
  return productNote === '' ? (
    <Text text={'Nessuna nota'} textColor={theme.palette.lightGrey} fontSize={14} />
  ) : (
    <CustomModal
      width={'30%'}
      placeholder={productNote}
      onClick={handleClick}
      openModal={
        <Text
          text={productNote !== '' ? productNote : 'Nessuna nota'}
          textColor={value?.note ? theme.palette.danger : theme.palette.lightGrey}
          fontSize={14}
          wordwrap="anywhere"
          textOverflow={'ellipsis'}
          width={'90px'}
          whitespace={'nowrap'}
        />
      }
    />
  )
}

export const ModalNote = ({ value }: { value: OrderProductExtendend }) => {
  const [productNote, setProductNote] = useState(value.note)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    () =>
      dispatch(
        cartActions.editNote({
          productId: value?.productId,
          quantity: 1,
          newNote: productNote,
          note: value.note
        })
      ),
    [dispatch, value?.productId, value.note, productNote]
  )

  return value.status === undefined ? (
    <ModalAction
      productNote={productNote}
      handleClick={handleClick}
      setProductNote={setProductNote}
      value={value}
    />
  ) : (
    <ModalView
      productNote={productNote}
      handleClick={handleClick}
      setProductNote={setProductNote}
      value={value}
    />
  )
}
