import { Phone } from '@mui/icons-material'
import Person2Icon from '@mui/icons-material/Person2'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ordersActions } from '../../features/orders/reducer'
import { selectStores } from '../../features/stores/selector'
import CustomModal from '../CustomModal'
import Select from '../Select'
import { Text } from '../Text'
import { StyledBox, StyledImg, StyledPhoneLine } from './styled'

type Props = {
  name: string
  phone?: string
  src?: string
  person?: boolean
  direction?: 'row' | 'column'
  textOverflow?: string
  textAligned?: 'right' | 'left' | 'center' | 'inherit' | undefined
  whitespace?: string
  width?: number
  fontSize?: number
  imgSize?: number
  editable?: boolean
}

export const DeliveryInfo = ({
  src,
  name,
  phone,
  person,
  direction = 'row',
  textAligned,
  textOverflow,
  fontSize,
  whitespace,
  imgSize,
  editable = false,
  width = 250
}: Props) => {
  const IconDefault = person ? Person2Icon : StoreMallDirectoryIcon
  const defaultStoreValue = useSelector(selectStores).find((e) => e.name == name)?._id
  const storesOptions = useSelector(selectStores).map(({ _id: id, name: title }) => ({
    id,
    title
  }))
  const [storeID, setStoreID] = useState(defaultStoreValue || '')
  const dispatch = useDispatch()
  const onCloseChangeAddress = () => {
    dispatch(ordersActions.changeAddress(storeID))
  }

  const StoreName = (
    <Text
      text={name}
      bold
      textAligned={textAligned}
      textOverflow={textOverflow}
      fontSize={fontSize}
      whitespace={whitespace}
      wordBreak="word-break"
    />
  )

  return (
    <Stack direction={direction} alignItems="center" gap={2} width={width}>
      <StyledImg person={person} imgSize={imgSize}>
        {src ? (
          <img style={{ borderRadius: '50%' }} src={src} alt={name} />
        ) : (
          <IconDefault />
        )}
      </StyledImg>
      <StyledBox>
        {editable ? (
          <CustomModal
            openModal={StoreName}
            content={
              <Select
                label="Seleziona negozio"
                id="store-select"
                staticOptions={storesOptions}
                onChange={setStoreID}
                value={storeID}
              />
            }
            onClose={() => onCloseChangeAddress()}
          />
        ) : (
          StoreName
        )}
        {phone && (
          <StyledPhoneLine width={width}>
            <Phone fontSize="small" />
            <Text
              text={phone}
              textOverflow={textOverflow}
              textAligned={textAligned}
              whitespace="nowrap"
              fontSize={fontSize}
            />
          </StyledPhoneLine>
        )}
      </StyledBox>
    </Stack>
  )
}
