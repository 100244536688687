import EmptyState from '../../components/EmptyState/EmptyState'
import { Text } from '../../components/Text'

type Props = {
  extraContent?: string
}

export const EmptyStateTable = ({ extraContent }: Props) => {
  return (
    <EmptyState
      title="Nessun Prodotto trovato"
      text="Non sono stati trovati prodotti con il filtro"
      icon="detail"
      iconSize="100px"
      children={<Text text={extraContent} themeColor="danger" variant="h6" bold />}
    />
  )
}
