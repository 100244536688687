function EmptyCartIcon() {
  return (
    <svg
      width="76"
      height="84"
      viewBox="0 0 76 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0299 0.5V15.5909H26.4677L41.592 30.6818L56.7164 15.5909H49.1542V0.5M0 8.04545V15.5909H7.56219L21.1741 44.2636L15.8806 53.3182C15.5025 54.45 15.1244 55.5818 15.1244 57.0909C15.1244 61.2409 18.5274 64.6364 22.6866 64.6364H68.0597V57.0909H24.199C23.8209 57.0909 23.4428 56.7136 23.4428 56.3364V55.9591L26.8458 49.5455H54.8259C57.4726 49.5455 60.1194 48.0364 61.2537 45.7727L76 19.3636L69.5721 15.5909L54.8259 42H28.3582L12.4776 8.04545M22.6866 68.4091C18.5274 68.4091 15.1244 71.8045 15.1244 75.9545C15.1244 80.1045 18.5274 83.5 22.6866 83.5C26.8458 83.5 30.2488 80.1045 30.2488 75.9545C30.2488 71.8045 26.8458 68.4091 22.6866 68.4091ZM60.4975 68.4091C56.3383 68.4091 52.9353 71.8045 52.9353 75.9545C52.9353 80.1045 56.3383 83.5 60.4975 83.5C64.6567 83.5 68.0597 80.1045 68.0597 75.9545C68.0597 71.8045 64.6567 68.4091 60.4975 68.4091Z"
        fill="#424242"
      />
    </svg>
  )
}

export default EmptyCartIcon
