import { Cake, LocalShippingRounded, CalendarMonth } from '@mui/icons-material'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import PrintIcon from '@mui/icons-material/Print'
import { memo, ReactNode, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ThemeColor } from '../../style/theme'
import Calendar from '../Calendar'
import EmptyCartIcon from '../EmptyState/EmptyCartIcon'
import EmptyOrderStateIcon from '../EmptyState/EmptyOrderStateIcon'
import ErrorIcon from '../ResultState/ErrorIcon'
import { StyledIcon } from './styled'

type IconType = Record<string, ReactNode>

type Props = {
  icon: string
  color?: ThemeColor
  size?: string
  justify?: string
  onClick?: () => void
  customAction?: string
  actionType?: string
  flowName?: string
  stepId?: string
  value?: any
  pointer?: boolean
}

const Icon = ({
  icon,
  color,
  size,
  justify,
  actionType,
  onClick,
  customAction,
  value,
  flowName,
  stepId,
  pointer
}: Props) => {
  const dispatch = useDispatch()

  const iconMap: IconType = {
    print: <PrintIcon />,
    delete: <DeleteIcon />,
    error: <ErrorIcon />,
    clear: <ClearIcon />,
    orders: <EmptyOrderStateIcon />,
    emptyCart: <EmptyCartIcon />,
    production: <Cake />,
    delivery: <LocalShippingRounded />,
    detail: <InsertChartIcon />,
    changeDate: <CalendarMonth />,
    calendar: <Calendar />,
    paper: <ArticleOutlinedIcon />
  }

  const onClickFlower = useCallback(() => {
    const valueObject = typeof value === 'object'
    dispatch({
      type: actionType === 'custom' && customAction ? customAction : actionType,
      payload: { flowName, node: stepId, ...(valueObject ? value : { value }) }
    })
  }, [dispatch, flowName, actionType, stepId, customAction, value])

  return (
    <StyledIcon
      color={color}
      size={size}
      justify={justify}
      onClick={onClick || onClickFlower}
      pointer={pointer}
    >
      {iconMap[icon]}
    </StyledIcon>
  )
}

export default memo(Icon)
