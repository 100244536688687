import { PropsWithChildren } from 'react'
import { OrderProduct } from '../../features/ordersProducts/model'
import { StyledStatusColumn } from './styled'

type Props = {
  status?: any
  list: OrderProduct[]
}

function StatusColumn({ status, children }: PropsWithChildren<Props>) {
  return <StyledStatusColumn status={status}>{children}</StyledStatusColumn>
}

export default StatusColumn
