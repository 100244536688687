import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Order, selectedOrder, StatusCode } from '../../../features/orders/model'
import { ordersActions } from '../../../features/orders/reducer'
import { selectCurrentOrder } from '../../../features/orders/selectors'
import { DeliveryInfo } from '../../DeliveryInfo'
import { ListItem } from '../../ListItem'
import { PointOfSale } from '../../PointOfSale'
import { StatusBadge } from '../../StatusBadge'

type Props = {
  orders: any
}

function OrdersList({ orders }: Props) {
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (o: selectedOrder) => {
      dispatch(ordersActions.setSelectedOrderId(o))
      dispatch({ type: 'flower/reset', payload: { flowName: 'orderDetail' } })
    },
    [dispatch]
  )

  const selectedOrder = useSelector(selectCurrentOrder)

  return (
    <>
      {orders.map((order: Order, i: number) => (
        <ListItem
          orderData={order}
          key={i}
          isSelected={order._id?.toString() === selectedOrder?._id?.toString()}
          onClick={() => handleClick({ id: order._id, status: order.status })}
          statusBadge={
            <StatusBadge
              status={order?.status || StatusCode.new}
              text={`Ordine n. ${order.orderNumber}`}
            />
          }
          status={order?.status}
          deliveryTime={order?.details?.deliveryTime}
          detail={
            order?.details?.deliveryStoreId ? (
              <PointOfSale storeId={order?.details?.deliveryStoreId} />
            ) : (
              <DeliveryInfo
                name={`${order?.details?.deliveryAt}`}
                phone={order?.details?.phone}
                person
              />
            )
          }
          rightComponent={<ArrowRightAltIcon />}
        />
      ))}
    </>
  )
}

export default OrdersList
