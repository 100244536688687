import { styled, Box, Divider, Paper } from '@mui/material'
import { theme } from '../../style/theme'
import imageBg from './loginBg.png'

export const StyledLogin = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  background: `no-repeat url(${imageBg})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: `${theme.background.light}`
})

export const StyledLoginForm = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  padding: '20px',
  minWidth: 340,
  minHeight: 340
})

export const LoginDivider = styled(Divider)({
  padding: '8px',
  border: 0
})

export const StyledLoginContent = styled(Box)({
  textAlign: 'center'
})
