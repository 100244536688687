import './App.css'
import { ThemeProvider } from '@mui/material'
import { useState } from 'react'
import { Provider } from 'react-redux'
import AsyncFlow from './components/AsyncFlow'
import { Watch } from './components/Watch'
import { store } from './features/store'
import OnBoarding from './pages/OnBoarding/OnBoarding'
import { theme } from './style/theme'
import './flower'
import { isUserLoggedIn } from './utils'

function App() {
  const [isLogged, setIsLogged] = useState(false)

  isUserLoggedIn()
    .then((value) => {
      setIsLogged(value || false)
    })
    .catch((error) => {
      console.log(error)
    })

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <OnBoarding />
        <AsyncFlow />
        {isLogged && <Watch />}
      </Provider>
    </ThemeProvider>
  )
}

export default App
