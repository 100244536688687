import { put, call, takeEvery } from 'redux-saga/effects'
import { getStores } from '../../utils'
import { Store } from './model'
import { storesActions } from './reducer'

function* fetchStores(action: ReturnType<typeof storesActions.fetchStores>) {
  const stores: Store[] = yield call(getStores)
  yield put(storesActions.storesLoaded(stores))
}

export function* storesSaga() {
  yield takeEvery(storesActions.fetchStores.toString(), fetchStores)
}
