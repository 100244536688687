import Flower from '@stackhouseos/flower-client'

import {
  selectCurrentOrderWithStore,
  selectProductsCurrentOrder
} from '../../features/orders/selectors'
import flow from './flower.json'

export const FlowerScanQrOrder = () => {
  return (
    <Flower
      name={flow.name}
      reducerName="app"
      elements={flow.elements}
      selectors={{
        selectedOrder: selectCurrentOrderWithStore,
        orderProducts: selectProductsCurrentOrder
      }}
    />
  )
}
