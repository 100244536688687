import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as RadioGroupMaterial
} from '@mui/material'
import { useCallback, useEffect } from 'react'

type Props = {
  options: { title: string; id: string }[]
  id: string
  value: boolean
  autoselect?: boolean
  onChange: (val: string) => void
}

const RadioGroup = ({ options, value, id, onChange, autoselect }: Props) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange]
  )

  useEffect(() => {
    if (autoselect && !value) onChange(options[0].id)
  }, [autoselect, onChange, options, value])

  return (
    <FormControl>
      <FormLabel id={id} />
      <RadioGroupMaterial
        name={`${id}-radiogroup`}
        value={value || ''}
        onChange={handleChange}
        id={id}
      >
        {options.map((o) => (
          <FormControlLabel
            key={o.id}
            value={o.id}
            control={<Radio size="small" />}
            label={o.title}
            labelPlacement="end"
          />
        ))}
      </RadioGroupMaterial>
    </FormControl>
  )
}

export default RadioGroup
