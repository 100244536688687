import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  SelectChangeEvent
} from '@mui/material'
import { memo, useCallback, useEffect } from 'react'

type Props = {
  value?: string
  dynamicOptions?: { title: string; id: string }[]
  label?: string
  id: string
  staticOptions: { title: string; id: string }[]
  onChange: (val: string) => void
  autoselect?: boolean
}

const Select = ({
  dynamicOptions = [],
  staticOptions = [],
  id,
  label,
  value,
  onChange,
  autoselect
}: Props) => {
  const optionsToMap = dynamicOptions.length >= 1 ? dynamicOptions : staticOptions

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const { value } = event.target
      onChange(value)
    },
    [onChange]
  )

  useEffect(() => {
    if (autoselect && !value) onChange(optionsToMap[0].id)
  }, [autoselect, onChange, optionsToMap, value])

  return (
    <FormControl variant="standard">
      <InputLabel>{label}</InputLabel>
      <MaterialSelect id={id} onChange={handleChange} type="select" value={value || ''}>
        {optionsToMap.map((o) => (
          <MenuItem value={o.id} key={o.id}>
            {o.title}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  )
}

export default memo(Select)
