import productPlaceholder from '../../assets/product_placeholder.png'
import { Product } from '../../features/products/model'
import { StyledImage } from './styled'

type Props = {
  product?: Product
  imgWidth?: number
  imgHeight?: number
  onClick?: () => void
}

function ProductImg({ product, imgWidth, imgHeight, onClick }: Props) {
  return (
    <StyledImage
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      onClick={onClick}
      src={product?.img || productPlaceholder}
      alt={product?.title}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = productPlaceholder
      }}
    />
  )
}

export default ProductImg
