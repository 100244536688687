import { memo, ReactNode, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { appActions } from '../../features/app/reducer'
import { selectPendingFlowId } from '../../features/app/selectors'
import { OrderProductExtendend } from '../../features/ordersProducts/model'
import Loader from '../Loader'
import { StyledArrow, StyledArrowContainer, StyledArrowList } from './styled'

type Props = {
  value?: OrderProductExtendend
  enablePrev?: boolean
  enableNext?: boolean
  children?: ReactNode
}

const StatusAction = memo(({ value, enablePrev, enableNext }: Props) => {
  const dispatch = useDispatch()
  const { _id } = value || {}
  const loading = useSelector(selectPendingFlowId(`productStatus-${_id}`))
  const noActions = !enablePrev && !enableNext

  const onClick = useCallback(
    (direction: string) => {
      if (_id) {
        dispatch(
          appActions.addFlow({
            id: `productStatus-${_id}`,
            flow: 'productStatus',
            state: {
              id: _id?.toString(),
              direction
            }
          })
        )
      }
    },
    [_id, dispatch]
  )

  if (!_id) return null

  return (
    <StyledArrowList
      gap={0}
      flexDirection="column"
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'absolute'}
      top={0}
      right={0}
      height={'100%'}
    >
      {loading && <Loader showLoader={loading} />}
      {noActions || loading ? (
        <></>
      ) : (
        <>
          {enablePrev && (
            <StyledArrowContainer
              back
              status={value?.status}
              single={!enableNext}
              onClick={() => {
                onClick('prev')
              }}
            >
              <StyledArrow back status={value?.status} single={!enableNext} />
            </StyledArrowContainer>
          )}
          {enableNext && (
            <StyledArrowContainer
              disabled={!enableNext}
              single={!enablePrev}
              status={value?.status}
              onClick={() => {
                onClick('next')
              }}
            >
              <StyledArrow status={value?.status} single={!enableNext} />
            </StyledArrowContainer>
          )}
        </>
      )}
    </StyledArrowList>
  )
})

export default StatusAction
