function EmptyOrderStateIcon() {
  return (
    <svg
      width="72"
      height="59"
      viewBox="0 0 72 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.583008 0.333496V58.6668H71.4163V0.333496H0.583008ZM17.2497 8.66683V17.0002H8.91634V8.66683H17.2497ZM8.91634 33.6668V25.3335H17.2497V33.6668H8.91634ZM8.91634 42.0002H17.2497V50.3335H8.91634V42.0002ZM63.083 50.3335H25.583V42.0002H63.083V50.3335ZM63.083 33.6668H25.583V25.3335H63.083V33.6668ZM63.083 17.0002H25.583V8.66683H63.083V17.0002Z"
        fill="#424242"
      />
    </svg>
  )
}

export default EmptyOrderStateIcon
