import { put, call, takeEvery, select } from 'redux-saga/effects'
import { getProducts, uploadProducts } from '../../utils'
import { checkCategory } from '../../utils/checkCategory'
import { appActions } from '../app/reducer'
import { CategoryDTO } from '../categories/model'
import { categoriesActions } from '../categories/reducer'
import { Products } from './model'
import { productsActions } from './reducer'
import {
  selectCategories,
  selectCategoriesMappedByName,
  selectProducts
} from './selectors'

function* fetchProducts(action: ReturnType<typeof productsActions.fetchProducts>) {
  const products: Products = yield call(getProducts)
  yield put(productsActions.productsLoaded(products))
}

function* addProducts(action: ReturnType<typeof productsActions.addProductsRequested>) {
  try {
    const categories: ReturnType<typeof selectCategories> = yield select(
      selectCategoriesMappedByName
    )
    const newProducts = action.payload
    const missingCategories: CategoryDTO[] = yield checkCategory(newProducts, categories)
    const existingProduct: Products = yield select(selectProducts)

    if (missingCategories.length > 0) {
      yield put(
        categoriesActions.addCategoriesRequested({
          categories: missingCategories,
          products: newProducts
        })
      )
      // yield put(categoriesActions.fetchCategories())
    } else {
      yield uploadProducts(newProducts, categories, existingProduct)
    }

    yield put(appActions.showModalMessage({ type: 'success', message: 'Fatto!' }))
  } catch (error) {
    if (error instanceof Error)
      yield put(appActions.showModalMessage({ type: 'error', message: error.message }))
    else if (typeof error === 'string' || Array.isArray(error))
      yield put(appActions.showModalMessage({ type: 'error', message: error }))
    else console.log(error)
  }
}

export function* productSaga() {
  yield takeEvery(productsActions.fetchProducts.toString(), fetchProducts)
  yield takeEvery(productsActions.addProductsRequested.toString(), addProducts)
}
