import { StyledFormControl } from '../styled'
import { StyledInput } from './styled'

type Props = {
  id?: string
  value?: string | number
  title?: string
  type?: string
  required?: boolean
  onBlur?: () => void
  onChange: (val: string | number) => void
}

export const Input = ({
  value,
  id,
  onChange,
  title,
  required,
  onBlur,
  type = 'string'
}: Props) => {
  return (
    <StyledFormControl>
      <StyledInput
        id={id}
        placeholder={title + `${required ? '*' : ''}`}
        inputProps={{ style: { textAlign: 'center' } }}
        aria-describedby={title}
        name={id}
        value={value}
        onBlur={onBlur}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(type === 'number' ? e.target.valueAsNumber : e.target.value)
        }
        type={type}
        required={required}
      />
    </StyledFormControl>
  )
}
