import styled from '@emotion/styled'
import { Stepper } from '@mui/material'
import { StepLabel } from '@mui/material'

export const StyledStepper = styled(Stepper)({
  margin: '10px',
  padding: '10px 3vw 30px',
  fontSize: '10px'
})

export const StyledStepLabel = styled(StepLabel)<{ color?: string }>`
  color: ${({ theme, color }) => color};
  font-size: 10px;
  svg {
    font-size: 20px;
  }
  span {
    height: 10px;
  }
`
