import { Box, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Button } from '../../components/Button'
import Loader from '../../components/Loader'
import { Logo } from '../../components/Logo'
import { Text } from '../../components/Text'
import { selectError, selectLoading } from '../../features/app/selectors'
import { LoginDivider, StyledLogin, StyledLoginForm, StyledLoginContent } from './styled'

function Login() {
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const loading = useSelector(selectLoading)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const disableLogin = useMemo(() => !password || !email, [email, password])

  useEffect(() => {
    dispatch({ type: 'CHECK_USER' })
  }, [dispatch])

  const onClickLogin = useCallback(() => {
    dispatch({ type: 'LOGIN', payload: { email, password } })
  }, [dispatch, email, password])

  return (
    <StyledLogin>
      <StyledLoginContent>
        <StyledLoginForm elevation={2}>
          <Logo />
          <Box component="form" noValidate autoComplete="off">
            <Typography variant="h4" component="h1">
              Login
            </Typography>
            <LoginDivider />
            <TextField
              id="user-email"
              name="email"
              type="email"
              required
              fullWidth
              label="Email"
              onChange={(e: any) => setEmail(e.target.value)}
              variant="standard"
            />
            <LoginDivider />
            <TextField
              id="user-password"
              type="password"
              name="password"
              required
              fullWidth
              onChange={(e: any) => setPassword(e.target.value)}
              label="Password"
              variant="standard"
            />
            <LoginDivider />
            {error && <Text text={error} textColor="red" />}
            <LoginDivider />
            <Button
              title="Login"
              endIcon={<Loader showLoader={loading} />}
              variant="outlined"
              textColor="black"
              borderRadius={20}
              disabled={disableLogin}
              onClick={onClickLogin}
            />
          </Box>
        </StyledLoginForm>
      </StyledLoginContent>
    </StyledLogin>
  )
}

export default Login
