import { QrScanner } from '@yudiel/react-qr-scanner'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

type Props = {
  flowName?: string
}

export const QRScanner = ({ flowName }: Props) => {
  const [startScan, setStartScan] = useState(true)
  const dispatch = useDispatch()

  const handleScan = (id: string) => {
    if (id && flowName) {
      dispatch({ type: 'orders/scanQrOrderSaga', payload: { flowName, id } })
      setStartScan(false)
    }
  }

  const handleError = (err: Error) => {
    console.error(err)
  }

  return startScan ? (
    <QrScanner
      scanDelay={100}
      onError={handleError}
      onDecode={handleScan}
      containerStyle={{ width: 300, height: 300, paddingTop: '0%' }}
      videoStyle={{ width: 300, height: 300 }}
    />
  ) : (
    <></>
  )
}
