import { Stack } from '@mui/material'
import Flower from '@stackhouseos/flower-client'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { FilterHeader } from '../../components/FilterHeader'
import { selectFormattedFilterDate } from '../../features/app/selectors'
import { deliveryActions } from '../../features/delivery/reducer'
import {
  makeSelectDeliveryOrdersFilterOpts,
  selectDeliveryFilter,
  selectOrderGroupedByDeliveryDate,
  selectSearchFilterDelivery
} from '../../features/delivery/selectors'
import { OrderDetail } from '../OrderDetail'
import PageContent from '../PageContent/PageContent'
import flow from './delivery.flower.json'

function Delivery() {
  const dispatch = useDispatch()

  const filterOpts = useSelector(makeSelectDeliveryOrdersFilterOpts)
  const value = useSelector(selectDeliveryFilter)
  const searchValue = useSelector(selectSearchFilterDelivery)

  const handleChange = useCallback(
    (val: string) => dispatch(deliveryActions.setSearchFilter(val)),
    [dispatch]
  )

  return (
    <Stack
      gap={2}
      justifyContent="space-between"
      width={'100%'}
      paddingBottom={35}
      paddingTop="20px"
    >
      <FilterHeader
        filterOpts={filterOpts}
        value={value.status}
        action={deliveryActions.setFilter}
        searchAction={handleChange}
        searchValue={searchValue}
      />

      <PageContent
        leftCmp={
          <Flower
            name={flow.name}
            reducerName="delivery"
            elements={flow.elements}
            selectors={{
              groupedOrders: selectOrderGroupedByDeliveryDate,
              filterOpts: makeSelectDeliveryOrdersFilterOpts,
              filterDate: selectFormattedFilterDate
            }}
          />
        }
        rightCmp={<OrderDetail />}
      />
    </Stack>
  )
}

export default Delivery
