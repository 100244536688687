export function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return Array.from(bytes.buffer)
}

class Printer {
  print() {
    return Promise.resolve()
  }
}

export default Printer
