import _find from 'lodash/find'
import _get from 'lodash/get'
import { createSelector } from 'reselect'
import { getFormattedDate } from '../../utils'
import type { RootState } from '../store'

export const selectApp = (state: RootState) => state.app

export const selectPendingFlow = createSelector(selectApp, (state) => state.asyncFlow)
export const selectLoggedIn = createSelector(selectApp, (state) => state.loggedIn)
export const selectError = createSelector(selectApp, (state) => state.error)
export const selectLoading = createSelector(selectApp, (state) => state.loading)
export const selectUser = createSelector(selectApp, (state) => state.user)
export const selectFilterDate = createSelector(selectApp, (state) => state.filterDate)
export const selectShowFilterDate = createSelector(selectApp, (state) => state.dateSwitch)
export const selectModalState = createSelector(selectApp, (state) => state.modal)

export const selectFormattedFilterDate = createSelector(selectFilterDate, (date) =>
  date ? getFormattedDate(date) : date
)

export const selectCurrentStoreId = createSelector(selectUser, (user) =>
  _get(user, 'currentStoreId')
)

export const selectPendingFlowId = (name: string) =>
  createSelector(selectPendingFlow, (asyncFlow) => _find(asyncFlow, { id: name }))

export const selectLastQrCodeId = (state: RootState) => state.app.lastQrCodeId

export const selectUserName = createSelector(selectUser, (user) => user.name)

export const selectModalMessage = createSelector(
  selectModalState,
  (modal) => modal?.message
)
