import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme, ThemeColor } from '../../style/theme'

type Props = {
  bgColor?: ThemeColor
  textColor?: ThemeColor
  border?: string
  borderRadius?: number
  fontWeight?: number
  width?: string
  align?: string
}

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'bgColor' &&
    prop !== 'textColor' &&
    prop !== 'border' &&
    prop !== 'fontWeight' &&
    prop !== 'borderRadius' &&
    prop !== 'width' &&
    prop !== 'align'
})(
  ({
    fontWeight,
    width,
    align,
    textColor = 'black',
    bgColor = 'primary',
    borderRadius = 0,
    border
  }: Props) => ({
    backgroundColor: `${theme.palette[bgColor]}`,
    borderColor: textColor,
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    padding: '6px 24px',
    color: `${theme.palette[textColor]}`,
    justifyContent: 'center',
    borderRadius: `${borderRadius}px`,
    fontWeight: `${fontWeight}`,
    alignSelf: align,
    boxShadow: 'none',
    border: border,
    width: width,
    '&:hover': {
      borderColor: textColor,
      opacity: '0.8',
      backgroundColor: `${theme.palette[bgColor]}`,
      boxShadow: 'none'
    }
  })
)
