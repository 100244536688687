import { memo, ReactNode } from 'react'
import { StyledStack } from './styled'

type Props = {
  children?: ReactNode
  gap?: number
  justifyContent?: string
  alignItems?: string
  alignContent?: string
  height?: string
  width?: string
  inline?: boolean
  scrollY?: boolean
  marginTop?: string
  alignSelf?: string
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse' | undefined
}

const Stack = ({
  children,
  gap,
  justifyContent,
  flexDirection,
  alignContent,
  alignItems,
  alignSelf,
  height,
  width,
  marginTop,
  inline,
  scrollY
}: Props) => {
  return (
    <StyledStack
      display={inline ? 'inline' : 'flex'}
      gap={gap ? Number(gap) : undefined}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      alignContent={alignContent}
      alignItems={alignItems}
      alignSelf={alignSelf}
      height={height}
      width={width}
      marginTop={marginTop}
      scrollY={scrollY}
    >
      {children}
    </StyledStack>
  )
}

export default memo(Stack)
