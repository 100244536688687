import { styled, Grid, Stack } from '@mui/material'
import { theme } from '../../style/theme'

export const StyledGrid = styled(Grid)({
  fontFamily: 'Lato, sans-serif'
})

export const StyledStack = styled(Stack)`
  padding-top: 2vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const StyledLeftComponent = styled('div')`
  width: 100%;
  padding-top: ${theme.container.paddingTop};
  overflow: scroll;
  // height: calc(100vh - 136px);
  padding-bottom: 25px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100vh - 94px - ${theme.container.paddingTop});
  ::-webkit-scrollbar {
    display: none;
  }
`
export const StyledRightComponent = styled(Stack)`
  height: 100vh;
  min-width: 412px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: ${theme.background.grayContrast};
  padding-inline: 20px;
  box-shadow: '0px 1px 25px rgba(0, 0, 0, 0.1)';
  border-radius: 15px 0 15px 15px;
  &::-webkit-scrollbar {
    display: none;
  }
`

// height: '100vh';
