import { put, call, takeEvery } from 'redux-saga/effects'
import { getOrderProducts, updateOrderProductsQuantity } from '../../utils'
import { OrderProduct } from './model'
import { ordersProductsActions } from './reducer'

function* fetchOrdersProductsList() {
  try {
    const products: OrderProduct[] = yield call(getOrderProducts)
    yield put(ordersProductsActions.ordersProductsLoaded(products))
  } catch (error) {
    console.log('error', error)
  }
}

function* fetchOrdersProducts({ payload }: any) {
  const { flowName } = payload || {}
  try {
    const products: OrderProduct[] = yield call(getOrderProducts)
    yield put(ordersProductsActions.ordersProductsLoaded(products))
  } catch (error) {
  } finally {
    if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

function* updateOrdersProductsQuantitySaga({ payload }: any) {
  try {
    yield call(updateOrderProductsQuantity, payload)
    yield put({ type: 'fetchOrdersProductsList' })
  } catch (error) {
    console.log('error', error)
  } finally {
  }
}

export function* ordersProductsSaga() {
  yield takeEvery(
    ordersProductsActions.fetchOrdersProducts.toString(),
    fetchOrdersProducts
  )
  yield takeEvery('updateOrdersProductsQuantitySaga', updateOrdersProductsQuantitySaga)
  yield takeEvery('app/removeFlow', fetchOrdersProducts) // @TODO rivedere
  yield takeEvery('fetchOrdersProductsList', fetchOrdersProductsList)
}
