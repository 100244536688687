import { Stack } from '@mui/system'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { appActions } from '../../features/app/reducer'
import { Orders } from '../../features/orders/model'
import GroupOrdersDivider from './GroupOrdersDivider'
import OrderList from './OrderList/OrderList'

type Props = {
  groups: [string, Orders][]
}

function GroupOrdersContainer({ groups }: Props) {
  const dispatch = useDispatch()

  const onChange = useCallback(
    (date: string | undefined) => dispatch(appActions.setfiltersDate(date)),
    [dispatch]
  )

  return (
    <Stack gap={4}>
      {groups.map(([date, ords]) => {
        return (
          <div key={date}>
            <GroupOrdersDivider date={date} onChange={onChange} />
            <OrderList orders={ords} />
          </div>
        )
      })}
    </Stack>
  )
}

export default GroupOrdersContainer
