import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from '../../../style/theme'

export const StyledNavigation = styled(BottomNavigation)({
  position: 'fixed',
  top: '0',
  left: 0,
  width: '90px',
  height: '100vh',
  zIndex: 100,
  flexDirection: 'column',
  gap: '30px',
  backgroundColor: `${theme.background.light}`,
  padding: '0 5px 2vh',
  borderRadius: '15px 15px 15px',
  boxSizing: 'border-box'
})

export const StyledNavigationLogo = styled(BottomNavigationAction)({
  justifyContent: 'flex-start',
  paddingTop: '12px'
})
