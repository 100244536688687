import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton } from '@mui/material'
import { Stack } from '@mui/material'
import { memo, useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrderProduct } from '../../features/ordersProducts/model'
import { Input } from '../Form/Input/Input'

type Props = {
  quantity: number
  initial: number
  value: OrderProduct
  id: string
  disableButton: boolean
  incrementQuantitySaga: string
  decrementQuantitySaga: string
  variableQuantitySaga: string
}

const QuantitySelector = ({
  incrementQuantitySaga,
  decrementQuantitySaga,
  variableQuantitySaga,
  quantity,
  initial,
  value,
  disableButton,
  id
}: Props) => {
  const quantiyText = quantity || initial
  const dispatch = useDispatch()
  const [tada, setTada] = useState(false)

  const onIncrement = useCallback(() => {
    dispatch({ type: incrementQuantitySaga, payload: value })
  }, [dispatch, incrementQuantitySaga, value])

  const onDecrement = useCallback(() => {
    dispatch({ type: decrementQuantitySaga, payload: value })
  }, [decrementQuantitySaga, dispatch, value])

  const onChangeInput = useCallback(
    (newQuantity: string | number) => {
      dispatch({
        type: variableQuantitySaga,
        payload: { ...value, newQuantity }
      })
    },
    [dispatch, value, variableQuantitySaga]
  )

  useLayoutEffect(() => {
    const elmnt = document.getElementById(id)
    if (elmnt) {
      setTimeout(() => {
        setTada(true)
      }, 300)
      setTimeout(() => {
        setTada(false)
      }, 1000)
    }
  }, [id])

  return (
    <Stack id={id} direction="row" justifyContent="center" alignItems="center">
      <IconButton
        className={tada ? 'animated tada' : ''}
        onClick={onDecrement}
        aria-label="remove"
        disabled={disableButton}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
      <Input
        type="number"
        value={quantity === 0 ? undefined : quantiyText}
        onChange={onChangeInput}
        title="0"
      />
      <IconButton
        className={tada ? 'animated tada' : ''}
        onClick={onIncrement}
        aria-label="add"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </Stack>
  )
}

export default memo(QuantitySelector)
