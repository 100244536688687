import { memo } from 'react'
import { ThemeColor } from '../../style/theme'
import { StyledBadge } from './styled'
import TimeIcon from './TimeIcon'

type Props = {
  bgColor?: ThemeColor
  text?: string | number
  timeBadge?: Boolean
}

export const Badge = ({ bgColor = 'turquoise', text, timeBadge }: Props) => {
  return (
    <StyledBadge>
      {timeBadge && <TimeIcon />}
      {text}
      {timeBadge && 'h'}
    </StyledBadge>
  )
}

export default memo(Badge)
