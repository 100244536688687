// import jrules from '@stackhouseos/json-rules'
import get from 'lodash/get'
import React, { Children, PropsWithChildren, ReactNode, Suspense, useMemo } from 'react'
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom'
import * as Pages from '..'
import { PageLayout } from '../PageLayout/PageLayout'

type RouteT = {
  path: string
  label: string
  element: ReactNode
  enable: boolean
}[]

function Navigation({
  children,
  checkRules,
  prefix
}: PropsWithChildren<{ checkRules: any; prefix: string }>) {
  const enableRoutes = Children.toArray(children)
    .map((child) => {
      const childProps = get(child, ['props', 'child'])
      const rules = get(child, ['props', 'child', 'rules'])
      const check = checkRules(rules, prefix)
      const path: string = get(childProps, 'path', '')

      if (!check) return undefined

      return {
        path,
        label: childProps.label,
        enable: check,
        // @ts-ignore
        element: React.cloneElement(child)
      }
    })
    .filter(Boolean) as RouteT

  const routes = useMemo(
    () =>
      enableRoutes.map((e) => ({
        path: e.path,
        label: e.label
      })),
    [enableRoutes]
  )

  return (
    <Suspense fallback={<></>}>
      <BrowserRouter>
        <Routes>
          <Route element={<PageLayout routes={routes} />}>
            {enableRoutes.map((child, index) => (
              <Route
                key={child.path}
                index={index === 0}
                path={index > 0 ? child.path : undefined}
                element={child.element}
              />
            ))}
          </Route>
          <Route path="404" element={<Pages.Page404 />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default Navigation
