import styled from '@emotion/styled'
import { Input } from '@mui/material'

export const StyledInput = styled(Input)`
  /* hide arrows
 Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
`
