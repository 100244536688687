import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { appActions } from '../../features/app/reducer'

function ProcessingFlow({ flowName, flow, state = {} }) {
  const dispatch = useDispatch()
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) {
      dispatch(appActions.addFlow({ id: `${flow}-${state.id}`, flow, state }))
      dispatch({ type: 'flower/next', payload: { flowName } })
      ref.current = true
    }
  }, [dispatch, flow, flowName, state, ref])

  return undefined
}

export default React.memo(ProcessingFlow)
