import { styled, Stack } from '@mui/material'

export const StyledStackContent = styled(Stack)`
  padding-top: 20px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`
