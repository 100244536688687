import Flower from '@stackhouseos/flower-client'
import { keyBy } from 'lodash'
import React, { useMemo } from 'react'

const contextClient = require.context('../../', true, /[\s\S]*?.flower.client.json$/)
const context = require.context('../../', true, /[\s\S]*?.flower.json$/)
const flowers = context
  .keys()
  .map((filename) => context(filename))
  .filter((e) => e.type === 'flow')

const flowersClient = contextClient
  .keys()
  .map((filename) => contextClient(filename))
  .filter((e) => e.type === 'flow')

const flow = keyBy([...flowers, ...flowersClient], 'name')

export const Subflow = React.memo(function UseFlow({
  source,
  flowName,
  subReducerName,
  subFlowName,
  extraParams,
  startId,
  selectors,
  modalKey
}) {
  const extraContext = useMemo(
    () => ({
      parentFlow: flowName,
      subflow: (extraParams || []).reduce(
        (acc, inc) => ({ ...acc, [inc.name]: inc.value }),
        {}
      ),
      modalKey,
      showErrors: true
    }),
    [extraParams, flowName, modalKey]
  )

  return flow[source || subFlowName] ? (
    <Flower
      destroyOnUnmount
      parentFlow={flowName}
      elements={flow[source || subFlowName].elements}
      name={`${subFlowName}-${flowName}`}
      source={subFlowName}
      extraContext={extraContext}
      startId={startId}
      selectors={selectors}
      reducerName={subReducerName} // non mettere punti nel nome
    />
  ) : (
    'NO_FLOW'
  )
})
