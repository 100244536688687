import { SwipeableDrawer } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Flower from '@stackhouseos/flower-client'
import { Fragment, useState } from 'react'
import ProductImg from '../../../components/ProductImg'
import { StyledArrow } from '../../../components/StatusAction/styled'
import { OrderProductDetail } from '../../../features/ordersProducts/model'
import { selectFilteredOrderProducts } from '../../../features/ordersProducts/selectors'
import flow from '../../Production/production.flower.json'
import {
  StackDrower,
  StyledPaper,
  StyledSpan,
  StyledTableCell,
  StyledTableCellAction,
  StyledTableCellBig,
  StyledTableRow
} from './styled'

type Props = {
  rows: OrderProductDetail[]
}

export default function BasicTable({ rows }: Props) {
  const [selected, setSelected] = useState<string | undefined>(undefined)
  return (
    <TableContainer component={StyledPaper}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <StyledTableRow>
            <TableCell align="left">Sku</TableCell>
            <TableCell align="left" />
            <TableCell>Nome</TableCell>
            <TableCell align="left">Totale</TableCell>
            <TableCell align="left">In attesa</TableCell>
            <TableCell align="left">In corso</TableCell>
            <TableCell align="left">Fatto</TableCell>
            <TableCell align="left">Rimanenti</TableCell>
            <TableCell align="left" />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Fragment key={row.productId + row.note}>
              <TableRow>
                <StyledTableCellBig
                  align="left"
                  bold="bold"
                  bgColor="grayContrast"
                  onClick={() => setSelected(row.productId)}
                >
                  {row.sku}
                </StyledTableCellBig>
                <StyledTableCell align="left">
                  <ProductImg imgWidth={80} imgHeight={60} product={row} />
                  {selected === row.productId && (
                    <SwipeableDrawer
                      anchor="bottom"
                      open={!!selected}
                      onClose={() => setSelected(undefined)}
                      onOpen={() => console.log('test')}
                    >
                      <StackDrower maxHeight={'70vh'} minHeight={'70vh'}>
                        <Flower
                          name={flow.name}
                          reducerName="ordersProducts"
                          elements={flow.elements}
                          selectors={{
                            filtered: selectFilteredOrderProducts
                          }}
                          extraContext={{
                            // @ts-ignore
                            pending: row['pending_items'],
                            // @ts-ignore
                            processing: row['processing_items'],
                            // @ts-ignore
                            ready: row['ready_items']
                          }}
                        />
                      </StackDrower>
                    </SwipeableDrawer>
                  )}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.title}
                  <StyledSpan color="danger">{' ' + (row.note || '')}</StyledSpan>
                </StyledTableCell>

                <StyledTableCellBig bold="bold" align="left">
                  {row.total}
                </StyledTableCellBig>
                <StyledTableCell align="left">{row.pending}</StyledTableCell>
                <StyledTableCell align="left">{row.processing}</StyledTableCell>
                <StyledTableCell align="left">{row.ready}</StyledTableCell>
                <StyledTableCellBig
                  bold="bold"
                  align="left"
                  bgColor="grayContrast"
                  onClick={() => setSelected(row.productId)}
                >
                  {row.remains}
                </StyledTableCellBig>
                <StyledTableCellAction
                  align="right"
                  onClick={() => setSelected(row.productId)}
                >
                  <StyledArrow />
                </StyledTableCellAction>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
