import { ReactNode, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ThemeColor } from '../../style/theme'
import { StyledButton } from './styled'

type Props = {
  title?: string | ReactNode
  icon?: ReactNode
  bgColor?: ThemeColor
  textColor?: ThemeColor
  borderRadius?: number
  fontWeight?: number
  width?: string
  verticalAlign?: string
  onClick?: () => void
  actionType?: string
  flowName?: string
  stepId?: string
  customAction?: string
  value?: any
  variant?: 'text' | 'outlined' | 'contained' | undefined
  disabled?: boolean
  endIcon?: ReactNode
}

export const Button = ({
  title,
  icon,
  onClick,
  actionType,
  flowName,
  bgColor,
  textColor,
  fontWeight,
  width,
  verticalAlign,
  borderRadius = 0,
  stepId,
  customAction,
  value,
  disabled,
  endIcon,
  variant = 'contained'
}: Props) => {
  const dispatch = useDispatch()

  const onClickFlower = useCallback(() => {
    const valueObject = typeof value === 'object'
    dispatch({
      type: actionType === 'custom' && customAction ? customAction : actionType,
      payload: { flowName, node: stepId, ...(valueObject ? value : { value }) }
    })
  }, [dispatch, flowName, actionType, stepId, customAction, value])

  return (
    <StyledButton
      variant={variant}
      onClick={onClick || onClickFlower}
      bgColor={bgColor}
      textColor={textColor}
      borderRadius={borderRadius}
      fontWeight={fontWeight}
      width={width}
      align={verticalAlign}
      disabled={disabled}
      endIcon={endIcon}
    >
      {title || icon}
    </StyledButton>
  )
}
