import { Box, styled } from '@mui/material'

type Props = {
  bgColor?: string
  isSelected: boolean
}

export const StyledFilterButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'bgColor'
})(({ bgColor, isSelected }: Props) => ({
  display: 'flex',
  padding: '2px 15px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isSelected ? `${bgColor}` : 'none',
  border: isSelected ? `2px solid ${bgColor}` : `2px solid ${bgColor}`,
  borderRadius: '15px',
  cursor: 'pointer',
  minWidth: '90px',
  width: 'max-content',
  textAlign: 'center',
  '&:hover': {
    opacity: isSelected ? '0.7' : '0.5'
  }
}))
