import { createAction, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import reducerCrud from '../../utils/reducerCrud'
import { Orders, Status, OrdersSlice, selectedOrder } from './model'

export const FORMATDATE = 'YYYY/MM/DD'

const initialState: OrdersSlice = {
  items: [],
  selectId: undefined,
  filters: { status: undefined },
  search: ''
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    filterStatus(state, { payload }: PayloadAction<Status | undefined | string>) {
      state.filters.status = state.filters.status === payload ? '' : payload

      state.selectId = state.filters.status === state.selectStatus ? state.selectId : ''

      state.search = ''
    },
    ordersLoaded(state, { payload }: PayloadAction<Orders>) {
      state.items = payload.map((r) => ({
        ...r,
        _id: r._id?.toString(),
        createdAt: dayjs(r.createdAt).format(FORMATDATE),
        deliveryDate: dayjs(r.details?.deliveryDate).format(FORMATDATE)
      }))
    },
    setSelectedOrderId(state, { payload }: PayloadAction<selectedOrder>) {
      const { toggle = true, id, status } = payload

      if (id !== state.selectId) state.tempDeliveryDate = undefined
      state.selectId = id === state.selectId && toggle ? '' : id
      if (status) state.selectStatus = status
    },
    resetSelectedOrderId(state) {
      state.selectId = undefined
    },
    searchedOrders(state, { payload }: PayloadAction<string>) {
      state.search = payload
      state.filters.status = undefined
    },
    ...reducerCrud
  },
  extraReducers: {
    'delivery/setfiltersDate': (state) => {
      state.selectId = undefined
    }
  }
})

export const ordersActions = {
  ...ordersSlice.actions,
  fetchOrders: createAction('orders/fetchOrders'),
  createOrderSaga: createAction('orders/createOrderSaga'),
  deleteOrderSaga: createAction('orders/deleteOrderSaga'),
  printOrder: createAction('orders/printOrder'),
  scanQrOrderSaga: createAction('orders/scanQrOrderSaga'),
  changeDeliveryDate: createAction('orders/changeDeliveryDate'),
  changeAddress: createAction<string>('orders/changeAddress')
}

export const ordersReducer = ordersSlice.reducer
