import _findIndex from 'lodash/findIndex'
import PrinterWeb from './PrinterWeb'

let printUsb = null

class PrinterWebUsb extends PrinterWeb {
  async printerInitDevice() {
    function setup(device) {
      return device
        .open()
        .then(() => device.selectConfiguration(device.configuration.configurationValue))
        .then(() =>
          device.claimInterface(device.configuration.interfaces[0].interfaceNumber)
        )
        .then(() => device)
    }

    if (printUsb == null) {
      return navigator.usb
        .requestDevice({
          filters: []
        })
        .then((device) => setup(device))
        .then((characteristic) => {
          printUsb = characteristic
          return printUsb
        })
        .catch((err) => {
          console.log('err', err)
          return Promise.reject()
        })
    } else {
      return printUsb
    }
  }

  printByDevice(result) {
    const findOutIndex = _findIndex(
      printUsb.configuration.interfaces[0].alternate.endpoints,
      { direction: 'out' }
    )
    return printUsb.transferOut(
      printUsb.configuration.interfaces[0].alternate.endpoints[findOutIndex]
        .endpointNumber,
      result
    )
  }
}

export default PrinterWebUsb
