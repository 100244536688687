import { createAction, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Store, StoresSlice } from './model'

const initialState: StoresSlice = {
  items: []
}

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    storesLoaded(state, { payload }: PayloadAction<Store[]>) {
      state.items = payload.map((r) => ({ ...r, _id: r._id.toString() }))
    }
  }
})

export const storesActions = {
  ...storesSlice.actions,
  fetchStores: createAction('stores/fetchStores')
}

export const storesReducer = storesSlice.reducer
