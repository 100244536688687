import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { appActions } from '../../features/app/reducer'

type Props = {
  flowName: string
  asyncFlow: string
  parentFlow?: string
  reducer: string
  redurceName: string
  selector: string
}

export const AsyncFlowCreate = React.memo(
  ({ flowName, asyncFlow, reducer, redurceName, selector }: Props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const one = useRef<boolean>(false)

    useEffect(() => {
      if (asyncFlow && !one.current) {
        dispatch(
          appActions.addFlow({
            id: asyncFlow,
            flow: asyncFlow,
            state: {}
          })
        )
        dispatch({ type: 'flower/next', payload: { flowName } })
        one.current = true
      }
    }, [dispatch, flowName, asyncFlow, reducer, redurceName, selector, params, one])

    return <></>
  }
)
