import { createSelector } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import _get from 'lodash/get'
import { selectCurrentStoreId, selectUser } from '../app/selectors'
import type { RootState } from '../store'

export const selectStores = (state: RootState) => state.stores.items

export const selectSearchOrderInput = (state: RootState) => state.orders.search

export const selectStoreIds = createSelector(selectStores, (stores) =>
  keyBy(stores, '_id')
)

export const makeSelectStoreById = (id: string) =>
  createSelector(selectStoreIds, (stores) => stores[id])

export const makeSelectMyStore = createSelector(
  selectCurrentStoreId,
  selectStoreIds,
  (currentStoreId, stores) => _get(stores, [currentStoreId])
)

export const selectStoresNames = createSelector(selectStores, (stores) =>
  stores.map((s) => {
    return { id: s._id, title: s.name }
  })
)

export const selectCurrentOrderStoreId = createSelector(
  selectUser,
  (storeId) => storeId.currentStoreId
)

export const selectOrderedStoresArray = createSelector(
  selectCurrentStoreId,
  selectStoresNames,
  (currentStoreid, storesArray) => {
    const newArray: { title: string; id: string }[] = []
    storesArray.map((store) =>
      currentStoreid && currentStoreid === store.id
        ? newArray.unshift(store)
        : newArray.push(store)
    )
    return newArray
  }
)
