import { CalendarMonth } from '@mui/icons-material'
import { Stack, SvgIcon } from '@mui/material'
import dayjs from 'dayjs'
import { memo, useCallback } from 'react'
import { FORMATDATE } from '../../features/orders/reducer'
import CustomModal from '../CustomModal'
import { ActionBar, DateCalendar } from '../Form/DatePicker/DateCalendar'

type Props = {
  date?: string
  onChange: (val: string | undefined) => void
}

const ModalCalendar = ({ date, onChange }: Props) => {
  const onClear = useCallback(() => onChange(undefined), [onChange])
  const setToday = useCallback(() => onChange(dayjs().format(FORMATDATE)), [onChange])

  return (
    <CustomModal
      openModal={
        <SvgIcon
          component={CalendarMonth}
          viewBox="0 0 22 22"
          fontSize="large"
          color="secondary"
          cursor="pointer"
        />
      }
      content={
        <Stack>
          <DateCalendar onChange={onChange} value={date} isFilter />
          <ActionBar onSetToday={setToday} onClear={onClear} />
        </Stack>
      }
    />
  )
}

export default memo(ModalCalendar)
