import { CircularProgress } from '@mui/material'
import { keyBy } from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { app } from '../../mongodb'

const context = require.context('../../', true, /[\s\S]*?.flower.server.json$/)
const flowers = context
  .keys()
  .map((filename) => context(filename))
  .filter((e) => e.type === 'flow')

const flowIds = keyBy(flowers, 'name')

export function Portal({ nodeId, flowName, sourceFlow, dataIn = {}, onChange }) {
  const dispatch = useDispatch()
  const flow = dataIn.flow || sourceFlow || flowName

  useEffect(() => {
    if (!flowIds[flow]) return

    app.currentUser.functions
      .flower(nodeId, flowIds[flow].elements, dataIn)
      .then((data) => {
        dispatch({
          type: 'flower/node',
          payload: {
            flowName,
            node: data.nodeId,
            value: data
          }
        })
      })
  }, [dispatch, nodeId, flowName, dataIn, onChange, flow])

  return <CircularProgress size={24} />
}
