import { ObjectId } from 'bson'
import { flatMap, uniqBy, keyBy, Dictionary } from 'lodash'
import { Category, CategoryDTO } from '../features/categories/model'
import { ProductDTO } from './convertExcelToJson'

export const checkCategory = (
  products: ProductDTO[],
  categories: Dictionary<Category>
): CategoryDTO[] => {
  const newCategories = flatMap(
    products,
    (product) =>
      product.category?.map((category) => ({
        name: category
      })) || []
  )
  const uniqueCategories = uniqBy(newCategories, 'name')

  const missingCategoriesMap = keyBy(
    uniqueCategories.filter((category) => !categories[category.name]),
    'name'
  )

  const missingCategories = Object.values(missingCategoriesMap).map((category) => ({
    _id: new ObjectId(),
    name: category.name
  }))

  return missingCategories
}
