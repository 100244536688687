import { Input, Stack } from '@mui/material'
import { ReactNode } from 'react'
import IconCmp from '../../IconCmp'
import { Text } from '../../Text'
import { StyledButton } from '../styled'

type Props = {
  hasAction?: boolean
  handleClose?: () => void
  handleConfirm?: () => void
  subtitle?: string
  hasInputField?: boolean
  onChange?: any
  placeholder?: string
  openModal?: ReactNode
  width?: number
  type?: string
  content?: ReactNode
}

export const ActionsModal = ({ hasAction, handleClose, handleConfirm }: Props) => {
  return (
    <Stack direction="row" gap={2}>
      {hasAction ? (
        <>
          <StyledButton onClick={handleClose} bgColor="mediumGrey">
            Annulla
          </StyledButton>
          <StyledButton onClick={handleConfirm} bgColor="danger">
            Conferma
          </StyledButton>
        </>
      ) : (
        <StyledButton onClick={handleClose} bgColor="mediumGrey">
          Chiudi
        </StyledButton>
      )}
    </Stack>
  )
}

export const TextModal = ({
  hasAction,
  subtitle,
  hasInputField,
  onChange,
  placeholder,
  content,
  type = 'number'
}: Props) => {
  return (
    <>
      <Text text={subtitle} />
      {hasInputField && (
        <Input
          type={type}
          fullWidth={true}
          multiline={true}
          onChange={(e) => onChange(e.target.value)}
          value={placeholder}
        />
      )}
      {!hasAction && (
        <Text text={placeholder} overflowwrap="anywhere" width="100%" whitespace="pre" />
      )}
      {content && content}
    </>
  )
}

const OpenButton = ({ icon, handleOpen, bgColor = 'danger', title }: any) => {
  return title ? (
    <StyledButton onClick={handleOpen} bgColor={bgColor}>
      {title}
    </StyledButton>
  ) : (
    <IconCmp icon={icon} onClick={handleOpen} color={bgColor} pointer={true} />
  )
}

export const OpenModal = ({
  handleOpen,
  openModal,
  icon,
  width,
  title,
  bgColor
}: any) => {
  return (
    <>
      {openModal ? (
        <Stack onClick={handleOpen} width={width}>
          {openModal}
        </Stack>
      ) : (
        <OpenButton icon={icon} handleOpen={handleOpen} bgColor={bgColor} title={title} />
      )}
    </>
  )
}
