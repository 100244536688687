import Flower from '@stackhouseos/flower-client'
import { memo } from 'react'
import {
  selectCartExtended,
  selectDeliveryHoursOpts,
  selectDeliveryOpts,
  selectShowDetails,
  selectTotal
} from '../../features/cart/selector'
import { selectOrderedStoresArray } from '../../features/stores/selector'
import flow from './cartOrder.flower.client.json'
import { StyledStackContent } from './styled'

function Cart() {
  return (
    <StyledStackContent>
      <Flower
        name={flow.name}
        reducerName="cart"
        elements={flow.elements}
        selectors={{
          cartOrderList: selectCartExtended,
          showForm: selectShowDetails,
          dynamicOptions: selectOrderedStoresArray,
          deliveryDate: selectDeliveryOpts,
          deliveryHours: selectDeliveryHoursOpts,
          total: selectTotal
        }}
      />
    </StyledStackContent>
  )
}

export default memo(Cart)
