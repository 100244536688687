import { FormControlLabel } from '@mui/material'
import { Switch as MuiSwitch } from '@mui/material'
import { StyledSwitchStack } from '../styled'

type Props = {
  onChange: (val: boolean) => void
  value?: boolean
  title?: string
  disabled?: boolean
}

function Switch({ value, onChange, title, disabled }: Props) {
  return (
    <StyledSwitchStack>
      <FormControlLabel
        control={<MuiSwitch checked={value} color="primary" size="small" />}
        label={title}
        disabled={disabled}
        labelPlacement="start"
        onChange={(e, checked) => onChange(checked)}
      />
    </StyledSwitchStack>
  )
}

export default Switch
