import { memo, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { selectLoggedIn } from '../../features/app/selectors'
import Sidebar from './Sidebar/Sidebar'
import { StyledPage, StyledPageContent, StyledSidebar } from './styled'

type Props = {
  routes?: any
  children?: ReactNode
}

export const PageLayout = memo(({ routes, children }: Props) => {
  const isLogged = useSelector(selectLoggedIn)
  if (!isLogged) return <>{children || <Outlet />}</>

  return (
    <StyledPage>
      <StyledSidebar
        item
        xs="auto"
        direction="column"
        justifyContent="flex-start"
        container
      >
        <Sidebar routes={routes} />
      </StyledSidebar>
      <StyledPageContent>{children || <Outlet />}</StyledPageContent>
    </StyledPage>
  )
})
