import { memo } from 'react'
import { ProductCard } from '../../../components/ProductCard'
import { StyledGrid } from '../styled'

function ProductList({ products = [] }: any) {
  return (
    <StyledGrid container gap={4}>
      {products.map((product: any) => (
        <ProductCard item={product} key={product._id} />
      ))}
    </StyledGrid>
  )
}

export default memo(ProductList)
