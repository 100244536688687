function SuccessIcon() {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 682 682"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M293.26 497.86L533.665 257.455L485.925 209.715L293.26 402.38L196.075 305.195L148.335 352.935L293.26 497.86ZM341 682C293.828 682 249.498 673.043 208.01 655.129C166.522 637.238 130.432 612.948 99.7425 582.258C69.0525 551.568 44.7619 515.478 26.8708 473.99C8.95694 432.502 0 388.172 0 341C0 293.828 8.95694 249.498 26.8708 208.01C44.7619 166.522 69.0525 130.432 99.7425 99.7425C130.432 69.0525 166.522 44.7506 208.01 26.8367C249.498 8.94556 293.828 0 341 0C388.172 0 432.502 8.94556 473.99 26.8367C515.478 44.7506 551.568 69.0525 582.258 99.7425C612.948 130.432 637.238 166.522 655.129 208.01C673.043 249.498 682 293.828 682 341C682 388.172 673.043 432.502 655.129 473.99C637.238 515.478 612.948 551.568 582.258 582.258C551.568 612.948 515.478 637.238 473.99 655.129C432.502 673.043 388.172 682 341 682ZM341 613.8C417.157 613.8 481.662 587.372 534.517 534.517C587.372 481.662 613.8 417.157 613.8 341C613.8 264.843 587.372 200.337 534.517 147.482C481.662 94.6275 417.157 68.2 341 68.2C264.843 68.2 200.337 94.6275 147.482 147.482C94.6275 200.337 68.2 264.843 68.2 341C68.2 417.157 94.6275 481.662 147.482 534.517C200.337 587.372 264.843 613.8 341 613.8Z"
        fill="#96BB63"
      />
    </svg>
  )
}

export default SuccessIcon
