import { actionUpdate } from '@stackhouseos/flower-client'
import * as Realm from 'realm-web'
import { put, takeLatest } from 'redux-saga/effects'
import { app } from '../../mongodb'
import { categoriesActions } from '../categories/reducer'
import { ordersActions } from '../orders/reducer'
import { ordersProductsActions } from '../ordersProducts/reducer'
import { productsActions } from '../products/reducer'
import { storesActions } from '../stores/reducer'

function* checkSession() {
  if (app.currentUser && app.currentUser.isLoggedIn) {
    yield put(actionUpdate('app', 'loggedIn', true))
    yield put(actionUpdate('app', 'user', app.currentUser.customData))
    yield put({ type: 'flower/reset', payload: { flowName: 'onboarding' } })
  }
}

function* login({ payload }: any) {
  const { email, password } = payload
  yield put(actionUpdate('app', 'error', undefined))
  yield put(actionUpdate('app', 'loading', true))

  try {
    const lowerEmail = email.toString().toLowerCase()
    const credentials = Realm.Credentials.emailPassword(lowerEmail, password)
    // @ts-ignore
    const user = yield app.logIn(credentials)
    console.log('Login ok')

    // @ts-ignore
    const customUserData = yield user.refreshCustomData()

    yield put(actionUpdate('app', 'loggedIn', true))
    yield put(actionUpdate('app', 'user', customUserData))

    // reset flow onboarding
    yield put({ type: 'flower/reset', payload: { flowName: 'onboarding' } })
    window.location.reload()
  } catch (error) {
    yield put(actionUpdate('app', 'error', 'invalid username/password'))
  } finally {
    yield put(actionUpdate('app', 'loading', false))
  }
}

function* logout() {
  try {
    if (app.currentUser) {
      window.location.reload()
      yield app.currentUser.logOut()
      console.log('Logout ok')
    }
    yield put(actionUpdate('app', 'loggedIn', false))

    // reset flow onboarding
    yield put({ type: 'flower/reset', payload: { flowName: 'onboarding' } })
  } catch (error) {
    console.log('logout ~ error:', error)
    //
  }
}

function* logoutONLY() {
  if (app.currentUser) {
    yield app.currentUser.logOut()
    console.log('Logout ok')
  }
}

function* loadData({ payload }: any) {
  const { flowName } = payload || {}

  try {
    // LOAD DATA
    console.log('load data')

    // LOAD CATEGORIES
    yield put(categoriesActions.fetchCategories())

    // LOAD PRODUCTS
    yield put(productsActions.fetchProducts())

    // LOAD ORDERS
    yield put(ordersActions.fetchOrders())

    // LOAD ORDERS PRODUCT
    yield put(ordersProductsActions.fetchOrdersProducts())

    // LOAD STORES
    yield put(storesActions.fetchStores())
  } catch (error) {
  } finally {
    if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

function* watchAll({ payload }: any) {
  const { flowName } = payload || {}

  try {
    // create watchers
    // yield put({ type: 'orders/WATCH' })
    // yield put({ type: 'orders-products/WATCH' })
  } catch (error) {
    // if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

export function* appSaga() {
  yield takeLatest('LOGIN', login)
  yield takeLatest('CHECK_USER', checkSession)
  yield takeLatest('LOGOUT', logout)
  yield takeLatest('ONLYLOGOUT', logoutONLY)
  yield takeLatest('LOAD_DATA', loadData)
  yield takeLatest('WATCH_DATA', watchAll)
}
