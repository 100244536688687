import AddIcon from '@mui/icons-material/Add'
import { Button, Card, Stack, keyframes, styled } from '@mui/material'
import { theme } from '../../style/theme'
import ProductImg from '../ProductImg'
import { Text } from '../Text'

type StyledProps = {
  bgColor?: string
}

const cardAnim = keyframes`
0% { left: 100%; opacity: 0}
100% { left: 0; opacity: 1 }
`

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})(({ bgColor = `${theme.background.grayContrast}` }: StyledProps) => ({
  backgroundColor: bgColor,
  flex: 1,
  height: '100%',
  width: '160px',
  maxHeight: '250px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  textAlign: 'center',
  boxShadow: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  animationName: `${cardAnim}`,
  animationDuration: '0.3s',
  position: 'relative'
}))

export const StyledTitle = styled(Text)({
  width: '100%'
})

export const StyledTitleContainer = styled(Stack)({
  justifyContent: 'center',
  overflowWrap: 'break-word',
  width: '100%',
  height: '48px'
})

export const StyledImage = styled(ProductImg)`
  border-radius: 20px;
`

export const StyledIcon = styled(AddIcon)`
  color: ${theme.palette.black.main};
  margin-right: 5px;
`

export const StyledStack = styled(Stack)({
  cursor: 'pointer',
  BorderRight: '1px solid black'
})

export const StyledButton = styled(Button)({
  borderLeft: `1px solid ${theme.palette.black.main}`,
  borderRadius: 0,
  paddingRight: '20px',
  '&:hover': {
    opacity: '0.9',
    backgroundColor: 'inherit',
    boxShadow: 'none'
  }
})
