import { Stack, styled } from '@mui/material'

type Props = {
  scrollY?: boolean
}

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'scrollY'
})(({ scrollY }: Props) => ({
  overflowY: scrollY ? 'scroll' : 'hidden',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': { display: 'none' }
}))
