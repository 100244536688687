import styled from '@emotion/styled'
import { Stack } from '@mui/system'
import { theme } from '../../style/theme'
import { Text } from '../Text'

export const StyledGroupOrdersDivider = styled(Stack)({
  borderBottom: `1px solid ${theme.palette.turquoise}`,
  marginBottom: '15px',
  paddingBottom: '8px',
  cursor: 'pointer'
})

export const StyledText = styled(Text)({
  lineHeight: '1.1'
})
