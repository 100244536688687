import { CheckCircle, HourglassBottomRounded, WarningRounded } from '@mui/icons-material'
import { Step } from '@mui/material'
import { StatusCode } from '../../features/orders/model'
import { statusTranslate } from '../../features/orders/selectors'
import { theme } from '../../style/theme'
import { StyledStepper, StyledStepLabel } from './styled'

type Props = {
  listStatus: { status: StatusCode }[]
}

const mapIcons = {
  [StatusCode.pending]: <CheckCircle />,
  [StatusCode.processing]: <HourglassBottomRounded />,
  [StatusCode.toDeliver]: <></>,
  [StatusCode.ready]: <WarningRounded />,
  [StatusCode.delivered]: <></>,
  [StatusCode.completed]: <></>,
  [StatusCode.new]: <></>,
  [StatusCode.deleted]: <></>
}

function StepperStatus({ listStatus }: Props) {
  return (
    <StyledStepper activeStep={1} alternativeLabel>
      {listStatus.map(({ status }) => (
        <Step key={status}>
          <StyledStepLabel color={`${theme.status[status]}`} icon={mapIcons[status]}>
            {statusTranslate[status]}
          </StyledStepLabel>
        </Step>
      ))}
    </StyledStepper>
  )
}

export default StepperStatus
