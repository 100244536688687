import { useSelector } from 'react-redux'
import { makeSelectStoreById } from '../../features/stores/selector'
import { DeliveryInfo } from '../DeliveryInfo'

type Props = {
  storeId: string
  enablePhone?: boolean
  direction?: 'row' | 'column'
  textAligned?: 'right' | 'left' | 'center' | 'inherit' | undefined
  textOverflow?: string
  whitespace?: string
  width?: number
  fontSize?: number
  imgSize?: number
}

export const PointOfSale = ({
  storeId,
  direction = 'row',
  enablePhone = true,
  textOverflow,
  textAligned,
  whitespace,
  fontSize,
  width,
  imgSize
}: Props) => {
  const store = useSelector(makeSelectStoreById(storeId))

  return store ? (
    <DeliveryInfo
      direction={direction}
      name={store.name}
      phone={enablePhone ? store.phone : undefined}
      src={store.img}
      textAligned={textAligned}
      textOverflow={textOverflow}
      whitespace={whitespace}
      fontSize={fontSize}
      width={width}
      imgSize={imgSize}
    />
  ) : null
}
