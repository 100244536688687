import { useCallback } from 'react'
import { getFormattedDate } from '../../utils'
import Calendar from '../Calendar'
import { StyledGroupOrdersDivider, StyledText } from './styled'

type Props = {
  date: string
  counter?: number
  onChange: (val: string | undefined) => any
}

function GroupOrdersDivider({ date, onChange }: Props) {
  const dateToRender = getFormattedDate(date)

  const handleClick = useCallback(() => {
    onChange(date)
  }, [date, onChange])

  return (
    <StyledGroupOrdersDivider
      direction="row"
      gap={2}
      alignItems={'center'}
      onClick={handleClick}
    >
      <Calendar date={date} />
      <StyledText text={dateToRender} fontSize={22} bold />
    </StyledGroupOrdersDivider>
  )
}

export default GroupOrdersDivider
