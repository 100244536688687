import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { appActions } from '../../features/app/reducer'

function CompleteFlow({ flowName }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(appActions.removeFlow({ id: flowName }))
  }, [dispatch, flowName])

  return undefined
}

export default React.memo(CompleteFlow)
