import { StyledTimeIconContainer } from './styled'

function TimeIcon() {
  return (
    <StyledTimeIconContainer>
      <svg
        width="40"
        height="45"
        viewBox="0 0 50 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0195 5.63156V0.150879H33.1327V5.63156H17.0195ZM25.0761 57.698C21.7639 57.698 18.642 57.0472 15.7103 55.7456C12.7786 54.4439 10.2161 52.6741 8.02292 50.4361C5.82973 48.1982 4.09532 45.5835 2.81969 42.5919C1.54406 39.6004 0.90625 36.4147 0.90625 33.035C0.90625 29.6552 1.54406 26.4696 2.81969 23.478C4.09532 20.4865 5.82973 17.8718 8.02292 15.6338C10.2161 13.3959 12.7786 11.6261 15.7103 10.3244C18.642 9.02273 21.7639 8.3719 25.0761 8.3719C27.8511 8.3719 30.5143 8.82863 33.0655 9.74207C35.6168 10.6555 38.0114 11.98 40.2493 13.7156L44.0091 9.87909L47.7688 13.7156L44.0091 17.552C45.7099 19.8357 47.0079 22.2791 47.9031 24.8825C48.7983 27.4858 49.2459 30.2033 49.2459 33.035C49.2459 36.4147 48.6081 39.6004 47.3324 42.5919C46.0568 45.5835 44.3224 48.1982 42.1292 50.4361C39.936 52.6741 37.3736 54.4439 34.4419 55.7456C31.5102 57.0472 28.3882 57.698 25.0761 57.698ZM25.0761 52.2174C30.2681 52.2174 34.6992 50.3448 38.3695 46.5997C42.0397 42.8545 43.8748 38.333 43.8748 33.035C43.8748 27.737 42.0397 23.2154 38.3695 19.4703C34.6992 15.7252 30.2681 13.8526 25.0761 13.8526C19.884 13.8526 15.4529 15.7252 11.7827 19.4703C8.11244 23.2154 6.27732 27.737 6.27732 33.035C6.27732 38.333 8.11244 42.8545 11.7827 46.5997C15.4529 50.3448 19.884 52.2174 25.0761 52.2174Z"
          fill="#4E5FFF"
          fillOpacity="0.19"
        />
      </svg>
    </StyledTimeIconContainer>
  )
}

export default TimeIcon
