import EventIcon from '@mui/icons-material/Event'
import { memo } from 'react'
import { getMonth } from '../../utils'
import { Text } from '../Text'
import { StyledBody, StyledHeader, StyledPage } from './styled'

type Props = {
  date?: string
}

const Calendar = ({ date }: Props) => {
  const day = date ? `${new Date(date).getDate()}` : ''
  const month = date ? getMonth(date) : ''

  return (
    <StyledPage justifyContent="flex-start" textAlign="center">
      <StyledHeader>
        <Text text={month} fontSize={8} bold />
      </StyledHeader>
      <StyledBody>
        {date && <Text text={day} fontSize={18} bold />}
        {!date && <EventIcon color="primary" />}
      </StyledBody>
    </StyledPage>
  )
}

export default memo(Calendar)
