import { Grid as MuiGrid } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  gap: string
  children: ReactNode
}

function Grid({ gap, children }: Props) {
  return <MuiGrid gap={+gap}>{children}</MuiGrid>
}

export default Grid
