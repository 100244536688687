import { memo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import IconCmp from '../../../components/IconCmp'
import {
  selectCurrentOrder,
  selectProductsCurrentOrder
} from '../../../features/orders/selectors'
import { makeSelectStoreById } from '../../../features/stores/selector'
import { ThemeColor } from '../../../style/theme'
import { OrderToPrint } from './OrderToPrint'

type Props = {
  icon: string
  iconSize?: string
  iconColor?: ThemeColor
}

const PdfPrinter = ({ icon, iconSize, iconColor = 'mediumGrey' }: Props) => {
  const order = useSelector(selectCurrentOrder)
  const products = useSelector(selectProductsCurrentOrder)
  const store = useSelector(makeSelectStoreById(order!.details?.deliveryStoreId || ''))

  const componentRef = useRef(null)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <>
      <IconCmp
        icon={icon}
        onClick={handlePrint}
        justify="flex-end"
        size={iconSize}
        color={iconColor}
        pointer
      />
      <div style={{ display: 'none' }}>
        <OrderToPrint
          ref={componentRef}
          order={order}
          products={products}
          store={store}
        />
      </div>
    </>
  )
}

export default memo(PdfPrinter)
