import { Stack } from '@mui/system'
import { ReactNode } from 'react'
import ErrorIcon from './ErrorIcon'
import SuccessIcon from './SuccessIcon'

type Props = {
  children: ReactNode
  result: string
}

function ResultState({ children, result }: Props) {
  return (
    <Stack
      gap={3}
      width={'100%'}
      minHeight={550}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {result === 'success' ? <SuccessIcon /> : <ErrorIcon />}
      {children}
    </Stack>
  )
}

export default ResultState
