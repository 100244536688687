import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledProductRowsContainer = styled(Stack)`
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledProductsRow = styled('div')`
  :first-of-type {
    padding-top: 20px;
  }

  :last-of-type {
    padding-bottom: 100px;
  }
`
