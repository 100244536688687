import { createAction, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import _remove from 'lodash/remove'
import reducerCrud from '../../utils/reducerCrud'
import { OrderProduct, OrdersProductsSlice } from './model'

const initialState: OrdersProductsSlice = {
  items: [],
  filters: {
    category: undefined
  },
  selected: undefined
}

export const ordersProductsSlice = createSlice({
  name: 'ordersProducts',
  initialState,
  reducers: {
    ordersProductsLoaded(state, { payload }: PayloadAction<OrderProduct[]>) {
      state.items = payload.map((r) => ({
        ...r,
        _id: r._id.toString(),
        // @ts-ignore
        updateAt: r.updateAt.toString()
      }))
    },
    setFilterCategory(state, { payload }: PayloadAction<string | undefined>) {
      state.filters.category = payload
    },
    searchOrdersProducts(state, { payload }: PayloadAction<string>) {
      state.search = payload
    },
    setQuantity(
      state,
      { payload }: PayloadAction<Pick<OrderProduct, '_id' | 'quantity'>>
    ) {
      const { quantity, _id } = payload
      state.items = state.items.map((e) =>
        e._id.toString() === _id.toString() ? { ...e, quantity } : e
      )
    },
    incrementQuantity(state, { payload }: PayloadAction<OrderProduct>) {
      state.items = state.items.map((e) =>
        e._id.toString() === payload._id.toString()
          ? { ...e, tempQuantity: (e.tempQuantity || e.quantity) + 1 }
          : e
      )
    },
    decrementQuantity(state, { payload }: PayloadAction<OrderProduct>) {
      state.items = state.items.map((e) => {
        if (e._id.toString() === payload._id.toString()) {
          const newQuantity = (e.tempQuantity || e.quantity) - 1
          return {
            ...e,
            tempQuantity: newQuantity > 1 ? newQuantity : 1
          }
        }
        return e
      })
    },
    inputQuantity(state, { payload }: PayloadAction<OrderProduct>) {
      state.items = state.items.map((e) => {
        if (e._id.toString() === payload._id.toString()) {
          const newQuantity = payload.newQuantity
          return {
            ...e,
            tempQuantity: newQuantity || 0
          }
        }
        return e
      })
    },
    restoreQuantity(state, { payload }: PayloadAction<OrderProduct>) {
      state.items = state.items.map((e) =>
        e._id.toString() === payload._id.toString() ? { ...e, quantity: e.quantity } : e
      )
    },
    increment(state, { payload }: PayloadAction<OrderProduct>) {
      const { _id } = payload
      state.items = state.items.map((e) =>
        e._id.toString() === _id.toString() ? { ...e, quantity: e.quantity + 1 } : e
      )
    },
    decrement(state, { payload }: PayloadAction<OrderProduct>) {
      const { _id } = payload
      state.items = state.items.map((e) =>
        e._id.toString() === _id.toString()
          ? { ...e, quantity: e.quantity - 1 < 0 ? 0 : e.quantity - 1 }
          : e
      )
    },
    deleteFromOrder(state, { payload }: PayloadAction<OrderProduct>) {
      _remove(state.items, payload)
    },
    setSelected(state, { payload }: PayloadAction<OrderProduct | undefined>) {
      state.selected = state.selected === payload ? undefined : payload
    },
    ...reducerCrud
  }
})

export const ordersProductsActions = {
  ...ordersProductsSlice.actions,
  fetchOrdersProducts: createAction('orders/fetchOrdersProducts'),
  updateOrdersProductsQuantitySaga: createAction(
    'orders/updateOrdersProductsQuantitySaga'
  )
}

export const ordersProductsReducer = ordersProductsSlice.reducer
