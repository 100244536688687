import {
  FormControl,
  FormControlLabel,
  Checkbox as CheckboxMaterial
} from '@mui/material'

type Props = {
  title: string
  value?: string
  id?: string
  onChange: (val: boolean) => void
}

function Checkbox({ id, title, value, onChange }: Props) {
  return (
    <FormControl
      sx={{ alignItems: 'start' }}
      variant="standard"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
    >
      <FormControlLabel
        id={id}
        value={value}
        control={<CheckboxMaterial />}
        label={title}
        labelPlacement="end"
        name={id}
      />
    </FormControl>
  )
}

export default Checkbox
