import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

function UpdateStatus({ showLoader, status, flowName, ...a }) {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {}, 1000)
  }, [dispatch, flowName])

  return showLoader ? <CircularProgress size={24} /> : undefined
}

export default React.memo(UpdateStatus)
