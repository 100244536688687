import { Box, styled, Stack } from '@mui/material'

export const StyledFilterButtonsContainer = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 1vw;
  padding-left: 4px;
`
export const StyledStack = styled(Stack)`
  align-items: flex-start;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
`
