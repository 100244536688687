import { Stack } from '@mui/material'
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { appActions } from '../../features/app/reducer'
import { selectFilterDate, selectShowFilterDate } from '../../features/app/selectors'
import { Status } from '../../features/orders/model'
import ModalCalendar from '../Calendar/ModalCalendar'
import FilterButtonsContainer from '../FilterButtonsContainer'
import SearchInput from '../SearchInput'
import { StyledSwitch } from './styled'

type Props = {
  filterOpts: {
    title: string
    value: string
  }[]
  value: string | undefined
  searchValue?: string
  action: ActionCreatorWithOptionalPayload<string | undefined | Status>
  searchAction?: (value: string) => void
  dateFilter?: boolean
}

export const FilterHeader = ({
  filterOpts,
  value,
  action,
  dateFilter,
  searchAction,
  searchValue = ''
}: Props) => {
  const dispatch = useDispatch()
  const date = useSelector(selectFilterDate)
  const switchValue = useSelector(selectShowFilterDate)

  const handleFilter = useCallback(
    (e: Status | undefined | string) => {
      dispatch(action(e))
    },
    [dispatch, action]
  )

  const handleDateFilter = useCallback(
    (date: string | undefined) => dispatch(appActions.setfiltersDate(date)),
    [dispatch]
  )

  const onSwitch = useCallback(() => {
    dispatch(appActions.showFiltersDate())
  }, [dispatch])

  return (
    <Stack direction="row" justifyContent="space-between" width="calc(100vw - 95px)">
      <Stack direction="row" alignItems="flex-end" gap={2} width="100%" height="40px">
        {searchAction && (
          <SearchInput
            id="search"
            label="Ricerca"
            onChange={searchAction}
            value={searchValue}
          />
        )}
        <Stack
          direction="row"
          gap={2}
          alignItems="flex-end"
          minWidth="130px"
          justifyContent="space-between"
        >
          {dateFilter && (
            <StyledSwitch checked={switchValue} value={switchValue} onChange={onSwitch} />
          )}
          {dateFilter && !switchValue && (
            <ModalCalendar date={date} onChange={handleDateFilter} />
          )}
        </Stack>
        <FilterButtonsContainer
          opts={filterOpts}
          onChange={(e: Status | string | undefined) => handleFilter(e)}
          value={value}
        />
      </Stack>
    </Stack>
  )
}
