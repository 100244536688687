import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { FORMATDATE } from '../orders/reducer'
import { App, ModalMessage } from './model'

const initialState: App = {
  asyncFlow: [],
  loggedIn: false,
  loading: false,
  dateSwitch: true,
  filterDate: dayjs().format(FORMATDATE)
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addFlow(state, action: PayloadAction<any>) {
      state.asyncFlow.push(action.payload)
    },
    removeFlow(state, action: PayloadAction<any>) {
      state.asyncFlow = state.asyncFlow.filter(
        (flow: any) => flow.id !== action.payload.id
      )
    },
    addLastIdQrCode(state, action: PayloadAction<string>) {
      state.lastQrCodeId = action.payload
    },
    setfiltersDate(state, { payload }: PayloadAction<string | undefined>) {
      state.filterDate = state.filterDate === payload ? undefined : payload
    },
    showFiltersDate(state) {
      state.dateSwitch = !state.dateSwitch
      state.filterDate = state.dateSwitch ? dayjs().format(FORMATDATE) : undefined
    },
    showModalMessage(state, action: PayloadAction<ModalMessage>) {
      state.modal = {
        message: action.payload
      }
    },
    hideModalMessage(state) {
      state.modal = undefined
    }
  }
})

export const appActions = appSlice.actions
export const appReducer = appSlice.reducer
