import { styled } from '@mui/material'
import { theme, ThemeColor } from '../../style/theme'

type Props = {
  color?: ThemeColor
  size?: string
  justify?: string
  pointer?: boolean
}

export const StyledIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color'
})(({ size, justify, color, pointer }: Props) => ({
  display: 'flex',
  justifyContent: justify,
  alignItems: 'center',
  width: size,
  height: size,
  cursor: pointer ? 'pointer' : 'inherit',

  '.MuiSvgIcon-root': {
    color: color ? theme.palette[color] : theme.palette.black.main,
    fontSize: size
  }
}))
