import { createSelector } from '@reduxjs/toolkit'
import { keyBy, Dictionary } from 'lodash'
import _flatMap from 'lodash/flatMap'
import _uniq from 'lodash/uniq'
import { Category } from '../categories/model'
import type { RootState } from '../store'

export const selectProducts = (state: RootState) => state.products.items

export const selectCategoriesSlice = (state: RootState) => state.categories

export const selectFilterProducts = (state: RootState) => state.products

export const selectProductsIds = createSelector(selectProducts, (products) =>
  keyBy(products, '_id')
)

export const makeSelectProductById = (id: string) =>
  createSelector(selectProductsIds, (products) => products[id])

export const selectCategories = createSelector(
  selectCategoriesSlice,
  ({ items }) => keyBy(items, '_id') as Dictionary<Category>
)

export const selectCategoriesMappedByName = createSelector(
  selectCategoriesSlice,
  ({ items }) => keyBy(items, 'name')
)

export const selectProductsFilter = createSelector(
  selectFilterProducts,
  (products) => products.filters.category
)

export const selectProductsByFilters = createSelector(
  selectFilterProducts,
  (redProducts) => {
    const { filters, items } = redProducts
    if (!filters.category) return items
    return items.filter((i) =>
      i?.category && filters.category ? i.category.includes(filters.category) : true
    )
  }
)

export const makeSelectProductsFilterOpts = createSelector(
  selectProducts,
  selectCategories,
  (products, categories) => {
    const opt = _uniq(_flatMap(products, 'category')).filter(Boolean)
    return opt.map((o) => ({ value: o, title: categories[o]?.name || '' }))
  }
)

export const selectInputProducts = createSelector(
  selectFilterProducts,
  (products) => products.search
)

export const selectSearchedProducts = createSelector(
  selectProducts,
  selectInputProducts,
  (products, input) => {
    return products.filter((product) =>
      product.title.toLowerCase().includes(input.toLowerCase())
    )
  }
)

export const selectFilteredProducts = createSelector(
  selectProductsByFilters,
  selectSearchedProducts,
  selectInputProducts,
  (filteredProducts, selectSearchedProducts, searchInput) => {
    return searchInput.toLowerCase() !== '' ? selectSearchedProducts : filteredProducts
  }
)
