import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ordersActions } from '../../features/orders/reducer'
import { ordersProductsActions } from '../../features/ordersProducts/reducer'
import { mongodb } from '../../mongodb'

export const Watch = React.memo(() => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'LOAD_DATA' })
  }, [dispatch])

  useEffect(() => {
    const run = async () => {
      for await (const change of mongodb().collection('orders').watch()) {
        dispatch(ordersActions.fetchOrders())
      }
    }
    run()
  }, [dispatch])

  useEffect(() => {
    const run = async () => {
      for await (const change of mongodb().collection('orders-products').watch()) {
        dispatch(ordersProductsActions.fetchOrdersProducts())
      }
    }
    run()
  }, [dispatch])

  return null
})
