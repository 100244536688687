import { PayloadAction } from '@reduxjs/toolkit'
import { updateNodeData } from '@stackhouseos/flower-client'
import { AnyAction } from 'redux'
import { put, call, takeEvery, select, all, take } from 'redux-saga/effects'
import {
  getOrders,
  deleteOrder,
  getOrderById,
  updateDeliveryDate,
  updateOrderAddress
} from '../../utils'
import Printer from '../../utils/printer'
import { selectCurrentStoreId } from '../app/selectors'
import { OrderProductExtendend } from '../ordersProducts/model'
import { getConversionValue } from '../ordersProducts/selectors'
import { Store } from '../stores/model'
import { makeSelectStoreById } from '../stores/selector'
import { Order, Orders } from './model'
import { ordersActions } from './reducer'
import {
  selectCompleteProductsCurrentOrder,
  selectCurrentOrder,
  selectTempDeliveryDate,
  statusTranslate
} from './selectors'

function* fetchOrders(
  { payload }: any /* ReturnType<typeof ordersActions.fetchOrders>*/
) {
  const { flowName } = payload || {}
  try {
    const orders: Orders = yield call(getOrders)
    yield put(ordersActions.ordersLoaded(orders))
  } catch (error) {
  } finally {
    if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

function* changeDeliveryDate({ payload }: any) {
  const { flowName } = payload || {}
  try {
    const order: Order = yield select(selectCurrentOrder)
    const newDeliveryDate: string = yield select(selectTempDeliveryDate)
    yield call(updateDeliveryDate, { ...order, newDeliveryDate })
  } catch (err) {
    yield put(updateNodeData(flowName, 'error', err))
  } finally {
    if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

function* changeAddress({ payload }: PayloadAction<string>) {
  try {
    const order: Order = yield select(selectCurrentOrder)
    yield call(updateOrderAddress, order.id!, payload)
    yield put(ordersActions.fetchOrders())
    yield take(ordersActions.fetchOrders)
  } catch (err) {
    console.log('error in changeAddress Saga', err)
  }
}

function* scanQrOrderSaga({ payload }: AnyAction) {
  const { flowName, id } = payload

  try {
    const order: Order = yield call(getOrderById, id)
    const userStoreId: string = yield select(selectCurrentStoreId)

    // if (userStoreId !== order.details?.deliveryStoreId)
    //   throw new Error('storeid-mismatch')

    if (order === null) throw new Error('no-order')
    yield put(updateNodeData(flowName, 'success', true))
    yield put(
      ordersActions.setSelectedOrderId({
        id,
        toggle: false,
        status: order.status
      })
    )
  } catch (err) {
    if (err instanceof Error) err = err.message
    yield put(updateNodeData(flowName, 'error', err))
    console.log('errore ' + err)
  } finally {
    if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

function* deleteOrderSaga(action: ReturnType<typeof ordersActions.deleteOrderSaga>) {
  try {
    const order: Order = yield select(selectCurrentOrder)
    yield call(deleteOrder, order)
  } catch (err) {
    console.log(err)
  }
}

function* printOrder({ payload }: any) {
  const { flowName } = payload || {}
  try {
    const order: Order = yield select(selectCurrentOrder)
    const products: OrderProductExtendend[] = yield select(
      selectCompleteProductsCurrentOrder
    )
    const printer = Printer('USB')
    yield printer.printerInit()
    yield printer.clearData()
    yield printer.setAlignment('center')

    yield printer.printTextWithFont(`Ordine: ${order.orderNumber}`, 'large')
    yield printer.lineWrap(1)

    yield printer.printTextWithFont(`${statusTranslate[order.status]}`, 'medium')
    yield printer.lineWrap(1)

    if (order.details?.deliveryStoreId) {
      const store: Store = yield select(
        makeSelectStoreById(order.details?.deliveryStoreId)
      )
      yield printer.printTextWithFont(`indirizzo: ${store.name}`, 'medium')
      yield printer.lineWrap(1)
      yield printer.printTextWithFont(`Tel: ${store.phone}`, 'medium')
      yield printer.lineWrap(1)
    }

    if (order.details?.deliveryAt) {
      yield printer.printTextWithFont(
        `nome cliente: ${order.details.deliveryAt}`,
        'medium'
      )
      yield printer.lineWrap(1)
    }
    if (order.details?.address) {
      yield printer.printTextWithFont(`indirizzo: ${order.details.address}`, 'medium')
      yield printer.lineWrap(1)
    }
    if (order.details?.phone) {
      yield printer.printTextWithFont(`Tel. ${order.details.phone}`, 'medium')
      yield printer.lineWrap(1)
    }
    if (order.details?.deliveryDate) {
      const date = new Date(order.details.deliveryDate).toLocaleDateString()
      yield printer.printTextWithFont(`Data consegna: ${date}`, 'medium')
      yield printer.lineWrap(1)
    }
    if (order.details?.deliveryTime) {
      yield printer.printTextWithFont(`Ore ${order.details.deliveryTime}`, 'medium')
      yield printer.lineWrap(1)
    }
    yield printer.lineWrap(1)
    yield printer.setAlignment('left')
    yield printer.printTextWithFont('prodotti:', 'medium')
    yield printer.lineWrap(2)
    yield all(
      products.map((p) => {
        const value = getConversionValue({
          counter: p.quantity,
          conversionValue: p.conversionValue,
          conversionUnit: p.conversionUnit
        })
        printer.printTextWithFont(`${p.title} ${value}`, 'medium')
        if (p.quantity !== p.initialQty) {
          const initialValue = getConversionValue({
            counter: p.initialQty,
            conversionValue: p.conversionValue,
            conversionUnit: p.conversionUnit
          })
          printer.lineWrap(1)
          printer.printTextWithFont(`(ORDINATO: ${initialValue})`, 'medium')
        }
        if (p.note) {
          printer.lineWrap(1)
          printer.printTextWithFont(`NOTA: ${p.note}`, 'medium')
        }
        printer.lineWrap(1)
        printer.printTextWithFont('-------------', 'medium')
        return printer.lineWrap(1)
      })
    )
    yield printer.lineWrap(1)
    if (order.details?.notes) {
      yield printer.printTextWithFont(`Note: ${order.details.notes}`, 'medium')
      yield printer.lineWrap(2)
    }
    yield printer.setAlignment('center')
    yield printer.printTextWithFont(
      `${order?.details?.payment ? 'Pagato' : 'Da pagare'}`,
      'medium'
    )
    yield printer.lineWrap(1)

    yield printer.printQRCode(`${order._id?.toString()}`, 8, 2)

    yield printer.printTextWithFont('---', 'medium')
    // @TODO update fontsize to 'small' with new printers
    yield printer.lineWrap(1)
    yield printer.printTextWithFont('a.dol.sar. S.r.l.', 'medium')
    yield printer.lineWrap(1)

    yield printer.lineWrap(4)
    yield printer.cut()
    yield printer.print()
  } catch (error) {
    console.log('🚀 ~ file: saga.ts:67 ~ function*printOrder ~ error:', error)
    yield put(updateNodeData(flowName, 'error', error))
  } finally {
    if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

export function* ordersSaga() {
  yield takeEvery(ordersActions.fetchOrders.toString(), fetchOrders)
  yield takeEvery(ordersActions.deleteOrderSaga.toString(), deleteOrderSaga)
  yield takeEvery(ordersActions.printOrder.toString(), printOrder)
  yield takeEvery(ordersActions.scanQrOrderSaga.toString(), scanQrOrderSaga)
  yield takeEvery(ordersActions.changeDeliveryDate.toString(), changeDeliveryDate)
  yield takeEvery(ordersActions.changeAddress.toString(), changeAddress)
}
