import { memo } from 'react'
import { StyledFormControl, StyledTextArea } from '../styled'

type Props = {
  id?: string
  value?: string
  title?: string
  rows?: string
  required?: boolean
  onChange: (val: string) => void
}

function TextArea({ id, onChange, title, rows, required }: Props) {
  return (
    <StyledFormControl>
      <StyledTextArea
        id={id}
        placeholder={title}
        aria-describedby={title}
        variant="outlined"
        name={id}
        onChange={(e: any) => onChange(e.target.value)}
        required={required}
        multiline
        rows={rows}
      />
    </StyledFormControl>
  )
}

export default memo(TextArea)
