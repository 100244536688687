import { IconButton, Stack } from '@mui/material'
import { memo, ReactNode, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ordersActions } from '../../features/orders/reducer'
import { OrderProduct } from '../../features/ordersProducts/model'
import { Product } from '../../features/products/model'
import { makeSelectProductById } from '../../features/products/selectors'
import { OrderDetail } from '../../pages/OrderDetail'
import CustomModal from '../CustomModal'
import { StatusBadge } from '../StatusBadge'
import { Text } from '../Text'
import { StyledInfoIcon } from './styled'

type Props = {
  productId?: string
  product: OrderProduct
  img?: boolean
  notes?: ReactNode
  enableInfoorder: boolean
}

const ProductThumb = ({ product, notes, enableInfoorder }: Props) => {
  const infoProduct: Product = useSelector(makeSelectProductById(product.productId))
  const dispatch = useDispatch()

  const handleOpen = useCallback(() => {
    dispatch(ordersActions.setSelectedOrderId({ id: product.orderId }))
  }, [dispatch, product.orderId])

  const handleClose = useCallback(() => {
    dispatch(ordersActions.setSelectedOrderId({ id: undefined }))
  }, [dispatch])

  return (
    <Stack direction="row" gap={1} paddingRight={'70px'} width={'150px'}>
      <Stack>
        <StatusBadge status={product.status} />
        {enableInfoorder ? (
          <>
            <CustomModal
              largeContainer
              onOpen={handleOpen}
              onClose={handleClose}
              openModal={
                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <Text text={infoProduct?.title || 'No title'} fontSize={14} />
                  <IconButton>
                    <StyledInfoIcon />
                  </IconButton>
                </Stack>
              }
              content={<OrderDetail />}
            />
            {notes}
          </>
        ) : (
          <>
            <Text text={infoProduct?.title || 'No title'} fontSize={14} />
            {notes}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default memo(ProductThumb)
