import { configureStore } from '@reduxjs/toolkit'
import { flowerEnhancer, combineReducersWithFlower } from '@stackhouseos/flower-client'
import { appReducer, appSlice } from './app/reducer'
import { appSaga } from './app/saga'
import { cartReducer, cartSlice } from './cart/reducer'
import { cartSaga } from './cart/saga'
import { categoriesReducer, categoriesSlice } from './categories/reducer'
import { categoriesSaga } from './categories/saga'
import { deliveryReducer, deliverySlice } from './delivery/reducer'
import { ordersReducer, ordersSlice } from './orders/reducer'
import { ordersSaga } from './orders/saga'
import { ordersProductsReducer, ordersProductsSlice } from './ordersProducts/reducer'
import { ordersProductsSaga } from './ordersProducts/saga'
import { productsReducer, productsSlice } from './products/reducer'
import { productSaga } from './products/saga'
import { storesReducer, storesSlice } from './stores/reducer'
import { storesSaga } from './stores/saga'

const rootSaga: any = [
  appSaga(),
  cartSaga(),
  ordersSaga(),
  productSaga(),
  ordersProductsSaga(),
  storesSaga(),
  categoriesSaga()
]

export const store = configureStore({
  enhancers: [flowerEnhancer(rootSaga)],
  reducer: combineReducersWithFlower({
    [ordersSlice.name]: ordersReducer,
    [productsSlice.name]: productsReducer,
    [ordersProductsSlice.name]: ordersProductsReducer,
    [storesSlice.name]: storesReducer,
    [cartSlice.name]: cartReducer,
    [appSlice.name]: appReducer,
    [deliverySlice.name]: deliveryReducer,
    [categoriesSlice.name]: categoriesReducer
  })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
