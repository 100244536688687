import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { Card, Container, InputLabel, Toolbar } from '@mui/material'
import { MuiFileInput } from 'mui-file-input'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import CustomModal from '../../components/CustomModal'
import Loader from '../../components/Loader'
import Stack from '../../components/Stack'
import { Text } from '../../components/Text'
import { appActions } from '../../features/app/reducer'
import { selectModalMessage } from '../../features/app/selectors'
import { productsActions } from '../../features/products/reducer'
import { deleteCategories, deleteProducts } from '../../utils'
import {
  JsonData,
  ProductDTO,
  convertExcelToJson,
  mapJsonDataToProductDto
} from '../../utils/convertExcelToJson'

// deleteCategories()
// deleteProducts()

function CSVImport() {
  const [file, setFile] = React.useState<File | null>(null)
  const dispatch = useDispatch()
  const modalMessage = useSelector(selectModalMessage)

  const handleFileChange = (file: File | null) => {
    setFile(file)
  }

  const handleSubmit = async () => {
    if (file) {
      try {
        const jsonData: JsonData[] = await convertExcelToJson(file)
        const newProducts: ProductDTO[] = mapJsonDataToProductDto(jsonData)
        dispatch(productsActions.addProductsRequested(newProducts))
      } catch (submissionError) {
        if (typeof submissionError === 'string' || Array.isArray(submissionError)) {
          dispatch(
            appActions.showModalMessage({ type: 'error', message: submissionError })
          )
        } else {
          console.error('submissionError', submissionError)
        }
      }
    }
  }

  return (
    <Container>
      <Toolbar variant="regular" />
      <Card sx={{ padding: '1rem' }}>
        <Stack gap={2}>
          <InputLabel htmlFor="fileUpload">Carica un file .csv</InputLabel>
          <Stack gap={2} flexDirection="row" alignItems="center">
            <MuiFileInput
              value={file}
              placeholder="Clicca per scegliere il file da caricare"
              onChange={handleFileChange}
            />
            <CustomModal
              onClose={() => dispatch(appActions.hideModalMessage())}
              openModal={
                <Button
                  borderRadius={16}
                  variant="outlined"
                  title="Invia"
                  disabled={!file}
                  onClick={handleSubmit}
                />
              }
            >
              <Toolbar variant="dense">
                {modalMessage?.type === 'error' ? (
                  <ErrorIcon color="error" />
                ) : modalMessage?.type === 'success' ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <Loader showLoader={!modalMessage} />
                )}
              </Toolbar>
              {modalMessage?.message && typeof modalMessage?.message === 'string' ? (
                <Text text={modalMessage?.message} />
              ) : Array.isArray(modalMessage?.message) ? (
                modalMessage?.message.map((message, index) => (
                  <Text text={message} key={index} />
                ))
              ) : null}
            </CustomModal>
          </Stack>
        </Stack>
      </Card>
    </Container>
  )
}

export default CSVImport
