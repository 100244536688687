import Flower from '@stackhouseos/flower-client'
import { Devtools } from '@stackhouseos/flower-client'
import { AsyncFlowCreate } from '../components/AsyncFlowCreate'
import { Button } from '../components/Button'
import CompleteFlow from '../components/CompleteFlow'
import CustomModal from '../components/CustomModal'
import { DeliveryInfo } from '../components/DeliveryInfo'
import EmptyState from '../components/EmptyState/EmptyState'
import FilterButtonsContainer from '../components/FilterButtonsContainer'
import { Flag } from '../components/Flag'
import Checkbox from '../components/Form/CheckBox/CheckBox'
import CustomDatePicker from '../components/Form/DatePicker'
import { DateCalendar } from '../components/Form/DatePicker/DateCalendar'
import { Input } from '../components/Form/Input/Input'
import RadioGroup from '../components/Form/Radio'
import Switch from '../components/Form/Switch/Switch'
import TextArea from '../components/Form/TextArea/TextArea'
import Grid from '../components/Grid'
import GroupOrdersContainer from '../components/GroupOrdersContainer'
import OrderList from '../components/GroupOrdersContainer/OrderList/OrderList'
import Icon from '../components/IconCmp'
import { ListRow } from '../components/ListRow/ListRow'
import OutletPage from '../components/Outlet/Outlet'
import { ParentGoToNext } from '../components/ParentGoToNext'
import { Portal } from '../components/Portal'
import ProcessingFlow from '../components/ProcessingFlow'
import ProductRow from '../components/ProductRowCmp'
import { QRScanner } from '../components/QRScanner'
import QuantityCmp from '../components/QuantityCmp'
import { Reset } from '../components/Reset'
import ResultState from '../components/ResultState/ResultState'
import { Saga } from '../components/Saga'
import Scroll from '../components/Scroll'
import SearchInput from '../components/SearchInput'
import Select from '../components/Select'
import Stack from '../components/Stack'
import StatusAction from '../components/StatusAction'
import { StatusBadge } from '../components/StatusBadge'
import StatusColumn from '../components/StatusColumn'
import StepperStatus from '../components/StepperStatusCmp'
import { Subflow } from '../components/Subflow'
import { Text } from '../components/Text'
import UpdateStatus from '../components/UpdateStatus'
import { Watch } from '../components/Watch'
import CSVImport from '../pages/CSVImport'
import DeliveryPage from '../pages/Delivery'
import Login from '../pages/Login/Login'
import LoginPage from '../pages/Login/Login'
import Navigation from '../pages/Navigation/Navigation'
import PdfPrinter from '../pages/OrderDetail/ExtendedPrinter/PdfPrinter'
import OrdersPage from '../pages/Orders/Orders'
import ProductionPage from '../pages/Production/Production'
import ProductionDetailPage from '../pages/ProductionDetail/ProductionDetail'
import ProductList from '../pages/Products/ProductList/ProductList'
import ShopPage from '../pages/Products/Products'

Flower.registerComponents({
  Button,
  Text,
  ProcessingFlow,
  UpdateStatus,
  Reset,
  CompleteFlow,
  Portal,
  OutletPage,
  Login,
  FilterButtonsContainer,
  ProductList,
  OrderList,
  Grid,
  Stack,
  SearchInput,
  Input,
  Switch,
  Checkbox,
  TextArea,
  Saga,
  Flag,
  CustomModal,
  ResultState,
  Scroll,
  GroupOrdersContainer,
  ProductRow,
  QuantityCmp,
  StatusBadge,
  StatusAction,
  StatusColumn,
  StepperStatus,
  RadioGroup,
  Select,
  Navigation,
  OrdersPage,
  DeliveryPage,
  EmptyState,
  LoginPage,
  ProductionPage,
  ShopPage,
  ProductionDetailPage,
  CustomDatePicker,
  QRScanner,
  Icon,
  AsyncFlowCreate,
  Subflow,
  ParentGoToNext,
  DeliveryInfo,
  Watch,
  ListRow,
  DateCalendar,
  CSVImport,
  PdfPrinter
})

Devtools({ port: 8770 })
