import { actionUpdate } from '@stackhouseos/flower-client'
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { cartActions } from './reducer'

function* resetCart({ payload }: any) {
  const { flowName } = payload || {}
  try {
    yield put(cartActions.resetCart())
  } catch (error) {
  } finally {
    if (flowName) yield put({ type: 'flower/next', payload: { name: flowName } })
  }
}

function* changeCartStoreId({ payload }: any) {
  const { value } = payload

  switch (value) {
    case 'atStoreId': {
      yield put(actionUpdate('cart', 'showDetails', false))
      yield put(actionUpdate('cart', 'details.address', undefined))
      return
    }

    case 'atCustomer': {
      yield put(actionUpdate('cart', 'showDetails', true))
      yield put(actionUpdate('cart', 'details.deliveryStoreId', undefined))
      return
    }

    default: {
      return
    }
  }
}

function* onShowDetails({ payload }: any) {
  const { value } = payload

  if (value) {
    yield put(actionUpdate('cart', 'details.payment', false))
    yield put(actionUpdate('cart', 'details.deliveryStoreId', undefined))
  } else {
    yield put(actionUpdate('cart', 'details.payment', true))
    yield put(actionUpdate('cart', 'details.deliveryAt', undefined))
    yield put(actionUpdate('cart', 'details.phone', undefined))
    yield put(actionUpdate('cart', 'details.email', undefined))
  }
}

function* onAddToCart() {
  const flowName = 'cartOrder'

  const flowerOrder: { history: string[] } = yield select(
    (state) => state.flower[flowName]
  )

  if (flowerOrder && flowerOrder.history.length > 2) {
    // restart flow cartOrder
    yield put({ type: 'flower/reset', payload: { flowName: flowName } })
  }
}

export function* cartSaga() {
  yield takeEvery('RESET_CART', resetCart)
  yield takeLatest(
    ({ type, payload }: any) =>
      type === '@flower/cart/update' && payload.id === 'deliveryMode',
    changeCartStoreId
  )
  yield takeLatest(
    ({ type, payload }: any) =>
      type === '@flower/cart/update' && payload.id === 'showDetails',
    onShowDetails
  )
  yield takeLatest(({ type }: { type: string }) => type === 'cart/addToCart', onAddToCart)
}
