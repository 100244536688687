import { Stack } from '@mui/material'
import Flower from '@stackhouseos/flower-client'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { FilterHeader } from '../../components/FilterHeader'
import { selectFormattedFilterDate } from '../../features/app/selectors'
import { ordersActions } from '../../features/orders/reducer'
import {
  makeSelectOrdersFilterOpts,
  selectFilter,
  selectInputOrders,
  selectOrderGroupedByDeliveryDate
} from '../../features/orders/selectors'
import { OrderDetail } from '../OrderDetail'
import PageContent from '../PageContent/PageContent'
import flow from './orders.flower.json'

function Orders() {
  const dispatch = useDispatch()
  const filterOpts = useSelector(makeSelectOrdersFilterOpts)
  const value = useSelector(selectFilter)
  const searchValue = useSelector(selectInputOrders)

  const handleChange = useCallback(
    (val: string) => dispatch(ordersActions.searchedOrders(val)),
    [dispatch]
  )

  return (
    <Stack
      gap={2}
      justifyContent="space-between"
      width={'100%'}
      height="100%"
      paddingBottom={35}
      paddingTop="20px"
    >
      <FilterHeader
        filterOpts={filterOpts}
        value={value}
        action={ordersActions.filterStatus}
        searchValue={searchValue}
        searchAction={handleChange}
        dateFilter
      />
      <PageContent
        leftCmp={
          <Flower
            name={flow.name}
            reducerName="orders"
            elements={flow.elements}
            selectors={{
              filterOpts: makeSelectOrdersFilterOpts,
              groupedOrders: selectOrderGroupedByDeliveryDate,
              filterDate: selectFormattedFilterDate
            }}
          />
        }
        rightCmp={<OrderDetail />}
      />
    </Stack>
  )
}

export default memo(Orders)
