import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from '../../style/theme'

export const StyledPage = styled('div')`
  height: 100%;
  display: flex;
`

export const StyledSidebar = styled(Grid)({
  backgroundColor: `${theme.background.light}`,
  display: 'flex',
  boxShadow: ' 0px 1px 25px rgba(0, 0, 0, 0.05)'
})

export const StyledPageContent = styled('div')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-height: 50%;
  padding: 0;
  padding-left: calc(35px + 60px);
  overflow: hidden;
  background: ${theme.background.lightGrey};
`
