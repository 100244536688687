import Flower from '@stackhouseos/flower-client'
import {
  selectCurrentOrderDate,
  selectCurrentOrderWithStore,
  selectDeliveryLabel,
  selectNewMinDeliveryDate,
  selectProductsCurrentOrder,
  selectRawDeliveryDate,
  selectTotal
} from '../../features/orders/selectors'
import flow from './orderDetail.flower.client.json'
import { StyledStackContent } from './styled'

export const OrderDetail = () => {
  return (
    <StyledStackContent>
      <Flower
        name={flow.name}
        reducerName="orders"
        elements={flow.elements}
        selectors={{
          selectedOrder: selectCurrentOrderWithStore,
          orderProducts: selectProductsCurrentOrder,
          deliveryDate: selectCurrentOrderDate,
          minDeliveryDate: selectNewMinDeliveryDate,
          rawDeliveryDate: selectRawDeliveryDate,
          filterDeliveryDate: selectDeliveryLabel,
          total: selectTotal
        }}
      />
    </StyledStackContent>
  )
}
