import React from 'react'

export { default as Orders } from './Orders/Orders'

export const OnBoarding = React.lazy(() => import('./OnBoarding/OnBoarding'))

export const Page404 = React.lazy(() => import('./Page404/Page404'))

export const Cart = React.lazy(() => import('./Cart'))

export const Production = React.lazy(() => import('./Production/Production'))

export const Products = React.lazy(() => import('./Products/Products'))

export const Delivery = React.lazy(() => import('./Delivery'))

export const Login = React.lazy(() => import('./Login/Login'))

export const CSVImport = React.lazy(() => import('./CSVImport'))
