import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary']
    selected: React.CSSProperties['color']
    lightGrey: React.CSSProperties['color']
    mediumGrey: React.CSSProperties['color']
    danger: React.CSSProperties['color']
    turquoise: React.CSSProperties['color']
    icon: React.CSSProperties['color']
    white: React.CSSProperties['color']
  }

  interface PaletteOptions {
    black: PaletteOptions['primary']
    selected: React.CSSProperties['color']
    lightGrey: React.CSSProperties['color']
    mediumGrey: React.CSSProperties['color']
    danger: React.CSSProperties['color']
    turquoise: React.CSSProperties['color']
    white: React.CSSProperties['color']
    icon: React.CSSProperties['color']
  }

  type StatusType = {
    new: React.CSSProperties['color']
    pending: React.CSSProperties['color']
    ready: React.CSSProperties['color']
    processing: React.CSSProperties['color']
    toDeliver: React.CSSProperties['color']
    delivered: React.CSSProperties['color']
    completed?: React.CSSProperties['color']
    deleted: React.CSSProperties['color']
  }
  interface Theme {
    status: StatusType
    actions: {
      add: React.CSSProperties['color']
      remove: React.CSSProperties['color']
    }
    background: {
      light: React.CSSProperties['color']
      dark: React.CSSProperties['color']
      lightGrey: React.CSSProperties['color']
      grayContrast: React.CSSProperties['color']
    }
    productsCategories: {
      green: React.CSSProperties['color']
      turquoise: React.CSSProperties['color']
      purple: React.CSSProperties['color']
      orange: React.CSSProperties['color']
      red: React.CSSProperties['color']
      blue: React.CSSProperties['color']
    }
    container: {
      width: React.CSSProperties['maxWidth']
      paddingTop: React.CSSProperties['paddingTop']
    }
    icon: React.CSSProperties['color']
    turquoise: React.CSSProperties['color']
    white: React.CSSProperties['color']
  }
  interface ThemeOptions {
    status: StatusType
    background: {
      light: React.CSSProperties['color']
      dark: React.CSSProperties['color']
      lightGrey: React.CSSProperties['color']
      grayContrast: React.CSSProperties['color']
    }
    actions: {
      add: React.CSSProperties['color']
      remove: React.CSSProperties['color']
    }
    productsCategories: {
      green: React.CSSProperties['color']
      turquoise: React.CSSProperties['color']
      purple: React.CSSProperties['color']
      orange: React.CSSProperties['color']
      red: React.CSSProperties['color']
      blue: React.CSSProperties['color']
    }
    container: {
      width: React.CSSProperties['maxWidth']
      paddingTop: React.CSSProperties['paddingTop']
    }
  }
}

export const theme = createTheme({
  components: {
    // @ts-ignore
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#424242',
            color: '#ffffff'
          },
          '&:focus.Mui-selected': {
            backgroundColor: '#424242',
            color: '#ffffff'
          }
        }
      }
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: ['#a9a9a9']
        },
        colorSecondary: {
          color: ['#656565']
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '15px'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#BB8E4B'
    },
    secondary: {
      main: '#A56C18'
    },
    success: {
      main: '#A8CE16'
    },
    black: {
      main: '#424242'
    },
    selected: '#96bb63',
    lightGrey: '#cccccc',
    mediumGrey: '#a9a9a9',
    danger: '#EE5959',
    turquoise: '#63BBBB',
    white: '#FFFBF4',
    icon: '#F18162'
  },
  background: {
    light: '#FCFCFC',
    dark: '#424242',
    lightGrey: '#FEFEFE',
    grayContrast: '#F6F6F6'
  },
  status: {
    new: '#EE5959',
    pending: '#EEA159',
    processing: '#63BBBB',
    ready: '#4E5FFF',
    toDeliver: '#bf8e51',
    delivered: '#9353E7',
    completed: '#96BB63',
    deleted: '#a9a9a9'
  },
  actions: {
    add: '#FCFCFC',
    remove: '#424242'
  },
  productsCategories: {
    green: '#96BB63',
    turquoise: '#63BBBB',
    purple: '#8280EB',
    orange: '#EEA159',
    red: '#EE5959',
    blue: '#4E5FFF'
  },
  container: {
    width: 1000,
    paddingTop: '45px'
  }
})

export type AppTheme = typeof theme

export type ThemeColor = keyof AppTheme['palette']

export type ThemeStatus = keyof AppTheme['status']
export type ThemeBackground = keyof AppTheme['background']
