import _ from 'lodash'
import * as XLSX from 'xlsx'
import { Product } from '../features/products/model'

export type JsonData = {
  title: string
  description: string
  category: string
  img: string
  sku: string
  weight: number
  conversionUnit: string
  conversionValue: number
  price: number
  noFood: string
}

const headers = [
  'title',
  'description',
  'category',
  'img',
  'sku',
  'weight',
  'conversionUnit',
  'conversionValue',
  'price',
  'noFood'
]

export type ProductDTO = Omit<Product, '_id' | 'quantity' | 'time'>

type InvalidRows = {
  invalidTitles: number[]
  invalidCategories: number[]
  invalidSKUs: number[]
}

export function convertExcelToJson(file: File | null): Promise<JsonData[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target) {
        const data = event.target.result
        const workbook = XLSX.read(data, { type: 'binary' })
        const sheet = workbook.Sheets[workbook.SheetNames[1]]
        const json: JsonData[] = XLSX.utils.sheet_to_json<JsonData>(sheet, {
          header: headers,
          raw: true
        })

        if (!json.length) {
          return reject('Nessun prodotto trovato')
        }

        const headersRow = Object.values(json[0]).some((value) => {
          const cleanValue = value.toString().replace(/['"]/g, '')
          return headers.includes(cleanValue)
        })
        if (headersRow) json.shift()

        const invalidRowsCounter = _.pickBy(
          json.reduce(
            (acc, product, index) => {
              if (!product.title) {
                acc.invalidTitles = [...acc.invalidTitles, index + 1]
              }
              if (!product.category) {
                acc.invalidCategories = [...acc.invalidCategories, index + 1]
              }
              if (!product.sku) {
                acc.invalidSKUs = [...acc.invalidSKUs, index + 1]
              }
              return acc
            },
            {
              invalidTitles: [],
              invalidCategories: [],
              invalidSKUs: []
            } as InvalidRows
          ),
          (rowCount) => {
            return rowCount.length > 0
          }
        )

        if (!_.isEmpty(invalidRowsCounter)) {
          const invalidRows = Object.entries(invalidRowsCounter).reduce(
            (acc, [missingProperty, rowCount]) => {
              const missingElement =
                missingProperty === 'invalidTitles'
                  ? 'Titolo'
                  : missingProperty === 'invalidCategories'
                  ? 'Categoria'
                  : missingProperty === 'invalidSKUs'
                  ? 'SKU'
                  : 'Proprietà'
              const maxRows = 5
              const rowNumbers: string =
                rowCount.length > maxRows
                  ? rowCount
                      .slice(0, maxRows)
                      .map((rowNumber) => rowNumber.toString())
                      .concat(['...'])
                      .join(', ')
                  : rowCount.join(', ')

              const error = `${missingElement} mancante a riga: ${rowNumbers}`
              return [...acc, error]
            },
            [] as string[]
          )
          return reject(invalidRows)
        }

        resolve(json)
      }
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsBinaryString(file as Blob)
  })
}

export const mapJsonDataToProductDto = (jsonData: JsonData[]) =>
  jsonData.map((product) => {
    const newProduct: ProductDTO = {
      title: product.title,
      category: [product.category],
      img: product.img,
      sku: product.sku,
      weight: product.weight,
      conversionUnit: product.conversionUnit,
      conversionValue: product.conversionValue,
      price: product.price,
      noFood: !!product.noFood
    }
    return newProduct
  })
