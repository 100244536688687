import styled from '@emotion/styled'
import { DatePicker } from '@mui/x-date-pickers'

type Props = {
  isFilter?: boolean
}

export const StyledDatePicker = styled(DatePicker, {
  shouldForwardProp: (prop) => prop !== 'isFilter'
})<Props>`
  border: none;
  margin: ${({ isFilter }) => (isFilter ? '0' : '20px 0 5px')};
  min-width: ${({ isFilter }) => (isFilter ? '130px' : '100%')};
  width: auto;
  ${({ isFilter }) => isFilter && 'margin-top: -14px;'};
`
