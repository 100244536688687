import Flower from '@stackhouseos/flower-client'
import { get, keyBy } from 'lodash'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { selectPendingFlow } from '../../features/app/selectors'

const contextClient = require.context('../../', true, /[\s\S]*?.flower.client.json$/)
const context = require.context('../../', true, /[\s\S]*?.flower.json$/)
const flowers = context
  .keys()
  .map((filename) => context(filename))
  .filter((e) => e.type === 'flow')

const flowersClient = contextClient
  .keys()
  .map((filename) => contextClient(filename))
  .filter((e) => e.type === 'flow')

const flowIds = keyBy([...flowers, ...flowersClient], 'name')

function AsyncFlow() {
  const list = useSelector(selectPendingFlow)
  const element = get(list, '0', {})
  const { flow, id, state } = element
  if (!flowIds[flow]) return

  return (
    <div style={{ position: 'fixed', bottom: -30, right: -30, display: 'none' }}>
      <Flower
        key={id}
        destroyOnUnmount
        source={flow}
        elements={flowIds[flow].elements}
        name={id}
        extraContext={{ value: { ...state, flow } }}
      />
    </div>
  )
}

export default memo(AsyncFlow)
