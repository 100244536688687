import styled from '@emotion/styled'
import { Switch } from '@mui/material'
import { theme } from '../../style/theme'

export const StyledSwitch = styled(Switch)({
  width: 74,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(48px)',
      color: '#fff',
      '& + .MuiSwitch-track:before': {
        transform: 'translateX(-20px)',
        color: theme.background.lightGrey
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.danger,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    color: theme.palette.mediumGrey,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    }),
    '&:before': {
      content: `${"'OGGI'"}`,
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 28,
      top: 2
    }
  }
})
