import { OrderStatus } from '../../features/orders/model'
import { statusTranslate } from '../../features/orders/selectors'
import { Status } from '../../features/products/model'
import { ThemeStatus } from '../../style/theme'
import { StyledBadge, StyledBox, StyledTypography } from './styled'

type Props = {
  status: Status | OrderStatus
  color?: ThemeStatus
  uppercase?: boolean
}

export const BadgeText = ({ status, uppercase, color = 'new' }: Props) => {
  return (
    <StyledBox>
      <StyledBadge variant="dot" badgeColor={color} />
      <StyledTypography
        variant="body1"
        fontSize={14}
        textColor={color}
        uppercase={uppercase}
      >
        {statusTranslate[status]}
      </StyledTypography>
    </StyledBox>
  )
}
