import { styled } from '@mui/material/styles'
import { Status } from '../../features/orders/model'
import { theme } from '../../style/theme'

type Props = {
  backgroundColor?: string
  status?: Status
  back?: boolean
  disabled?: boolean
}

export const StyledStatusColumn = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})(({ status }: Props) => ({
  background: '',
  position: 'relative',
  top: '0',
  minHeight: '60%',
  display: 'flex',
  minWidth: '20%',
  width: '33%',
  height: '100%%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  zIndex: '99',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  backgroundColor: status ? `${theme.status[status]}26` : 'none',

  '::-webkit-scrollbar': {
    display: 'none'
  }
}))
