import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

type Props = {
  flowName: string
  sagaName: string
  parentFlow?: string
  reducer: string
  redurceName: string
  selector: string
  enableLoader: boolean
}

export const Saga = React.memo(
  ({
    flowName,
    enableLoader = true,
    sagaName,
    reducer,
    redurceName,
    selector
  }: Props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const one = useRef<boolean>(false)

    useEffect(() => {
      if (sagaName && !one.current) {
        dispatch({
          type: sagaName,
          payload: { flowName, reducer, redurceName, selector, params }
        })
        one.current = true
      }
    }, [dispatch, flowName, sagaName, reducer, redurceName, selector, params, one])

    return enableLoader ? <CircularProgress size={24} /> : <></>
  }
)
