import styled from '@emotion/styled'
import { Stack, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'
import TableCell from '@mui/material/TableCell'
import { ThemeBackground, ThemeColor, theme } from '../../../style/theme'

type Props = {
  bold?: string
  color?: ThemeColor
  bgColor?: ThemeBackground
}

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})(({ bold = 'normal', color = 'primary', bgColor = 'light' }: Props) => ({
  fontWeight: `${bold}`,
  fontSize: '16px',
  color: `${theme.palette[color]}`,
  backgroundColor: `${theme.background[bgColor]}`,
  maxWidth: '100px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: '2vh 1vw'
}))

export const StyledTableCellBig = styled(StyledTableCell)`
  font-size: 22px;
  cursor: pointer;
`

export const StyledSpan = styled('span', {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})(({ color = 'primary' }: Props) => ({
  color: `${theme.palette[color]}`
}))

export const StyledPaper = styled(Paper)`
  height: 100%;
`

export const StyledTableCellAction = styled(StyledTableCell)`
  cursor: pointer;
  svg {
    fill: inherit;
  }
`

export const StackDrower = styled(Stack)`
  overflow: hidden;
`
export const StyledTableRow = styled(TableRow)({
  th: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  }
})
