import { Grid, Stack } from '@mui/material'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { cartActions } from '../../features/cart/reducer'
import { Product } from '../../features/products/model'
import { theme } from '../../style/theme'
import Badge from '../Badge'
import CustomModal from '../CustomModal'
import { Text } from '../Text'
import {
  StyledCard,
  StyledImage,
  StyledIcon,
  StyledStack,
  StyledButton,
  StyledTitle,
  StyledTitleContainer
} from './styled'

type Props = {
  item: Product
}

export const ProductCard = ({ item }: Props) => {
  const [productNote, setProductNote] = useState('')

  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(
      cartActions.addToCart({
        productId: item._id,
        quantity: 1,
        note: productNote,
        processingTime: item.time
      })
    )
    setProductNote('')
  }, [dispatch, item._id, item.time, productNote])

  return (
    <Grid item>
      <StyledCard bgColor={`${theme.background.light}`}>
        {item.time && <Badge timeBadge text={item.time} />}
        <StyledImage
          imgHeight={130}
          imgWidth={160}
          product={item}
          onClick={handleClick}
        />
        <StyledTitleContainer>
          <StyledTitle className="item-title" text={item.title} />
        </StyledTitleContainer>
        <Stack
          flexDirection="row"
          gap={1}
          justifyContent="center"
          border={'1px solid'}
          borderRadius={20}
          padding={'0 2px'}
        >
          <StyledStack
            onClick={handleClick}
            aria-label="add"
            flexDirection={'row'}
            alignItems={'center'}
          >
            <StyledIcon />
            <Text text="AGGIUNGI" fontSize={14} textColor={theme.palette.black.main} />
          </StyledStack>
          <CustomModal
            hasAction
            width={'30%'}
            subtitle={`inserisci una nota al prodotto "${item.title}"`}
            hasInputField
            onClick={() => {
              handleClick()
            }}
            onChange={setProductNote}
            openModal={
              <StyledButton variant="text" size="small">
                <Text text="Note" fontSize={14} textColor={theme.palette.black.main} />
              </StyledButton>
            }
          />
        </Stack>
      </StyledCard>
    </Grid>
  )
}
