import React from 'react'
import QRcode from 'react-qr-code'
import Stack from '../../../components/Stack'
import { Text } from '../../../components/Text'
import { Order } from '../../../features/orders/model'
import { statusTranslate } from '../../../features/orders/selectors'
import { OrderProductExtendend } from '../../../features/ordersProducts/model'
import { getConversionValue } from '../../../features/ordersProducts/selectors'
import { Store } from '../../../features/stores/model'

export class OrderToPrint extends React.Component<{
  order?: Order
  products?: OrderProductExtendend[]
  store?: Store
}> {
  render() {
    const { order, products, store } = this.props
    const payment = order?.details?.payment ? 'Pagato' : 'Da pagare'
    const status = statusTranslate[order!.status]
    const deliveryDate = new Date(order!.details!.deliveryDate).toLocaleDateString()

    return (
      <>
        {order && (
          <div style={{ margin: '40px' }}>
            <Text
              text={`Ordine n. ${order?.orderNumber}`}
              variant="h4"
              textAligned="center"
            />
            <Stack gap={6} marginTop="40px">
              <Stack gap={1}>
                <DetailRow detail="Ordine in stato:" value={status} />
                {store && <Address address={store.name} phone={store.phone} />}
                {order?.details?.deliveryAt && (
                  <>
                    <DetailRow
                      detail="nome cliente:"
                      value={order?.details?.deliveryAt}
                    />
                    <Address
                      address={order?.details?.address}
                      phone={order?.details?.phone}
                    />
                  </>
                )}
                <DetailRow detail="Data di consegna:" value={deliveryDate} />
                <DetailRow detail="Ore" value={order.details?.deliveryTime} />
              </Stack>
              {products && (
                <Stack gap={1}>
                  {products.map((p) => (
                    <ProductRow product={p} key={p._id} />
                  ))}
                </Stack>
              )}
              <Stack gap={1}>
                <Text text="Note:" bold />
                {order.details?.notes && (
                  <Text text={order.details.notes} whitespace="pre" />
                )}
              </Stack>
              <Text text={payment} />
              <QRcode size={100} value={order._id!} style={{ alignSelf: 'center' }} />
              <Text text={'a.dol.sar. S.r.l.'} />
            </Stack>
          </div>
        )}
      </>
    )
  }
}

const DetailRow = ({ detail, value }: any) => {
  return (
    <Stack flexDirection="row" gap={2}>
      <Text text={detail} />
      <Text bold text={value} whitespace="pre" />
    </Stack>
  )
}

const Address = ({ address, phone }: any) => {
  return (
    <>
      <DetailRow detail="indirizzo:" value={address} />
      <DetailRow detail="Tel:" value={phone} />
    </>
  )
}

const ProductRow = ({ product }: any) => {
  const showInitialQuantity = product.quantity !== product.initialQty
  const value = getConversionValue({
    counter: product.quantity,
    conversionValue: product.conversionValue,
    conversionUnit: product.conversionUnit
  })
  const initialValue = getConversionValue({
    counter: product.initialQty,
    conversionValue: product.conversionValue,
    conversionUnit: product.conversionUnit
  })
  return (
    <Stack>
      <Stack flexDirection="row" gap={2}>
        <Text text={`${product.title}`} />
        <Text text={value} />
      </Stack>
      {showInitialQuantity && <Text text={`Ordinato: ${initialValue}`} />}
      {product.note && <DetailRow detail="Nota:" value={product.note} />}
    </Stack>
  )
}
