import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

type Props = {
  bold?: boolean
  textColor?: string
  fontSize?: number
  textAligned?: 'right' | 'left' | 'center' | 'inherit'
  wordwrap?: string
  textOverflow?: string
  whitespace?: string
  overflow?: string
  width?: string
  overflowwrap?: string
  uppercase?: boolean
  wordBreak?: string
}

export const StyledText = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'bold' &&
    prop !== 'textColor' &&
    prop !== 'fontSize' &&
    prop !== 'textAligned' &&
    prop !== 'uppercase' &&
    prop !== 'textOverflow' &&
    prop !== 'wordBreak'
})<Props>`
  color: ${({ textColor }) => textColor && textColor};
  font-weight: ${({ bold }) => bold && 'bold'};
  font-size: ${({ fontSize }) => fontSize && fontSize + 'px'};
  text-align: ${({ textAligned }) => textAligned && textAligned};
  font-family: 'Lato';
  white-space: ${({ whitespace }) => whitespace && whitespace};
  overflow: hidden;
  text-overflow: ${({ textOverflow }) => textOverflow && textOverflow};
  overflow-wrap: ${({ overflowwrap }) => overflowwrap && overflowwrap};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
  word-break: ${({ wordBreak }) => wordBreak && wordBreak};
`
