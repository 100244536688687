import { createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { isInteger, keyBy } from 'lodash'
import _countBy from 'lodash/countBy'
import _flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
import { getFormattedDate } from '../../utils'
import { Status, StatusCode } from '../orders/model'
import { Product } from '../products/model'
import {
  selectCategories,
  selectProducts,
  selectProductsIds
} from '../products/selectors'
import type { RootState } from '../store'
import {
  OrderProduct,
  OrdersProductsSlice,
  OrderProductDetail,
  OrderProductExtendend
} from './model'

export const getConversionValue = ({
  counter,
  conversionValue = 1,
  conversionUnit = ''
}: {
  counter: number
  conversionValue: number
  conversionUnit: string
}) => {
  const value = isInteger(counter * conversionValue)
    ? counter * conversionValue
    : (counter * conversionValue).toFixed(2)
  return value + ' ' + getConversionUnit(conversionUnit, +value)
}
export const getConversionUnit = (conversionUnit: string, qty: number) => {
  switch (conversionUnit) {
    case 'pezzo':
      return qty > 1 ? 'pezzi' : 'pezzo'
    case 'kg':
      return qty > 1 ? 'kgs' : 'kg'
    default:
      return qty > 1 ? 'pezzi' : 'pezzo'
  }
}
export const selectFilterOrdersProduct = (state: RootState) => state.ordersProducts
export const selectFilters = (state: RootState) => state.ordersProducts.filters
export const selectFilterDate = (state: RootState) => state.app.filterDate

export const selectOrdersProducts = createSelector(
  selectFilterOrdersProduct,
  (ordersProducts) => ordersProducts.items
)
export const selectOrdersProductsIds = createSelector(selectOrdersProducts, (products) =>
  keyBy(products, '_id')
)

export const makeSelectOrdersProductById = (id: string) =>
  createSelector(selectOrdersProductsIds, (products) => products[id])

export const selectFormattedFilterDate = createSelector(selectFilterDate, (date) =>
  date ? getFormattedDate(date) : date
)

export const selectExtendedOrdersProducts = createSelector(
  selectOrdersProducts,
  selectProductsIds,
  (ordersProducts, productsByIds) => {
    return ordersProducts.map((o) => {
      const prod: Product = get(productsByIds, o.productId.toString())
      return {
        ...o,
        category: prod?.category || undefined,
        title: prod?.title.toLowerCase(),
        sku: prod?.sku,
        img: prod?.img,
        conversionValue: prod?.conversionValue,
        weight: prod?.weight,
        conversionUnit: prod?.conversionUnit,
        price: prod?.price
      } as OrderProductExtendend
    })
  }
)

export const filteredProductsByStatus = (status: Status) =>
  createSelector(selectOrdersProducts, (products: OrderProduct[]) =>
    products.filter((product: OrderProduct) => product.status === status)
  )

export const selectSearchValue = createSelector(
  selectFilterOrdersProduct,
  (products) => products.search
)

export const selectOrderProductsFilterOpts = createSelector(
  selectFilterOrdersProduct,
  selectProducts,
  selectCategories,
  (orderProducts, products, categories) => {
    const newOrderProducts = orderProducts.items.map((op) => {
      const prod = products.find((p) => p._id.toString() === op.productId.toString())
      return { ...op, category: prod?.category, sku: prod?.sku }
    })
    const opt = _uniq(_flatMap(newOrderProducts, 'category')).filter(Boolean)
    return opt.map((o) => ({ value: o, title: categories[o]?.name }))
  }
)

export const selectOrderProductsByCategory = createSelector(
  selectExtendedOrdersProducts,
  selectFilters,
  (products, { category }) => {
    if (!category) return products
    return products.filter((i) =>
      i?.category && category ? i.category.includes(category) : true
    )
  }
)

export const selectOrderProductsByDate = createSelector(
  selectOrderProductsByCategory,
  selectFilterDate,
  (products, deliveryDate) => {
    return deliveryDate
      ? products.filter((p) => p.deliveryDate === deliveryDate)
      : products
  }
)

export const selectFilteredOrderProducts = createSelector(
  selectOrderProductsByDate,
  selectSearchValue,
  (products, search) => {
    const availableStatus = ['pending', 'processing', 'ready']
    const productionProducts = products.filter((p) =>
      p?.status ? availableStatus.some((s) => s === p.status) : null
    )
    const orderedProduct = productionProducts.sort((a, b) => {
      const dateA = dayjs(a.updateAt).toDate() || new Date()
      const dateB = dayjs(b.updateAt).toDate() || new Date()
      return dateA.getTime() - dateB.getTime()
    })
    return search
      ? orderedProduct.filter((p) => p.title?.includes(search.toLowerCase()))
      : orderedProduct
  }
)

export const makeSelectOrdersProductsByCategoryAndStatus = (status: Status) =>
  createSelector(selectFilteredOrderProducts, (products) => {
    const productsWithId = products.map((p) => ({
      ...p,
      productId: p.productId.toString()
    }))
    const productsByState = productsWithId.filter((product) => product.status === status)
    return _uniqBy(productsByState, 'productId').map((u) => {
      const counterInstance = _countBy(productsByState, 'productId')
      const tot = counterInstance[u.productId] * u.quantity
      return { ...u, tot }
    })
  })

export const makeSelectSingleOrdersProductsByStatus = (status: Status) =>
  createSelector(selectFilteredOrderProducts, (products) => {
    return products
      .filter((product) => product.status === status)
      .sort((a, b) => {
        const dateA = dayjs(a.updateAt).toDate() || new Date()
        const dateB = dayjs(b.updateAt).toDate() || new Date()
        return dateA.getTime() - dateB.getTime()
      })
  })

export const makeStatusColumnPending = createSelector(
  selectFilteredOrderProducts,
  (products) => {
    return products.filter((product) => product.status === StatusCode.pending)
  }
)

export const makeStatusColumnProcessing = createSelector(
  selectFilteredOrderProducts,
  (products) => {
    return products.filter((product) => product.status === StatusCode.processing)
  }
)

export const makeStatusColumnReady = createSelector(
  selectFilteredOrderProducts,
  (products) => {
    return products.filter((product) => product.status === StatusCode.ready)
  }
)

export const makeProductionProducts = createSelector(
  makeStatusColumnPending,
  makeStatusColumnProcessing,
  makeStatusColumnReady,
  (pending, processing, ready) => [...pending, ...processing, ...ready]
)

export const makeStatusProduction = createSelector(
  makeStatusColumnReady,
  makeStatusColumnProcessing,
  makeStatusColumnPending,
  (ready, processing, pending) => {
    const result = [...ready, ...processing, ...pending].map((a) => ({
      ...a,
      productId: a.productId.toString()
    }))
    return result
  }
)

const productsByProductId = createSelector(makeStatusProduction, (production) => {
  const ids = _groupBy(production, (rec) => rec.productId + '__' + (rec.note || '')) // <Record<string, Product[]>>
  return Object.entries(ids)
})

const productsByStatus = createSelector(
  (state: OrdersProductsSlice) => state.items,
  (state: any) => state.inc,
  (items, status) => {
    return items.filter((r) => r.status === status)
  }
)

const sum = (acc2: number, inc2: OrderProduct) => acc2 + inc2.quantity
const status = ['pending', 'processing', 'ready']

export const makeDetailStatusProduction = createSelector(
  productsByProductId,
  (productsGrouped) => {
    const sumProductByStatus = (items: OrderProductExtendend[]) => {
      const total = items.reduce(sum, 0)
      const remains = productsByStatus({ items, inc: 'ready' }).reduce(sum, 0)
      const [firstItem] = items || []
      const { conversionUnit = '', conversionValue = 1 } = firstItem || {}

      const totalStatus = status.reduce((acc, inc) => {
        const itemsByStatus: OrderProductExtendend[] = items.filter(
          (r) => r.status === inc
        )

        return {
          ...acc,
          [inc + '_items']: itemsByStatus,
          [inc]: getConversionValue({
            counter: itemsByStatus.reduce(sum, 0),
            conversionValue,
            conversionUnit
          })
        }
      }, {})

      return {
        total: getConversionValue({
          counter: total,
          conversionValue,
          conversionUnit
        }),
        remains:
          total - remains > 0
            ? getConversionValue({
                counter: total - remains,
                conversionValue,
                conversionUnit
              })
            : 0,
        ...totalStatus
      }
    }

    const generateProductStatus = (products: [string, any[]][]) =>
      products.map(([prodId, items]) => {
        const result: OrderProductDetail = {
          ...items[0],
          productId: prodId,
          ...sumProductByStatus(items)
        }
        return result
      })

    const results = generateProductStatus(productsGrouped).sort(
      (a, b) => Number(b.sku) - Number(a.sku)
    )

    return results
  }
)

export const selectedCurrentOrderProducts = createSelector(
  selectFilterOrdersProduct,
  ({ items, selected }) => items.find((i) => i._id === selected?._id)
)
