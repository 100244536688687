import { ArrowRightAlt } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { Stack } from '@mui/system'
import { StatusCode } from '../../features/orders/model'
import { theme } from '../../style/theme'

type Props = {
  status?: StatusCode
  back?: boolean
  disabled?: boolean
  single?: boolean
}

export const StyledArrowList = styled(Stack)`
  width: 60px;
`

export const StyledArrowContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'disabled' && prop !== 'back' && prop !== 'status' && prop !== 'single'
})(({ single, back, status, disabled }: Props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: single ? '0 15px 15px 0' : back ? '0 15px 0 0' : '0 0 15px 0',
  backgroundColor:
    !back || single
      ? `${theme.status[status || 'pending']}`
      : `${theme.status[status || 'pending']}1A`,
  borderLeft: `1px solid ${theme.status[status || 'pending']}80`,
  height: single ? '100%' : '50%',
  width: '100%',
  cursor: !disabled ? 'pointer' : ''
}))

export const StyledArrow = styled(ArrowRightAlt, {
  shouldForwardProp: (prop) => prop !== 'back' && prop !== 'status' && prop !== 'single'
})(({ back, status, single }: Props) => ({
  fill: back && !single ? theme.status[status || 'pending'] : 'white',
  transform: back ? 'rotate(180deg)' : 'none',
  fontSize: '30px',
  width: '35px',
  alignItems: 'center'
}))
