import { Modal } from '@mui/material'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActionsModal, OpenModal, TextModal } from './Modal'
import { StyledModalContent } from './styled'

type Props = {
  actionType?: string
  icon?: string
  flowName?: string
  stepId?: string
  title?: string
  subtitle?: string
  hasInputField?: boolean
  onChange?: (val: string) => void
  onClick?: () => void
  width?: string
  openModal?: ReactNode
  placeholder?: string
  hasAction?: boolean
  initialValue?: any
  type?: any
  show?: boolean
  content?: any
  onOpen?: any
  onClose?: () => void
  largeContainer?: boolean
  children?: ReactNode
}

const CustomModal = ({
  actionType,
  icon,
  flowName,
  stepId,
  title,
  subtitle,
  hasInputField,
  onChange,
  onClick,
  width,
  openModal,
  placeholder,
  hasAction,
  initialValue,
  type,
  content,
  show,
  onOpen,
  onClose,
  largeContainer,
  children
}: Props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    onOpen && onOpen()
    setOpen(true)
  }, [onOpen])

  const handleClose = useCallback(() => {
    onChange && onChange(initialValue)
    onClose && onClose()
    setOpen(false)
  }, [setOpen, onChange, initialValue, onClose])

  const handleConfirm = useCallback(() => {
    onClick
      ? onClick()
      : dispatch({ type: actionType, payload: { flowName, node: stepId } })
    setOpen(false)
  }, [actionType, dispatch, flowName, stepId, onClick])

  useEffect(() => {
    if (open === false && show) setOpen(show)
  }, [open, show])

  return (
    <>
      <OpenModal
        handleOpen={handleOpen}
        openModal={openModal}
        width={width}
        title={title}
        icon={icon}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <StyledModalContent gap={2} largeContainer={largeContainer}>
          {children}
          <TextModal
            type={type}
            hasAction={hasAction}
            subtitle={subtitle}
            hasInputField={hasInputField}
            onChange={onChange}
            placeholder={placeholder}
            content={content}
          />
          <ActionsModal
            hasAction={hasAction}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        </StyledModalContent>
      </Modal>
    </>
  )
}

export default CustomModal
