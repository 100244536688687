import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Stack } from '@mui/material'
import { Text } from '../Text'

type Props = {
  checked?: boolean
  text?: string
}

export const Flag = ({ checked, text }: Props) => {
  const icon = checked ? (
    <CheckIcon color="success" />
  ) : (
    <ErrorOutlineIcon color="warning" />
  )

  return (
    <Stack direction="row" gap={2}>
      {text && <Text text={text} bold />}
      {icon}
    </Stack>
  )
}
