import { Stack } from '@mui/system'
import { memo, PropsWithChildren, ReactNode } from 'react'
import { OrderProductExtendend } from '../../features/ordersProducts/model'
import { Status } from '../../features/products/model'
import Badge from '../Badge'
import ProductImg from '../ProductImg'
import { ModalNote } from './Modal/ModalNote'
import ModalQty from './Modal/ModalQty'
import ProductThumb from './ProductThumb'
import { StyledRow } from './styled'

type Props = PropsWithChildren<{
  value: OrderProductExtendend
  quantity?: number
  productCounter?: ReactNode
  status?: Omit<Status, 'completed'>
  imgWidth?: number
  imgHeight?: number
  alignItems?: string
  enableChangeQuantity?: boolean
  enableInfoorder?: boolean
  overcomeInitialQuantity?: boolean
}>

const ProductRow = ({
  value,
  children,
  alignItems,
  enableChangeQuantity = false,
  enableInfoorder = false,
  overcomeInitialQuantity = false
}: Props) => {
  return (
    <StyledRow direction="row" gap={2} borderColor={value?.status}>
      <Stack position={'relative'} maxWidth={'50%'} paddingY={'5px'}>
        {value.processingTime && (
          <Stack left={'20px'} top={'-10px'} position={'relative'}>
            <Badge timeBadge text={value.processingTime} />
          </Stack>
        )}

        <ProductImg imgWidth={80} imgHeight={60} product={value} />
        <ModalQty
          value={value}
          enableChangeQuantity={enableChangeQuantity}
          overcomeInitialQuantity={overcomeInitialQuantity}
        />
      </Stack>
      <Stack
        gap={1}
        justifyContent="space-between"
        flexDirection="row"
        width={'70%'}
        alignItems={alignItems}
      >
        <ProductThumb
          product={value}
          enableInfoorder={enableInfoorder}
          notes={<ModalNote value={value} />}
        />
        {children}
      </Stack>
    </StyledRow>
  )
}

export default memo(ProductRow)
