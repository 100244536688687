import { ReactNode } from 'react'
import { StyledScroll } from './styled'

type Props = {
  scrollX?: boolean
  scrollY?: boolean
  height?: string
  width?: string
  children: ReactNode
}

const Scroll = ({ scrollX, scrollY, height, width, children }: Props) => {
  return (
    <StyledScroll height={height} scrollX={scrollX} scrollY={scrollY} width={width}>
      {children}
    </StyledScroll>
  )
}

export default Scroll
