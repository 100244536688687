import * as Realm from 'realm-web'

export const appId = process.env.REACT_APP_ID
console.log('TCL: appId', appId)

const appConfig = {
  id: appId,
  timeout: 10000
}

export const app = new Realm.App(appConfig)
export const mongodb = () =>
  app.currentUser.mongoClient('mongodb-atlas').db(process.env.REACT_APP_DB)
