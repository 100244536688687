import styled from '@emotion/styled'
import { TextField, FormControl, Stack } from '@mui/material'
import { theme } from '../../style/theme'

export const StyledTextArea = styled(TextField)({
  borderRadius: '15px',
  backgroundColor: theme.background.lightGrey
})

export const StyledFormControl = styled(FormControl)({
  borderRadius: '10px'
})

export const StyledSwitchStack = styled(Stack)`
  margin-right: 11px;
`
