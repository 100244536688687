import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _remove from 'lodash/remove'
import { Details } from '../orders/model'
import { CartSlice, OrderProductCart } from './model'

const initialState: CartSlice = {
  items: [],
  details: {
    payment: true,
    deliveryDate: '',
    deliveryTime: ''
  },
  deliveryMode: undefined,
  showDetails: false
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, { payload }: PayloadAction<OrderProductCart>) {
      const { productId, processingTime, note = '' } = payload

      const indexCart = state.items.findIndex(
        (p) => p.productId === productId.toString() && p.note === note
      )

      if (indexCart > -1) {
        state.items[indexCart].quantity === 0
          ? (state.items[indexCart].quantity = 1)
          : (state.items[indexCart].quantity += 1)
        return
      }

      state.items.push({
        productId: productId.toString(),
        quantity: 1,
        note,
        processingTime
      })
    },
    addToCartWithQuantity(state, { payload }: PayloadAction<OrderProductCart>) {
      const { productId, note = '', newQuantity } = payload

      const indexCart = state.items.findIndex(
        (p) => p.productId === productId.toString() && p.note === note
      )

      if (indexCart > -1) {
        state.items[indexCart].quantity = newQuantity || 0
        return
      }
    },

    editNote(state, { payload }: PayloadAction<OrderProductCart>) {
      const { productId, processingTime, note = '', newNote = '' } = payload

      const indexCart = state.items.findIndex(
        (p) => p.productId === productId.toString() && p.note === note
      )

      if (indexCart > -1) {
        state.items[indexCart].note = newNote
        return
      }

      state.items.push({
        productId: productId.toString(),
        quantity: 1,
        note,
        processingTime
      })
    },

    removeFromCart(state, { payload }: PayloadAction<OrderProductCart>) {
      const { productId, note } = payload

      const itemCart = state.items.find(
        (p) => p.productId === productId && p.note === note
      )

      if (itemCart && itemCart.quantity > 1) itemCart.quantity -= 1
    },

    deleteFromCart(
      state,
      { payload }: PayloadAction<OrderProductCart & { flowName?: string; node?: string }>
    ) {
      const { productId, quantity, note } = payload
      _remove(state.items, {
        productId,
        quantity,
        note
      })
    },
    addNotes(state, { payload }: PayloadAction<Details>) {
      state.details = payload
    },
    resetCart(state) {
      state.items = initialState.items
      state.details = initialState.details
      state.deliveryMode = initialState.deliveryMode
    },
    showDetails(state) {
      state.showDetails = !state.showDetails
    }
  }
})

export const cartActions = {
  ...cartSlice.actions,
  fetchProducts: createAction('products/fetchProducts')
}
export const cartReducer = cartSlice.reducer
