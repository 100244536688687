import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import { Stack } from '@mui/material'
import { theme } from '../../style/theme'

export const StyledHeader = styled(Stack)({
  backgroundColor: theme.palette.danger,
  height: '12px',
  borderRadius: '10px 10px 0 0',
  color: 'white',
  fontSize: '12px',
  justifyContent: 'center'
})

export const StyledBody = styled(Stack)({
  backgroundColor: theme.background.lightGrey,
  height: '24px',
  borderRadius: '0 0 10px 10px',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center'
})

export const StyledPage = styled(Stack)({
  boxShadow: '0px 1px 25px rgba(0, 0, 0, 0.05)',
  borderRadius: '10px',
  border: `1px solid ${theme.palette.lightGrey}`,
  minWidth: '38px',
  height: '38px'
})

export const StyledButton = styled(IconButton)({
  padding: '0'
})
