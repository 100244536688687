import { styled, Stack } from '@mui/material'

export const StyledProductionStack = styled(Stack)({
  padding: '4px 0 0',
  height: '100vh'
})

export const StyledPageStack = styled(Stack)({
  paddingTop: '20px',
  height: '100%'
})
