import { Chip as ChipMaterial } from '@mui/material'
import { ReactNode } from 'react'

function Chip({ label }: { label: string | ReactNode }) {
  return (
    <ChipMaterial
      label={label}
      variant="outlined"
      sx={{ width: '83px', fontSize: '12px' }}
    />
  )
}

export default Chip
