import { Stack } from '@mui/material'
import Flower from '@stackhouseos/flower-client'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { FilterHeader } from '../../components/FilterHeader'
import { selectFormattedFilterDate } from '../../features/app/selectors'
import { ordersProductsActions } from '../../features/ordersProducts/reducer'
import {
  makeStatusColumnPending,
  makeStatusColumnProcessing,
  makeStatusColumnReady,
  selectedCurrentOrderProducts,
  selectFilteredOrderProducts,
  selectFilters,
  selectOrderProductsFilterOpts,
  selectSearchValue
} from '../../features/ordersProducts/selectors'
import flow from './production.flower.json'
import { StyledProductionStack, StyledPageStack } from './styled'

function OrdersProducts() {
  const dispatch = useDispatch()

  const filterOpts = useSelector(selectOrderProductsFilterOpts)
  const value = useSelector(selectFilters)
  const searchValue = useSelector(selectSearchValue)

  const handleChange = useCallback(
    (val: string) => dispatch(ordersProductsActions.searchOrdersProducts(val)),
    [dispatch]
  )

  return (
    <StyledPageStack width="100%">
      <FilterHeader
        filterOpts={filterOpts}
        value={value.category}
        searchValue={searchValue}
        action={ordersProductsActions.setFilterCategory}
        searchAction={handleChange}
        dateFilter
      />
      <StyledProductionStack gap={8} width={'100%'}>
        <Stack gap={2} height={'100%'}>
          <Flower
            name={flow.name}
            reducerName="ordersProducts"
            elements={flow.elements}
            selectors={{
              filtered: selectFilteredOrderProducts,
              filterOpts: selectOrderProductsFilterOpts,
              filterDate: selectFormattedFilterDate,
              selected: selectedCurrentOrderProducts,
              pending: makeStatusColumnPending,
              processing: makeStatusColumnProcessing,
              ready: makeStatusColumnReady
            }}
          />
        </Stack>
      </StyledProductionStack>
    </StyledPageStack>
  )
}

export default OrdersProducts
