import Flower from '@stackhouseos/flower-client'
import onboarding from './onboarding.flower.json'

function OnBoarding() {
  return (
    <Flower name={onboarding.name} reducerName="app" elements={onboarding.elements} />
  )
}

export default OnBoarding
