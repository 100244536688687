import { FlowerContext } from '@stackhouseos/flower-client'
import React, { useContext, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

export const ParentGoToNext = React.memo(() => {
  const dispatch = useDispatch()
  const one = useRef(false)
  const { parentFlow, reducerName } = useContext(FlowerContext)

  useEffect(() => {
    if (parentFlow && !one) {
      one.current = true
      dispatch({
        type: 'flower/next',
        payload: {
          flowName: parentFlow,
          reducerName
        }
      })
    }
  }, [dispatch, parentFlow, reducerName, one])

  return null
})
