// @ts-nocheck
import React, { PropsWithChildren, ReactNode } from 'react'
import { StyledProductRowsContainer, StyledProductsRow } from './styled'

type Props = PropsWithChildren<{
  products: any[]
  item_container_children: ReactNode
  renderChildren: (node: ReactNode, path: string) => ReactNode
  childId: string
  height?: string
}>

export const ListRow = ({
  products = [],
  item_container_children,
  renderChildren,
  childId,
  products_id,
  height
}: Props) => {
  return (
    <>
      {products.length !== 0 ? (
        <StyledProductRowsContainer height={height}>
          {products.map((_, index) => (
            <StyledProductsRow key={index} className="list-item">
              {renderChildren(
                item_container_children.map((e) => ({
                  ...e,
                  id: `^${products_id}.${index}`
                })),
                childId
              )}
            </StyledProductsRow>
          ))}
        </StyledProductRowsContainer>
      ) : null}
    </>
  )
}
