import { StyledSearchInput } from './styled'

type Props = {
  id: string
  label: string
  value: string
  onChange: (value: string) => void
}

function SearchInput({ id, label, value, onChange }: Props) {
  return (
    <StyledSearchInput
      id={id}
      placeholder={label}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      variant="standard"
    />
  )
}

export default SearchInput
