import { ListItemIcon, styled } from '@mui/material'
import { ListItem } from '@mui/material'

type Props = {
  borderColor?: string
  backgroundColor?: string
  isSelected: Boolean
}

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) =>
    prop !== 'borderColor' && prop !== 'backgroundColor' && prop !== 'isSelected'
})(({ borderColor, backgroundColor, isSelected }: Props) => ({
  display: 'flex',
  marginBottom: '20px',
  padding: ' 10px 40px',
  borderRadius: '6px',
  justifyContent: 'space-between',
  border: isSelected ? `2px solid ${borderColor}` : '2px solid transparent',
  borderLeft: `6px solid ${borderColor}`,
  backgroundColor: `${backgroundColor}`,
  boxShadow: ' 0px 1px 25px rgba(0, 0, 0, 0.05)',
  alignItems: 'center',
  height: '75px',
  cursor: 'pointer'
}))

export const StyledListItemIcon = styled(ListItemIcon)`
  display: flex;
  justify-content: flex-end;
  align-self: center;
`

export const StyledCell = styled('span')({ minWidth: '80px' })
