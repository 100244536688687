import { Stack } from '@mui/material'
import Flower from '@stackhouseos/flower-client'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { FilterHeader } from '../../components/FilterHeader'
import { productsActions } from '../../features/products/reducer'
import {
  makeSelectProductsFilterOpts,
  selectFilteredProducts,
  selectInputProducts,
  selectProductsFilter
} from '../../features/products/selectors'
import Cart from '../Cart'
import PageContent from '../PageContent/PageContent'
import flow from './products.flower.json'

function Products() {
  const dispatch = useDispatch()
  const filterOpts = useSelector(makeSelectProductsFilterOpts)
  const value = useSelector(selectProductsFilter)
  const searchValue = useSelector(selectInputProducts)

  const handleChange = useCallback(
    (val: string) => dispatch(productsActions.searchedProducts(val)),
    [dispatch]
  )

  return (
    <Stack gap={2} height="100%" paddingTop="20px" width="100%">
      <Stack>
        <FilterHeader
          filterOpts={filterOpts}
          value={value}
          action={productsActions.setFilter}
          searchAction={handleChange}
          searchValue={searchValue}
        />
      </Stack>

      <PageContent
        leftCmp={
          <Flower
            destroyOnUnmount
            name={flow.name}
            reducerName="products"
            elements={flow.elements}
            selectors={{
              filterOpts: makeSelectProductsFilterOpts,
              filtered: selectFilteredProducts
            }}
          />
        }
        rightCmp={<Cart />}
      />
    </Stack>
  )
}

export default memo(Products)
