import { styled } from '@mui/material/styles'
import { theme, ThemeColor } from '../../style/theme'

type Props = {
  bgColor?: ThemeColor
  color?: string
  text?: string | number
  right?: string
  left?: string
  top?: string
  width?: string
  height?: string
  timeBadge?: Boolean
}

export const StyledBadge = styled('div', {
  shouldForwardProp: (props) => props !== 'bgColor' && props !== 'timeBadge'
})(({ bgColor = 'turquoise', timeBadge }: Props) => ({
  fontWeight: 'bold',
  fontSize: timeBadge ? '1.3rem' : '0.9rem',
  position: 'absolute',
  right: '16px',
  top: !timeBadge ? '5px' : '0px',
  width: '44px',
  height: '44px',
  backgroundColor: !timeBadge
    ? '#ffffff80'
    : bgColor
    ? `${theme.palette[bgColor]}`
    : 'red',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: !timeBadge ? 'blue' : 'danger',
  borderRadius: '50%'
}))

export const StyledTimeIconContainer = styled('div')`
  position: absolute;
  left: 5%;
`
