import { createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { get, groupBy, keyBy, matches } from 'lodash'
import _filter from 'lodash/filter'
import _isMatch from 'lodash/isMatch'
import _map from 'lodash/map'
import _omit from 'lodash/omit'
import _omitBy from 'lodash/omitBy'
import _uniq from 'lodash/uniq'
import { getWeekDay } from '../../utils'
import { selectFilterDate } from '../app/selectors'
import { OrderProduct, OrderProductExtendend } from '../ordersProducts/model'
import {
  selectExtendedOrdersProducts,
  selectOrdersProducts
} from '../ordersProducts/selectors'
import { selectProducts, selectProductsIds } from '../products/selectors'
import type { RootState } from '../store'
import { selectStores } from '../stores/selector'
import { Order, Status, StatusCode } from './model'
import { FORMATDATE } from './reducer'

export const selectFilterOrders = (state: RootState) => state.orders
export const selectOrders = (state: RootState) => state.orders.items
export const selectFilterOrdersItems = (state: RootState) => state.orders.items
export const selectInputOrders = (state: RootState) => state.orders.search
export const selectFilter = (state: RootState) => state.orders.filters.status

export const makeSelectOrdersByStatus = (status: Status) =>
  createSelector(selectOrders, (orders) => orders.filter((o) => o.status === status))

export const _makeSelectOrdersByDetails = createSelector(selectOrders, (orders) =>
  _filter(orders, 'details').map((d) => {
    return _omit({ ...d, ...d.details }, 'details') as Order
  })
)
export const makeSelectOrdersByDetails = createSelector(selectOrders, (orders) =>
  _filter(orders, 'details').map((d) => {
    return _omit({ ...d, ...d.details }, 'details', 'createdAt') as Order
  })
)

export const makeSelectOrdersByFilters = createSelector(
  selectFilterOrders,
  selectFilterDate,
  (redOrders, deliveryDate) => {
    const { filters, items } = redOrders
    const allFilters = { ...filters, deliveryDate }
    return _filter(items, matches(_omitBy(allFilters, (v) => v === undefined))).reverse()
  }
)

export const selectSearchedProducts = createSelector(
  selectProducts,
  selectInputOrders,
  (products, input) =>
    input
      ? products.filter((p) => p.title.toLowerCase().includes(input?.toLowerCase()))
      : products
)

export const selectOrderProductsBySearch = createSelector(
  selectOrdersProducts,
  selectSearchedProducts,
  (orderProducts, searchedProducts) => {
    const productsObj = keyBy(searchedProducts, '_id')
    return orderProducts.filter((o) => productsObj.hasOwnProperty(o.productId))
  }
)

export const selectSearchedStore = createSelector(
  selectStores,
  selectInputOrders,
  (stores, input) => {
    if (!input) return
    const search = input
      .toLowerCase()
      .replace('spedire a', '')
      .replace('creato da', '')
      .trim()
    return (
      stores
        .filter((s) => s.name.toLowerCase().includes(search))
        .map((s) => ({ ...s, _id: s._id.toString() })) || []
    )
  }
)

export const selectSearchedStoreObj = createSelector(selectSearchedStore, (stores) =>
  keyBy(stores, '_id')
)

export const makeSelectOrdersByFiltersAndString = createSelector(
  makeSelectOrdersByFilters,
  selectInputOrders,
  selectSearchedStoreObj,
  selectOrderProductsBySearch,
  (orders, input, storesObj, products) => {
    if (!orders) return
    if (!input) return orders
    const searched = input.toLowerCase()
    if (searched.includes('ordine')) {
      const search = searched.replace('ordine', '').trim()
      return orders.filter((o) => o?.orderNumber?.toString().includes(search))
    }
    if (searched.includes('nota')) {
      const search = searched.replace('nota', '').trim().toLocaleLowerCase()
      return orders.filter((o) => o?.details?.notes?.toLowerCase().includes(search))
    }
    if (searched.includes('ore')) {
      const search = searched.replace('ore', '').trim().toLocaleLowerCase()
      return orders.filter((o) => o?.details?.deliveryTime?.includes(search))
    }
    if (searched.includes('tel')) {
      const search = searched.replace('tel', '').trim()
      return orders.filter((o) => o?.details?.phone?.toString().includes(search))
    }
    if (searched.includes('nome')) {
      const search = searched.replace('nome', '').trim()
      return orders.filter((o) => o?.details?.deliveryAt?.toLowerCase().includes(search))
    }
    if (searched.includes('creato da')) {
      return orders.filter((o) => storesObj.hasOwnProperty(o?.storeId))
    }
    if (searched.includes('spedire a')) {
      return orders.filter((o) =>
        o?.details?.deliveryStoreId
          ? storesObj.hasOwnProperty(o?.details?.deliveryStoreId)
          : undefined
      )
    }

    const orderProduct = keyBy(products, 'orderId')
    return orders.filter(
      (o) =>
        o?.orderNumber?.toString().includes(input) ||
        o?.details?.notes?.toLowerCase().includes(input.toLowerCase()) ||
        o?.details?.deliveryTime?.includes(input) ||
        o?.details?.address?.toLowerCase().includes(input.toLowerCase()) ||
        o?.details?.deliveryAt?.toLowerCase().includes(input.toLowerCase()) ||
        o?.details?.phone?.toString().includes(input) ||
        storesObj.hasOwnProperty(o?.storeId) ||
        (o?.details?.deliveryStoreId &&
          storesObj.hasOwnProperty(o?.details?.deliveryStoreId)) ||
        (o?._id && orderProduct.hasOwnProperty(o?._id.toString()))
    )
  }
)

export const statusTranslate = {
  [StatusCode.new]: 'Nuovo',
  [StatusCode.pending]: 'In attesa',
  [StatusCode.processing]: 'In corso',
  [StatusCode.ready]: 'Fatto',
  [StatusCode.toDeliver]: 'Da spedire',
  [StatusCode.delivered]: 'Spedito',
  [StatusCode.completed]: 'Completato',
  [StatusCode.deleted]: 'Cancellato'
}

export const makeSelectOrdersFilterOpts = createSelector(selectFilterOrders, (orders) => {
  const sortOrder = [
    'new',
    'pending',
    'processing',
    'ready',
    'toDeliver',
    'delivered',
    'completed',
    'deleted'
  ]
  const opt = _uniq(_map(orders.items, 'status').filter(Boolean))
  return opt
    .map((o) => ({ value: o, title: statusTranslate[o] }))
    .sort((a, b) => sortOrder.indexOf(a.value) - sortOrder.indexOf(b.value))
})

export const makeSelectOrdersById = (id: string | undefined) =>
  createSelector(selectOrders, (orders) => orders.find((o) => o._id?.toString() === id))

export const selectProductionProducts = (state: RootState) => state.ordersProducts

export const makeSelectOrdersProductsFilterOpts = createSelector(
  selectOrdersProducts,
  (products) => {
    const opt = _uniq(_map(products, 'status'))
    return _map(opt, (o) => (o ? { value: o, title: StatusCode[o] } : undefined))
  }
)

export const makeSelectOrdersProductsByFilters = createSelector(
  selectProductionProducts,
  selectFilterDate,
  (products, date) => {
    const { filters, items } = products
    const allFilter = { ...filters, date }
    return items.filter((item) => (filters ? _isMatch(allFilter, item) : true))
  }
)

export const makeSelectOrderProductsByOrderId = (id: string) =>
  createSelector(selectOrdersProducts, (ordersProducts) =>
    ordersProducts.filter((p) => p.orderId === id)
  )

export const selectCurrentOrderId = createSelector(
  selectFilterOrders,
  (orders) => orders.selectId
)

export const selectCurrentOrder = createSelector(
  selectFilterOrders,
  selectCurrentOrderId,
  (orders, id) => {
    const order: Order | undefined = orders.items.find((o) => o._id === id)

    if (order) {
      const id_string = order._id ? order._id.toString() : undefined
      const date = order.createdAt.toLocaleString()
      const payment = order.details ? order.details?.payment : false
      return { ...order, id: id_string, date, payment }
    }
  }
)

export const selectCurrentOrderWithStore = createSelector(
  selectCurrentOrder,
  selectStores,
  (order, stores) => {
    if (order) {
      const deliveryStoreId = get(order, ['details', 'deliveryStoreId'])
      const deliveryStore = stores.find((s) => s._id.toString() === deliveryStoreId)
      const store = stores.find((s) => s._id.toString() === order.storeId)
      return { ...order, deliveryStore, store }
    }
  }
)

export const filteredProductsByStatus = (status: Status) =>
  createSelector(selectOrdersProducts, (products: OrderProduct[]) =>
    products.filter((product: OrderProduct) => product.status === status)
  )

export const selectProductsCurrentOrder = createSelector(
  selectExtendedOrdersProducts,
  selectCurrentOrderWithStore,
  (ordersProducts, order) =>
    order
      ? ordersProducts.filter(
          (p: OrderProductExtendend) => p.orderId === order?._id?.toString()
        )
      : undefined
)

export const selectCurrentOrderStatus = createSelector(
  selectCurrentOrderWithStore,
  (order) => order?.status
)

export const selectCurrentOrderDate = createSelector(selectCurrentOrder, (order) => {
  if (order?.details?.deliveryDate) {
    const date = new Date(order?.details?.deliveryDate)
    return date.toLocaleDateString('it-IT')
  }
})

export const selectCompleteProductsCurrentOrder = createSelector(
  selectProductsCurrentOrder,
  selectProductsIds,
  (orderProducts, products) =>
    orderProducts
      ? orderProducts
          .filter((r) => !!products[r.productId])
          .map((p) => {
            return {
              ...p,
              title: products[p.productId].title
            }
          })
      : undefined
)

export const selectOrderGroupedByDate = createSelector(
  makeSelectOrdersByFiltersAndString,
  (orders) => {
    const groupedDates = groupBy(orders, (entry: Order) => {
      const date = new Date(entry.details?.deliveryDate || entry.createdAt)
      return new Intl.DateTimeFormat('it-IT', {
        dateStyle: 'full'
      }).format(date)
    })

    return Object.entries(groupedDates)
  }
)

export const selectOrderGroupedByDeliveryDate = createSelector(
  makeSelectOrdersByFiltersAndString,
  (orders) => {
    const ordersByDateKey = groupBy(orders, 'deliveryDate')
    return Object.entries(ordersByDateKey).sort().reverse()
  }
)

export const selectOriginalDeliveryDate = createSelector(selectCurrentOrder, (order) => {
  const date = dayjs(order?.details?.originalDate)
  return date > dayjs() ? order?.details?.originalDate : dayjs().format(FORMATDATE)
})

export const selectRawDeliveryDate = createSelector(selectCurrentOrder, (order) => {
  return dayjs(order?.details?.deliveryDate)
})

export const selectMaxProcessingTime = createSelector(
  selectCompleteProductsCurrentOrder,
  (products) => {
    if (!products) return
    const processingTime = products.map((p) => p.processingTime || 0)
    return Math.max(...processingTime, 24)
  }
)

export const selectTempDeliveryDate = createSelector(
  selectFilterOrders,
  selectCurrentOrderDate,
  (state, deliveryOrderDate) => state.tempDeliveryDate || deliveryOrderDate
)

export const selectNewMinDeliveryDate = createSelector(
  selectMaxProcessingTime,
  (processingTime) => {
    if (!processingTime) return
    const minDate = new Date()
    minDate.setHours(minDate.getHours() + processingTime)
    return dayjs(minDate).format(FORMATDATE)
  }
)

export const selectDeliveryLabel = createSelector(selectFilterDate, (date) =>
  date ? getWeekDay(date) : 'Consegna'
)

export const selectTotal = createSelector(selectProductsCurrentOrder, (products) => {
  if (!products) return 0
  return products
    .reduce((acc, inc) => {
      const quantity = inc.newQuantity || inc.quantity
      return acc + quantity * inc.price
    }, 0)
    .toFixed(2)
})
