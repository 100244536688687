import { EscPosEncoder } from './esc-pos-encoder'
import Printer, { base64ToArrayBuffer } from './Printer'

class PrinterWeb extends Printer {
  constructor() {
    super()

    this.sizes = {
      small: 0x01,
      medium: 0x00, // 0b00000000,
      normal: 0b00010000,
      large: 0b00010001,
      extralarge: 0b00010001
    }

    this.align = {
      left: 'left',
      center: 'center',
      right: 'right'
    }
  }

  async printerInit() {
    this.self = new EscPosEncoder()
    try {
      await this.printerInitDevice()
    } catch (error) {
      console.warn('Error in PrinterWeb.printerInit:', error)
    }
  }

  printerInitDevice() {
    return
  }

  printTextWithFont(textdata, size, noUpper, bold) {
    this.setFontSize(size)
    if (bold) this.setBoldFont()
    this.self.text(noUpper ? textdata : textdata.toUpperCase())
    this.setFontSize()
    this.setBoldFont(false)
    return
  }

  printString(textdata) {
    this.self.text(textdata)
  }

  lineWrap(n = 1) {
    return Promise.all(
      Array(n)
        .fill(1)
        .map((i) => this.self.newline())
    )
  }

  clearData() {
    // ESC @
    return this.queue([0x1b, 0x40])
  }

  sendRAWData(data) {
    return this.queue(base64ToArrayBuffer(data))
  }

  printQRCode(url, x, y) {
    return this.self.qrcode(url, y, x)
  }

  setAlignment(align) {
    return this.self.align(this.align[align])
  }

  queue(args) {
    return this.self._queue(args)
  }

  cut() {
    return this.queue([0x1b, 0x69])
  }

  setFontSize(size) {
    const value = this.sizes[size] || 0

    // ESC ! Batch specify print mode
    // this.queue([0x1d, 0x21, value]);

    // ESC M n Select character font
    // if (size === 'extralarge')
    // this.queue([0x1b, 0x21, 0x01]);

    // GS ! n Select character size
    return this.queue([0x1d, 0x21, value])
  }

  setBoldFont(active) {
    return this.self.bold(active)
  }

  async print() {
    const data = this.self.encode()

    const chunks = []

    const newline1 = 0x0a
    const newline2 = 0x0d

    let current = 0

    for (let i = 0; i < data.length; i++) {
      const value = data[i]

      if (value === newline1 && data[i + 1] === newline2) {
        current++
      }

      if (!chunks[current]) {
        chunks[current] = []
      }

      chunks[current].push(value)
    }

    try {
      return chunks
        .reduce((acc, chunk) => {
          return acc.then(() => {
            let result = new Uint8Array(chunk.length)
            chunk.forEach((item, index) => {
              result[index] = item
            })

            return this.printByDevice(result)
          })
        }, Promise.resolve())
        .then(this.onPrintEnd)
    } catch (error) {
      console.log('e', error)
    }
  }

  printByDevice() {
    return
  }

  onPrintEnd() {
    return
  }
}

export default PrinterWeb
