import { Stack } from '@mui/material'
import {
  DateCalendar as MuiDateCalendar,
  LocalizationProvider
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/it'
import { memo, useCallback } from 'react'
import { FORMATDATE } from '../../../features/orders/reducer'
import { Button } from '../../Button'

type Props = {
  onChange: (v: string | undefined) => void
  value?: string
  defaultDate?: string
  isFilter?: boolean
}

const DateCalendarCmp = ({ value, defaultDate, isFilter, onChange }: Props) => {
  const currentValue = value ? dayjs(value) : undefined
  const minDate = defaultDate ? dayjs(defaultDate) : undefined

  const handleChange = useCallback(
    (value: any) => {
      onChange(value.format(FORMATDATE))
    },
    [onChange]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <MuiDateCalendar
        key={value}
        value={currentValue}
        onChange={handleChange}
        disableHighlightToday={true}
        disablePast={!isFilter}
        minDate={minDate}
      />
    </LocalizationProvider>
  )
}

export const ActionBar = ({ onClear, onSetToday }: any) => {
  return (
    <Stack direction="row" justifyContent="space-around">
      <Button onClick={onSetToday} title="Oggi" width="fit-content" variant="text" />
      <Button onClick={onClear} title="Cancella" width="fit-content" variant="text" />
    </Stack>
  )
}

export const DateCalendar = memo(DateCalendarCmp)
export const CustomActionBar = memo(ActionBar)
