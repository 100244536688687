import _get from 'lodash/get'
import { memo, useCallback } from 'react'
import { Status } from '../../features/orders/model'
import { theme } from '../../style/theme'
import { FilterButton } from '../FilterButton'
import { bgButtonsColors } from './bgButtonsColors'
import { StyledFilterButtonsContainer, StyledStack } from './styled'

type Props = {
  opts: {
    title: string
    value: string
  }[]
  value: string | undefined
  onChange: (val: string | undefined | Status) => void
}

function FilterButtonsContainer({ opts, value, onChange }: Props) {
  const handleClick = useCallback(
    (element: string) => onChange(element === value ? undefined : element),
    [onChange, value]
  )

  return (
    <StyledStack gap={2} direction="row">
      <StyledFilterButtonsContainer>
        {opts?.map((element, i) => (
          <FilterButton
            text={element.title}
            isSelected={element.value === value}
            key={element.value}
            bgColorCode={
              _get(theme, ['status', element.value]) ||
              bgButtonsColors[i % bgButtonsColors.length]
            }
            onClick={() => handleClick(element.value)}
          />
        ))}
      </StyledFilterButtonsContainer>
    </StyledStack>
  )
}

export default memo(FilterButtonsContainer)
