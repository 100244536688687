import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton } from '@mui/material'
import { Stack as MuiStack } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { appActions } from '../../../features/app/reducer'
import { OrderProductExtendend } from '../../../features/ordersProducts/model'
import { getConversionValue } from '../../../features/ordersProducts/selectors'
import Chip from '../../Chip'
import CustomModal from '../../CustomModal'
import { Input } from '../../Form/Input/Input'
import Stack from '../../Stack'
import { Text } from '../../Text'
import { StyledAlertQuantity } from '../styled'

type Props = {
  value: OrderProductExtendend
  overcomeInitialQuantity?: boolean
  enableChangeQuantity?: boolean
}

function ModalQty({ value, overcomeInitialQuantity, enableChangeQuantity }: Props) {
  const dispatch = useDispatch()
  const { _id, quantity, initialQty, conversionValue, conversionUnit } = value
  const orderedDiff = initialQty && initialQty !== quantity ? true : false
  const [loading, setLoading] = useState(false)
  const [updatedQuantity, setUpdatedQuantity] = useState<number>(1)

  const limitOnIncrement = overcomeInitialQuantity
    ? true
    : updatedQuantity < initialQty || isNaN(updatedQuantity)

  const limitOnDecrement = updatedQuantity > 0

  const noActions = (!limitOnIncrement && !limitOnDecrement) || !enableChangeQuantity
  const enableConfirm = !noActions && !loading && updatedQuantity !== quantity

  const onConfirm = useCallback(() => {
    if (_id) {
      setLoading(true)
      dispatch(
        appActions.addFlow({
          id: `productQuantity-${_id}`,
          flow: 'productQuantity',
          state: {
            id: _id?.toString(),
            quantity: isNaN(updatedQuantity) ? 0 : updatedQuantity
          }
        })
      )
      setTimeout(() => setLoading(false), 700)
    }
  }, [_id, dispatch, updatedQuantity])

  const originQuantityConverted = useMemo(
    () =>
      getConversionValue({
        counter: initialQty,
        conversionValue,
        conversionUnit
      }),
    [conversionUnit, conversionValue, initialQty]
  )

  const updatedQuantityConverted = useMemo(
    () =>
      getConversionValue({
        counter: updatedQuantity,
        conversionValue,
        conversionUnit
      }),
    [conversionUnit, conversionValue, updatedQuantity]
  )

  const quantityConverted = useMemo(
    () =>
      getConversionValue({
        counter: quantity,
        conversionValue,
        conversionUnit
      }),
    [conversionUnit, conversionValue, quantity]
  )

  const onIncrementProduct = useCallback(() => {
    limitOnIncrement && setUpdatedQuantity(updatedQuantity ? updatedQuantity + 1 : 1)
  }, [limitOnIncrement, setUpdatedQuantity, updatedQuantity])

  const onDecrementProduct = useCallback(() => {
    limitOnDecrement && setUpdatedQuantity(updatedQuantity - 1)
  }, [limitOnDecrement, setUpdatedQuantity, updatedQuantity])

  const onChangeInput = useCallback((newQuantity: string | number) => {
    setUpdatedQuantity(newQuantity === 'isNan' ? 0 : Number(newQuantity))
  }, [])

  const onBlurInput = useCallback(() => {
    if (updatedQuantity > initialQty) onChangeInput(`${initialQty}`)
  }, [initialQty, onChangeInput, updatedQuantity])

  useEffect(() => {
    setUpdatedQuantity(quantity)
  }, [quantity])

  return (
    <CustomModal
      key={`force-${quantity}`}
      hasAction={enableConfirm}
      onClick={onConfirm}
      openModal={
        <Chip
          label={
            <Stack flexDirection="row" alignItems="center">
              {quantityConverted}
              {orderedDiff && <StyledAlertQuantity color="warning" />}
            </Stack>
          }
        />
      }
      content={
        <Stack width="250px">
          <Text
            text={noActions ? 'Quantità' : 'Cambia la quantità'}
            bold
            textAligned="center"
          />
          <MuiStack
            flexDirection="row"
            gap={1}
            alignContent="center"
            justifyContent="center"
            paddingBottom="20px"
          >
            {!noActions && (
              <IconButton onClick={onDecrementProduct} disabled={!limitOnDecrement}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            )}
            <Stack alignItems="center" gap={0.5} width="80px">
              {!noActions && (
                <Input
                  type="number"
                  value={updatedQuantity}
                  onChange={onChangeInput}
                  title="0"
                  onBlur={onBlurInput}
                />
              )}
              {noActions && (
                <Text text={isNaN(updatedQuantity) ? '0' : `${updatedQuantity}`} bold />
              )}
              {!isNaN(updatedQuantity) && (
                <Text text={`(${updatedQuantityConverted})`} bold />
              )}
            </Stack>
            {!noActions && (
              <IconButton onClick={onIncrementProduct} disabled={!limitOnIncrement}>
                <AddCircleOutlineIcon />
              </IconButton>
            )}
          </MuiStack>
          {orderedDiff && (
            <Text
              text={`Ordinato: ${initialQty} (${originQuantityConverted})`}
              textAligned="center"
            />
          )}
        </Stack>
      }
    />
  )
}

export default ModalQty
