import { Stack } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { FilterHeader } from '../../components/FilterHeader'
import { selectFormattedFilterDate } from '../../features/app/selectors'
import { ordersProductsActions } from '../../features/ordersProducts/reducer'
import {
  makeDetailStatusProduction,
  selectFilters,
  selectOrderProductsFilterOpts,
  selectSearchValue
} from '../../features/ordersProducts/selectors'
import { productsActions } from '../../features/products/reducer'
import { StyledPageStack } from '../Production/styled'
import { EmptyStateTable } from './EmptyStateTable'
import BasicTable from './Table'

function OrdersProductsDetail() {
  const dispatch = useDispatch()

  const filterOpts = useSelector(selectOrderProductsFilterOpts)
  const value = useSelector(selectFilters)
  const searchValue = useSelector(selectSearchValue)
  const rows = useSelector(makeDetailStatusProduction)
  const formattedDate = useSelector(selectFormattedFilterDate)

  const handleChange = useCallback(
    (val: string) => dispatch(ordersProductsActions.searchOrdersProducts(val)),
    [dispatch]
  )

  useEffect(() => {
    dispatch(ordersProductsActions.fetchOrdersProducts())
    dispatch(productsActions.fetchProducts())
  }, [dispatch])

  return (
    <StyledPageStack width="100%">
      <FilterHeader
        filterOpts={filterOpts}
        value={value.category}
        searchValue={searchValue}
        action={ordersProductsActions.setFilterCategory}
        searchAction={handleChange}
        dateFilter
      />
      <StyledPageStack alignItems={'center'}>
        <Stack width="100%" justifyContent={'center'} height={'90vh'} padding={'20px 0'}>
          {rows.length > 0 ? (
            <BasicTable rows={rows} />
          ) : (
            <EmptyStateTable extraContent={formattedDate} />
          )}
        </Stack>
      </StyledPageStack>
    </StyledPageStack>
  )
}

export default OrdersProductsDetail
