import { createSelector } from '@reduxjs/toolkit'
import { groupBy, keyBy, matches } from 'lodash'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _omitBy from 'lodash/omitBy'
import _uniq from 'lodash/uniq'
import { getFormattedDate } from '../../utils'
import {
  selectFilterOrdersItems,
  selectOrders,
  statusTranslate
} from '../orders/selectors'
import { selectOrdersProducts } from '../ordersProducts/selectors'
import { selectProducts } from '../products/selectors'
import { RootState } from '../store'
import { selectStores } from '../stores/selector'

const availableStatus = ['ready', 'toDeliver', 'delivered', 'completed']

export const selectDelivery = (state: RootState) => state.delivery

export const selectDeliveryFilter = createSelector(
  selectDelivery,
  (delivery) => delivery.filters
)

export const selectSearchFilterDelivery = createSelector(
  selectDelivery,
  (delivery) => delivery.search
)

export const selectDateFilterDelivery = createSelector(
  selectDeliveryFilter,
  (filters) => filters.deliveryDate
)

export const selectFormattedFilterDeliveryDate = createSelector(
  selectDateFilterDelivery,
  (date) => (date ? getFormattedDate(date) : date)
)

export const selectDeliveryOrdersByFilters = createSelector(
  selectFilterOrdersItems,
  selectDeliveryFilter,
  (items, filtersDelivery) => {
    const orders = items.filter((o) => availableStatus.includes(o.status))
    if (!filtersDelivery) {
      return orders
    }
    return _filter(orders, matches(_omitBy(filtersDelivery, (v) => v === undefined)))
  }
)

export const selectDeliveryFromAvaibleStatus = createSelector(selectOrders, (orders) =>
  orders.filter((o) => availableStatus.includes(o.status))
)

export const selectSearchedStore = createSelector(
  selectStores,
  selectSearchFilterDelivery,
  (stores, input) => {
    if (!input) return
    const search = input
      .toLowerCase()
      .replace('spedire a', '')
      .replace('creato da', '')
      .trim()
    return (
      stores
        .filter((s) => s.name.toLowerCase().includes(search))
        .map((s) => ({ ...s, _id: s._id.toString() })) || []
    )
  }
)

export const selectSearchedStoreObj = createSelector(selectSearchedStore, (stores) =>
  keyBy(stores, '_id')
)

export const selectSearchedProducts = createSelector(
  selectProducts,
  selectSearchFilterDelivery,
  (products, input) =>
    input
      ? products.filter((p) => p.title.toLowerCase().includes(input?.toLowerCase()))
      : products
)

export const selectOrderProductsBySearch = createSelector(
  selectOrdersProducts,
  selectSearchedProducts,
  (orderProducts, searchedProducts) => {
    const productsObj = keyBy(searchedProducts, '_id')
    const filteredProducts = orderProducts.filter((o) =>
      productsObj.hasOwnProperty(o.productId)
    )
    return keyBy(filteredProducts, 'orderId')
  }
)

export const selectSearchedDelivery = createSelector(
  selectDeliveryOrdersByFilters,
  selectSearchFilterDelivery,
  selectSearchedStoreObj,
  selectOrderProductsBySearch,
  (orders, input, storesObj, productsObj) => {
    if (!orders) return
    if (!input) return orders
    const searched = input.toLowerCase()
    if (searched.includes('ordine')) {
      const search = searched.replace('ordine', '').trim()
      return orders.filter((o) => o?.orderNumber?.toString().includes(search))
    }
    if (searched.includes('nota')) {
      const search = searched.replace('nota', '').trim().toLocaleLowerCase()
      return orders.filter((o) => o?.details?.notes?.toLowerCase().includes(search))
    }
    if (searched.includes('ore')) {
      const search = searched.replace('ore', '').trim().toLocaleLowerCase()
      return orders.filter((o) => o?.details?.deliveryTime?.includes(search))
    }
    if (searched.includes('tel')) {
      const search = searched.replace('tel', '').trim()
      return orders.filter((o) => o?.details?.phone?.toString().includes(search))
    }
    if (searched.includes('nome')) {
      const search = searched.replace('nome', '').trim()
      return orders.filter((o) => o?.details?.deliveryAt?.toLowerCase().includes(search))
    }
    if (searched.includes('creato da')) {
      return orders.filter((o) => storesObj.hasOwnProperty(o?.storeId))
    }
    if (searched.includes('spedire a')) {
      return orders.filter(
        (o) =>
          o?.details?.deliveryStoreId &&
          storesObj.hasOwnProperty(o?.details?.deliveryStoreId)
      )
    }

    return orders.filter(
      (o) =>
        o?.orderNumber?.toString().includes(input) ||
        o?.details?.notes?.toLowerCase().includes(input.toLowerCase()) ||
        o?.details?.deliveryTime?.includes(input) ||
        o?.details?.address?.toLowerCase().includes(input.toLowerCase()) ||
        o?.details?.deliveryAt?.toLowerCase().includes(input.toLowerCase()) ||
        o?.details?.phone?.toString().includes(input) ||
        storesObj.hasOwnProperty(o?.storeId) ||
        (o?.details?.deliveryStoreId &&
          storesObj.hasOwnProperty(o?.details?.deliveryStoreId)) ||
        (o?._id && productsObj.hasOwnProperty(o?._id.toString()))
    )
  }
)

export const makeSelectDeliveryOrdersFilterOpts = createSelector(
  selectFilterOrdersItems,
  (items) => {
    const orders = items.filter((o) => availableStatus.includes(o.status))
    return _uniq(_map(orders, 'status').filter(Boolean))
      .map((o) => ({
        value: o,
        title: statusTranslate[o]
      }))
      .sort((a, b) => availableStatus.indexOf(a.value) - availableStatus.indexOf(b.value))
  }
)

export const selectOrderGroupedByDate = createSelector(
  selectSearchedDelivery,
  (orders) => {
    const groupedDates = groupBy(orders, (entry) => {
      if (entry.details?.deliveryDate) {
        const date = new Date(entry.details?.deliveryDate)
        const newDate = new Intl.DateTimeFormat('it-IT', {
          dateStyle: 'full'
        }).format(date)
        console.log(newDate)
      }
    })
    const nnn = Object.entries(groupedDates)
    console.log('🚀 ~ file: selectors.ts:185 ~ nnn:', nnn)
    return Object.entries(groupedDates)
  }
)

export const selectOrderGroupedByDeliveryDate = createSelector(
  selectSearchedDelivery,
  (orders) => {
    const ordersByDateKey = groupBy(orders, 'deliveryDate')
    return Object.entries(ordersByDateKey).sort().reverse()
  }
)
