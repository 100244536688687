import { Stack } from '@mui/material'
import { theme } from '../../style/theme'
import IconCmp from '../IconCmp'
import { Text } from '../Text'

type Props = {
  title?: string
  text?: string
  icon?: string
  iconSize?: string
  children?: any
}

function EmptyState({ title, text, icon, iconSize, children }: Props) {
  return (
    <Stack height="70vh" paddingTop={theme.container.paddingTop}>
      <Stack
        gap={4}
        width={'100%'}
        alignItems={'center'}
        minHeight={'50%'}
        justifyContent={'center'}
      >
        {icon && <IconCmp icon={icon} size={iconSize} />}
        <Text text={title} variant="h5" bold textAligned="center" />
        <Stack gap={0.5} alignItems={'center'}>
          <Text text={text} variant="h6" textAligned="center" fontSize={16} />
          {children}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default EmptyState
