import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/it'
import { memo, useCallback, useEffect } from 'react'
import { FORMATDATE } from '../../../features/orders/reducer'
import { CustomActionBar } from './DateCalendar'
import { StyledDatePicker } from './styled'

type Props = {
  onChange: (v: string | undefined) => void
  value?: string
  defaultDate?: Dayjs
  isFilter?: boolean
  label?: string
}

function CustomDatePicker({ value, defaultDate, onChange, isFilter, label }: Props) {
  const minDate = defaultDate ? dayjs(defaultDate) : undefined
  const currentValue = value ? dayjs(value) : undefined

  const handleChange = useCallback(
    (value: any) => {
      onChange(value === null ? undefined : value.format(FORMATDATE))
      if (value === null) value = undefined
    },
    [onChange]
  )

  useEffect(() => {
    if (defaultDate) {
      const defaultFormatted = dayjs(defaultDate).format(FORMATDATE)
      onChange(defaultFormatted)
    }
  }, [defaultDate, onChange])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <StyledDatePicker
        key={value}
        label={label}
        value={currentValue}
        onChange={handleChange}
        disableHighlightToday={true}
        minDate={minDate}
        isFilter={isFilter}
        slotProps={
          isFilter
            ? {
                textField: {
                  variant: 'standard',
                  size: 'small'
                }
              }
            : {
                actionBar: {
                  actions: []
                }
              }
        }
        slots={
          isFilter
            ? {
                actionBar: CustomActionBar
              }
            : {}
        }
      />
    </LocalizationProvider>
  )
}

export default memo(CustomDatePicker)
