import { Badge, Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeStatus } from '../../style/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  border: 1,
  borderColor: '#A56C18',
  marginLeft: '5px'
})

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor' && prop !== 'uppercase'
})<{ textColor: ThemeStatus; uppercase?: boolean }>`
  display: inline-flex;
  padding-left: 0;
  color: ${({ theme, textColor }) => theme.status[textColor]};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`

export const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'badgeColor'
})<{ badgeColor: ThemeStatus }>`
  span {
    background-color: ${({ theme, badgeColor }) => theme.status[badgeColor]};
  }
`
