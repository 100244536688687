import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from '../../style/theme'

type Props = {
  person?: boolean
  imgSize?: number
}

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left'
})

export const StyledImg = styled('div', {
  shouldForwardProp: (props) => props !== 'person' && props !== 'imgSize'
})(
  ({ person, imgSize }: Props) => `
border-radius: 50%;
  height: ${imgSize ? `${imgSize}px` : '44px'};
  width: ${imgSize ? `${imgSize}px` : '44px'};
  background-color: ${person ? theme.palette.mediumGrey : theme.palette.turquoise};
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;

  svg {
    fill: white;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
  }
`
)

export const StyledPhoneLine = styled('div', {
  shouldForwardProp: (props) => props !== 'person'
})(
  ({ width }: { width?: number }) => `
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
  color: ${theme.palette.mediumGrey};
  width: ${width}

  .MuiSvgIcon-root,
  .MuiSvgIcon-fontSizeSmall,
  .css-ptiqhd-MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
`
)
