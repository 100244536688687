import _uniq from 'lodash/uniq'

export default {
  create: (state, action) => {
    state.items.push(action.payload)
  },
  update: (state, action) => {
    state.items = state.items.map((e) =>
      e._id === action.payload._id ? { ...action.payload, _id: action.payload._id } : e
    )
  },
  remove: (state, action) => {
    state.items = state.items.filter((e) => e !== action.payload._id)
  }
}
